import {Type} from '@angular/core';
import {PrintRequest} from '@modules/sam-main/admin/models/print-request.model';
import {Geclicat} from '@sam-base/models/invoices/geclicat';
import {Gerpl} from '@sam-base/models/invoices/gerpl';

import {ContactNcs} from '@sam-base/models/salary/contact-ncs.model';
import {Gecli} from 'src/app/sam-base/models/invoices/gecli';
import {Gecligrp} from 'src/app/sam-base/models/invoices/gecligrp';
import {Gecliview} from 'src/app/sam-base/models/invoices/gecliview';
import {Gefacele} from 'src/app/sam-base/models/invoices/gefacele';
import {Gefachdr} from 'src/app/sam-base/models/invoices/gefachdr';
import {Gepaicli} from 'src/app/sam-base/models/invoices/gepaicli';
import {Gepaicond} from 'src/app/sam-base/models/invoices/gepaicond';
import {Gepaiele} from 'src/app/sam-base/models/invoices/gepaiele';
import {Gepaihdr} from 'src/app/sam-base/models/invoices/gepaihdr';
import {Gepar} from 'src/app/sam-base/models/invoices/gepar';
import {Saparass} from 'src/app/sam-base/models/placement/saparass';
import {Agetime} from 'src/app/sam-base/models/salary/agetime';
import {Saparded, Sapardedview, Saparncs} from 'src/app/sam-base/models/salary/index';
import {Sadedtxage} from 'src/app/sam-base/models/salary/sadedtxage';
import {Saemc} from 'src/app/sam-base/models/salary/saemc';
import {Saemp} from 'src/app/sam-base/models/salary/saemp';
import {Saempc} from 'src/app/sam-base/models/salary/saempc';
import {Saempded} from 'src/app/sam-base/models/salary/saempded';
import {Saempview} from 'src/app/sam-base/models/salary/saempview';
import {Saems} from 'src/app/sam-base/models/salary/saems';
import {Sags} from 'src/app/sam-base/models/salary/sags';
import {Sancsmot} from 'src/app/sam-base/models/salary/sancsmot';
import {Sapar} from 'src/app/sam-base/models/salary/sapar';

export const ENTITIES: Type<any>[] = [
    Sags,
    Sapar,
    Gepar,
    Saemp,
    Saempview,
    Sapardedview,
    Saemc,
    Saparded,
    Sancsmot,
    Saempc,
    Gecli,
    Gepaihdr,
    Gepaicli,
    Gepaiele,
    Agetime,
    Sadedtxage,
    Gecliview,
    Gecligrp,
    Gepaicond,
    Gefachdr,
    Gefacele,
    Geclicat,
    Saparass,
    Saempded,
    Saparncs,
    Saems,
    ContactNcs,
    Gerpl,
    PrintRequest];