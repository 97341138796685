<form [formGroup]="formGroup"
      class="row space-form">
    <iw-button #Standardbutton2
               (press)="openCreate()"
               [text]="'create' | translate"
               class="iw-button col-xs-2 no-label"></iw-button>

    <iw-button #Standardbutton2
               (press)="destroyForm()"
               [text]="'fermer' | translate"
               class="iw-button col-xs-2 no-label"></iw-button>

    <iw-uploader #fileUpload
                 (fileSelect)="uploadDocument($event)"
                 [acceptedFileType]="'.csv'"
                 [label]="'import' | translate"
                 class="iw-button no-label col-xs-2"></iw-uploader>

    <iw-table #lstMdl
              (applyFilter)="onApplyFilter($event)"
              (rowDoubleClick)="onRowDoubleClick($event)"
              [columns]="columns"
              [data]="filteredData"
              [defaultSelected]="true"
              [isDisabled]="false"
              [isFilterEnable]="true"
              [isGridColumnMenuEnable]="false"
              [isSortIconEnable]="true"
              [virtualScrollDelay]="0"
              [virtualScroll]="true"
              class="iw-grid col-xs-12"
              style="height: 200px"></iw-table>
</form>
