import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Gecli} from '@app/sam-base/models/invoices';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const clientTabsFormControls: TabFormControl<Gecli>[] = [
    {
        formControls: [
            'cliId',
            'nom',
            'clistatus',
            'categids']
    },
    {
        title: 'general',
        formControls: [
            'prefixe',
            'adresse1',
            'adresse2',
            'adresse3',
            'lieu',
            'natel',
            'npa',
            'pays',
            'tel1',
            'tel2',
            'email',
            'fax',
            'www',
            'categids',
            'tvaNoIde']
    },
    {
        title: 'autre',
        formControls: [
            'cliIndex',
            'pgnIndex',
            'cligrpId',
            'bvrMpaId',
            'paicondId',
            'crelimite',
            'escompte',
            'formejuri',
            'langue',
            'nbexfact',
            'horsTva',
            'facadrId',
            'facAttn',
            'facfrx',
            'rplAttn',
            'rpladrId',
            'rplfrx',
            'sendrappel',
            'rpladrmode',
            'facbyemail',
            'facemail',
            'facfrxem',
            'rplbyemail',
            'rplemail',
            'rplReminderDays1',
            'rplReminderDays2',
            'rplReminderDays3',
        ]
    },
    {
        title: 'contact',
        formControls: []
    },
    {
        title: 'adresse',
        formControls: []
    },
    {
        title: 'notes',
        formControls: [
            'remarques',
            'cliData',
            'xmlData']
    },
    {
        title: 'suivi',
        formControls: ['cliId']
    },
    {
        title: 'e-facture',
        formControls: [
            'ybactif',
            'ebillacc',
            'ybNote',
            'ybData']
    },
    {
        title: 'docs',
        formControls: []
    },
    {
        title: 'ij',
        formControls: [
            'ijFacmail',
            'lexpfac2ij']
    }];
