<form [formGroup]="formGroup"
      class="row">
    <div class="col-xs-11 col-no-padding">
        <div class="row col-xs-12 col-no-padding margin-0">
            <div class="col-xs-5 row padding-0 margin-0">
                <iw-textfield #txtSui_id
                              [isDisabled]="true"
                              class="iw-input col-xs-5 no-padding"
                              formControlName="suiId"></iw-textfield>

                <iw-textfield #txtGoAppUserId
                              [isDisabled]="true"
                              class="iw-input col-xs-2 no-padding"
                              formControlName="userId"></iw-textfield>

                <iw-textfield #txtUser_age_id
                              [isDisabled]="true"
                              class="iw-input col-xs-2 no-padding"
                              formControlName="userAgeId"></iw-textfield>

                <iw-textfield #txtSui_other_age_ids
                              [isDisabled]="true"
                              class="iw-input col-xs-2 no-padding"
                              formControlName="suiOtherAgeIds"></iw-textfield>
            </div>
        </div>
    </div>
    <div class="col-no-padding"
         style="height: 200px; width: 96%">
        <iw-rest-grid #olvClisui
                      (dataChanged)="clearSortDir()"
                      (selected)="getSelectedRow($event)"
                      [autoFormClick]="false"
                      [customColumns]="columns"
                      [defaultSelected]="true"
                      [defaultSortDir]="'desc'"
                      [defaultSort]="'dateaction'"
                      [hasGridColumnMenu]="false"
                      [hasSortIcon]="true"
                      [innerWidth]="tableWidth"
                      [queryStatements]="query"
                      [refreshType]="refreshType"
                      [type]="type"
                      class="suivi-grid"></iw-rest-grid>
    </div>

    <div class="flex flex-v flex-between">
        <div class="flex flex-v margin-left-5"
             style="height: calc(4 * 25px);">
            <iw-button #btnAddAct
                       (press)="addSuiviClient()"
                       [isDisabled]="false"
                       *iwAccessGroups="employeeMgtAccessRoles"
                       [tooltip]="'nouvelleAction' | translate"
                       class="margin-bottom-5"
                       iconClass="fas fa-file"></iw-button>

            <iw-button #btnModAct
                       (press)="editSuiviClient()"
                       [isDisabled]="!suiId || isReadonly"
                       *iwAccessGroups="employeeMgtAccessRoles"
                       [tooltip]="'modifierAction' | translate"
                       class="margin-bottom-5"
                       iconClass="fas fa-pencil-alt"></iw-button>

            <iw-button #btnDelAct
                       (press)="deleteSuiviClient()"
                       [isDisabled]="!suiId || isReadonly"
                       *iwAccessGroups="employeeMgtAccessRoles"
                       [tooltip]="'supprimerAction' | translate"
                       iconClass="fas fa-trash"></iw-button>
        </div>
        <iw-button #btnPrtSui
                   (press)="printService()"
                   [isDisabled]="!suiId"
                   [tooltip]="'imprimer' | translate"
                   class="margin-left-5"
                   iconClass="fas fa-print"></iw-button>
    </div>

    <div class="col-xs-12 padding-top-5 no-padding">
        <iw-textarea #edtClisuiNotes
                     [height]="150"
                     [isDisabled]="true"
                     [value]="suiviDescription"
                     class="iw-textarea col-xs-12 padding-top-15"></iw-textarea>
    </div>

</form>
