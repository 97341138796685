<form [formGroup]="formGroup"
      class="row space-form">
    <div class="col-xs-12">
        <div class="row">
            <iw-button (press)="openCreate()"
                       [text]="'create' | translate"
                       class="iw-button col-xs-2 no-label"></iw-button>

            <iw-button (press)="destroyForm()"
                       [text]="'fermer' | translate"
                       class="iw-button col-xs-2 no-label"></iw-button>

            <iw-button (press)="onMergeClick()"
                       [text]="'merge' | translate"
                       class="iw-button col-xs-2 no-label"></iw-button>

            <iw-button (press)="createMock()"
                       *iwAccessGroups="[SamUserRole.SAM_ADMIN_GOD_MODE]"
                       [text]="'Certification' | translate"
                       class="iw-button col-xs-2 no-label"></iw-button>

            <iw-button (press)="ping()"
                       *iwAccessGroups="[SamUserRole.SAM_ADMIN_GOD_MODE]"
                       [text]="'ping' | translate"
                       class="iw-button col-xs-2 no-label"></iw-button>
            <iw-button (press)="checkInteroperability()"
                       *iwAccessGroups="[SamUserRole.SAM_ADMIN_GOD_MODE]"
                       [text]="'checkInteroperability' | translate"
                       class="iw-button col-xs-2 no-label"></iw-button>
        </div>
    </div>
    <div class="col-xs-12"
         style="max-height: 40vh;">
        <iw-table #lstMdl
                  (applyFilter)="onApplyFilter($event)"
                  (columnSort)="onSort($event)"
                  (rowDoubleClick)="onRowDoubleClick($event)"
                  (selectedChange)="onSelectedChange($event)"
                  [columns]="columns"
                  [data]="filteredTableData"
                  [defaultSelected]="true"
                  [isDisabled]="false"
                  [isFilterEnable]="true"
                  [isGridColumnMenuEnable]="false"
                  [isSortIconEnable]="true"
                  [selectionMode]="'checkbox'"
                  class="iw-grid"></iw-table>
    </div>
</form>
