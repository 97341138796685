<div [formGroup]="formGroup"
     class="row">

    <div class="row space-form col-xs-6">
        <iw-rest-grid #saparassGrid
                      (selected)="getSelectedRow($event)"
                      [autoFormClick]="false"
                      [hasGridColumnMenu]="false"
                      [hasSortIcon]="true"
                      [isDisabled]="editing"
                      [queryStatements]="query"
                      [type]="type"
                      class="col-xs-12 padding-top-15"
                      style="height: 300px;"></iw-rest-grid>
        <iw-textfield [isDisabled]="isCodeReadonlyField"
                      [labelAlign]="'left'"
                      [label]="'code' | translate"
                      [size]="'medium'"
                      [value]="assCodeValue"
                      class="iw-input col-xs-5 padding-top-15"
                      formControlName="assCode"></iw-textfield>
        <iw-dropdown [isDisabled]="isCodeReadonlyField"
                     [labelAlign]="'left'"
                     [label]="'deduction' | translate"
                     [options]="dedCodeOptions"
                     [size]="'medium'"
                     class="iw-input col-xs-7 padding-top-15"
                     formControlName="dedCode"></iw-dropdown>
        <iw-textfield [isDisabled]="isReadonlyField"
                      [labelAlign]="'left'"
                      [label]="'nom' | translate"
                      [size]="'medium'"
                      [value]="assNomValue"
                      class="iw-input col-xs-12 padding-top-15"
                      formControlName="assNom"></iw-textfield>
        <iw-textfield [isDisabled]="isReadonlyField"
                      [labelAlign]="'left'"
                      [label]="'adresse' | translate"
                      [size]="'medium'"
                      [value]="assAddress1"
                      class="iw-input col-xs-12 padding-top-15"
                      formControlName="assAdr1"></iw-textfield>
        <iw-textfield [isDisabled]="isReadonlyField"
                      [labelAlign]="'left'"
                      [label]="' '"
                      [size]="'medium'"
                      [value]="assAddress2"
                      class="iw-input col-xs-12 padding-top-15"
                      formControlName="assAdr2"></iw-textfield>

        <iw-npa-lieu [formGroup]="formGroup"
                     [isDisabled]="isReadonlyField"
                     [labelNpaAlign]="'left'"
                     [lieuFormControlName]="'assLieu'"
                     [npaFormControlName]="'assNpa'"
                     class="iw-input col-xs-12"></iw-npa-lieu>

    </div>
    <div class="row space-form col-xs-6">
        <div class="col-xs-10">

            <!--    assNocli / No de membre (except LAA which label will be "no contract")    -->

            <iw-textfield [isDisabled]="isReadonlyField"
                          [labelAlign]="'left'"
                          [label]="(!isLAA ? 'noMembre' : 'noContrat') | translate"
                          [size]="'large'"
                          [value]="assClient"
                          class="iw-input col-xs-12 padding-top-15"
                          formControlName="assNocli"></iw-textfield>

            <!--    assNocta / No Contract (except LAA which label will be "sous no contract")    -->
            <iw-textfield [isDisabled]="isReadonlyField"
                          [labelAlign]="'left'"
                          [label]="(!isLAA ? 'noContrat' : 'sousNoContrat') | translate"
                          [size]="'large'"
                          [value]="assContract"
                          class="iw-input col-xs-12 padding-top-5"
                          formControlName="assNocta"></iw-textfield>


            <!--    assSubnc / Sous No Contract (except LAA it is not visible)    -->
            <iw-textfield *ngIf="!isLAA"
                          [isDisabled]="sousNoContractDisabled()"
                          [labelAlign]="'left'"
                          [label]="'sousNoContrat' | translate"
                          [size]="'large'"
                          class="iw-input col-xs-12 padding-top-5"
                          formControlName="assSubnc"></iw-textfield>


            <iw-textfield [isDisabled]="isReadonlyField"
                          [labelAlign]="'left'"
                          [label]="'noCaisse' | translate"
                          [size]="'large'"
                          [value]="assNoCaisse"
                          [warningMessage]="isReadonlyField ? '' : noCaiseMessage"
                          class="iw-input col-xs-12 padding-top-5"
                          formControlName="assNocai"></iw-textfield>
            <iw-date-picker #txtDateAnniv
                            [isDisabled]="validstartDisabled()"
                            [labelAlign]="'left'"
                            [label]="'validstart' | translate"
                            [size]="'large'"
                            class="iw-input col-xs-12 padding-top-5"
                            formControlName="validstart"></iw-date-picker>
            <iw-textfield [isDisabled]="isReadonlyField"
                          [labelAlign]="'left'"
                          [label]="'xmlIdRef' | translate"
                          [size]="'large'"
                          [value]="assIdref"
                          class="iw-input col-xs-12 padding-top-5"
                          formControlName="assIdref"></iw-textfield>
            <iw-textfield #noofs
                          (valueChange)="checkNoofVal()"
                          [isDisabled]="ofsnrAndLppentcDisabled()"
                          [labelAlign]="'left'"
                          [label]="'ofsnr' | translate"
                          [size]="'large'"
                          class="iw-input col-xs-12 padding-top-5"
                          formControlName="ofsnr"
                          maxlength="20"></iw-textfield>

            <ng-template [ngIf]="isLPP">
                <iw-textfield #noofs
                              [isDisabled]="ofsnrAndLppentcDisabled()"
                              [labelAlign]="'left'"
                              [label]="'lppentc' | translate"
                              [size]="'large'"
                              class="iw-input col-xs-12 padding-top-5"
                              formControlName="lppentc"></iw-textfield>
                <iw-dropdown [isDisabled]="isReadonlyField"
                             [labelAlign]="'left'"
                             [label]="'laaassu' | translate"
                             [options]="laaAssOptions"
                             [size]="'medium'"
                             class="iw-input col-xs-7 padding-top-15"
                             formControlName="laaassu"></iw-dropdown>
            </ng-template>

            <iw-checkbox [isDisabled]="isReadonlyField"
                         [labelAlign]="'right'"
                         [label]="' fichierXml' | translate"
                         [value]="sdcxml"
                         class="iw-input col-xs-12"
                         formControlName="assSdcxml"></iw-checkbox>
            <iw-checkbox [isDisabled]="isReadonlyField"
                         [labelAlign]="'right'"
                         [label]="'transmisDistributeur' | translate"
                         [value]="sdcdis"
                         class="iw-input col-xs-12"
                         formControlName="assSdcdis"></iw-checkbox>
            <iw-textarea [height]="50"
                         [hidden]="true"
                         [isDisabled]="isReadonlyField"
                         [labelAlign]="'left'"
                         [label]="'assData' | translate"
                         [value]="assData"
                         class="iw-input col-xs-12 padding-top-5"
                         formControlName="assData"></iw-textarea>


            <ng-template [ngIf]="isAVS">
                <iw-dropdown [isDisabled]="isReadonlyField"
                             [labelAlign]="'left'"
                             [label]="'laaassu' | translate"
                             [options]="laaAssOptions"
                             [size]="'medium'"
                             class="iw-input col-xs-7 padding-top-15"
                             formControlName="avsLaaAssCode"></iw-dropdown>
                <iw-date-picker [isDisabled]="isReadonlyField"
                                [labelAlign]="'left'"
                                [label]="'depuis' | translate"
                                [size]="'large'"
                                class="iw-input col-xs-12 padding-top-5"
                                formControlName="avsLaaFrom"></iw-date-picker>

                <iw-dropdown [isDisabled]="isReadonlyField"
                             [labelAlign]="'left'"
                             [label]="'assureurLpp' | translate"
                             [options]="lppAssOptions"
                             [size]="'medium'"
                             class="iw-input col-xs-7 padding-top-15"
                             formControlName="avsLppAssCode"></iw-dropdown>
                <iw-date-picker [isDisabled]="isReadonlyField"
                                [labelAlign]="'left'"
                                [label]="'depuis' | translate"
                                [size]="'large'"
                                class="iw-input col-xs-12 padding-top-5"
                                formControlName="avsLppFrom"></iw-date-picker>

            </ng-template>


        </div>
        <div class="space-form col-xs-2">
            <iw-textfield #txtText
                          [isDisabled]="true"
                          [noLabel]="true"
                          [value]="annee"
                          class="iw-input"></iw-textfield>

            <!-- EDIT ACTION BUTTONS -->
            <ng-container *iwAccessGroups="SALAIRE_MGT_ROLES">
                <iw-button (press)="newEntry(); editing = true;"
                           [isDisabled]="editing || !!selectedElem"
                           [text]="'Nouveau' | translate"
                           class="iw-button padding-top-10"></iw-button>
                <iw-button (press)="modifySaparass(); editing = true"
                           *ngIf="!editing"
                           [isDisabled]="!selectedElem"
                           [text]="'modif' | translate"
                           class="col-xs-12"
                           id="edit"></iw-button>
                <iw-button (press)="deleteEntry(); editing = false"
                           *ngIf="!editing"
                           [isDisabled]="!selectedElem"
                           [text]="'supprimer' | translate"
                           class="col-xs-12"
                           id="delete"></iw-button>
                <iw-button (press)="saveEntry(); editing = false"
                           *ngIf="editing"
                           [isDisabled]="!isValid"
                           [text]="'enregistrer' | translate"
                           class="col-xs-12"
                           id="save"></iw-button>
                <iw-button (press)="loadEntry(); editing = false"
                           *ngIf="editing"
                           [isDisabled]="!editing && !newEntryMode"
                           [text]="'annuler' | translate"
                           class="col-xs-12"
                           id="cancel"></iw-button>
            </ng-container>

            <iw-button (press)="closeDialog()"
                       [text]="'fermer' | translate"
                       class="iw-button padding-top-10"></iw-button>


        </div>

    </div>
</div>
