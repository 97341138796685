import {HttpClient} from '@angular/common/http';

import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwContextMenuComponent} from '@app/sam-base/components';
import {
    ContextMenuEvent,
    EntityNavigationService,
    IwEventHubService,
    IwEventResult,
    IwStoreService,
    ModalService
} from '@app/sam-base/core';
import {isRestEntity} from '@app/sam-base/core/rest-api';
import * as actions from '@app/sam-base/core/store/actions/entity.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {IwGridColumn, MenuItem, RowClickEvent, sortGridList, TableSortEvent} from '@app/sam-base/models';
import {
    EmployeeDeclarationEditComponent
} from '@modules/sam-main/admin/components/swissdec/tab-sasx5dec-employees/employee-declaration-edit/employee-declaration-edit.component';
import {SwissdecService} from '@modules/sam-main/admin/services/swissdec-service';
import {Actions, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {EmployeeDeclaration} from '@sam-base/models/admin/employee-declaration.model';

import {Sasx5dec, SwissdecUIEvents} from '@sam-base/models/admin/sasx5dec.model';
import {Sasx5decView} from '@sam-base/models/admin/sasx5decView.model';
import {SxStatus} from '@sam-base/models/admin/sx-status.enum';
import {ViewgenReportCmd, ViewgenReportType} from '@sam-base/models/admin/viewgen.model';
import {FilterService} from '@sam-base/models/components/filtering.service';
import {Saempview} from '@sam-base/models/salary';
import {Saemsview} from '@sam-base/models/salary/saemsview';
import {toLower} from 'lodash';
import {Subject, takeUntil} from 'rxjs';


export enum EmployeeDeclarationMenuEvent {
    SAEMP = 'SAEMP',
    SAEMSVIEW = 'SAEMSVIEW',
    VIEWGEN_SALARY_CERTIFICATE = 'VIEWGEN_SALARY_CERTIFICATE'
}

/**
 * Tab General of Employee - SAM-4268
 */
@Component({
    selector: 'iw-tab-sasx5dec-employees',
    templateUrl: './tab-sasx5dec-employees.component.html'
})
export class TabSasx5decEmployeesComponent extends BasePartialFormComponent<Sasx5dec> implements OnInit, OnDestroy {

    @Input() public isNew = false;
    public originalEmployees: EmployeeDeclaration[] = [];
    public filteredEmployees: EmployeeDeclaration[] = [];
    public employeesWithSplitAvs: EmployeeDeclaration[] = [];
    public employeeGridColumns: IwGridColumn<EmployeeDeclaration>[] = [
        {
            name: 'gestion',
            prop: 'gestion',
            type: 'string',
            colorMapper: row => {
                return row.error ? '#a00101' : '#008000';
            }
        },
        {
            name: 'id',
            prop: 'empId',
            type: 'string'
        },
        {
            name: 'nom',
            prop: 'nom',
            type: 'string'
        },
        {
            name: 'prenom',
            prop: 'prenom',
            type: 'string'
        },
        {
            prop: 'error',
            type: 'warning',
            name: 'error'
        }
    ];
    public selectedEmployee?: EmployeeDeclaration;
    @ViewChild('menu', {static: true}) public menu?: IwContextMenuComponent;
    public contextMenuData?: ContextMenuEvent<EmployeeDeclarationMenuEvent>;
    public contextMenuItems: MenuItem[] = this.buildContextMenu()
    private filterService = new FilterService<EmployeeDeclaration>();

    private _selectedEmployees: EmployeeDeclaration[] = [];
    private _removeEmployeeProcessing = false;
    private subscriptions = new Subject();
    private tableSortEvent?: TableSortEvent<Sasx5decView>;

    constructor(private readonly _store: IwStoreService,
                private readonly _event: IwEventHubService<SwissdecUIEvents>,
                private readonly _eventMenu: IwEventHubService<EmployeeDeclarationMenuEvent>,
                private readonly _toast: ToastService, private readonly _http: HttpClient,
                private readonly _modalService: ModalService,
                private _navigationService: EntityNavigationService,
                private readonly _translateService: TranslateService,
                private readonly _swissdecService: SwissdecService, private readonly _updates: Actions) {
        super();
        this._event.forType(SwissdecUIEvents.DECLARE_SALARY_DONE)
            .pipe(takeUntil(this.subscriptions))
            .subscribe((data: IwEventResult<SwissdecUIEvents.DECLARE_SALARY_DONE, any>) => {
                const declarationId = data?.payload?.declarationId;
                if (declarationId === this.declarationId) {
                    this.loadEmployees();
                }
            });
        this._eventMenu.forType(EmployeeDeclarationMenuEvent.SAEMP)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(e => {
                const payload = e.payload as ContextMenuEvent<EmployeeDeclaration>;
                payload.selected.forEach(e => {
                    // this._navigationService.navigateToEntityForm(Ppemp, e.empId!, undefined, 'read');
                    this._navigationService.navigateToEntityForm(Saempview, e.empId!, undefined, 'read');
                });
            })
        this._eventMenu.forType(EmployeeDeclarationMenuEvent.SAEMSVIEW)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(e => {
                const payload = e.payload as ContextMenuEvent<EmployeeDeclaration>;
                payload.selected.forEach(e => {
                    const ficheSalId = `${e.empId!}_${this.getFormValue('salId')}`;
                    console.log('ficheSalId', ficheSalId);
                    // this._navigationService.navigateToEntityForm(Ppemp, e.empId!, undefined, 'read');
                    this._navigationService.navigateToEntityForm(Saemsview, ficheSalId, undefined, 'read');
                });
            })
        this._eventMenu.forType(EmployeeDeclarationMenuEvent.VIEWGEN_SALARY_CERTIFICATE)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(e => {
                const payload = e.payload as ContextMenuEvent<EmployeeDeclaration>;
                payload.selected.forEach(e => {
                    const ficheSalId = `${e.empId!}_${this.getFormValue('salId')}`;
                    const cmd = new ViewgenReportCmd();
                    cmd.reportType = ViewgenReportType.TaxAccountingReport;
                    // eslint-disable-next-line max-len
                    cmd.filename = `${cmd.reportType}_${this.getFormValue('salId')}_${this.getFormValue('gestion')}_${e.nom}_${e.prenom}_${e.empId}.pdf`;
                    cmd.options = [];
                    this._swissdecService.viewgenForEmp(this.getFormValue('id')!, e.empId!, cmd)
                        .subscribe({
                            complete: () => {
                            }
                        });

                });
            })

    }

    public get nbEmployeeSelectedCount(): string {
        return `${this._selectedEmployees?.length} / ${this.originalEmployees?.length}`;
    }

    public get nbEmployeeErrorCount(): string {
        return `${this.originalEmployees?.filter(s => s.error).length} / ${this.originalEmployees?.length}`;
    }

    public get declarationId(): string | undefined {
        return this.getFormValue('id');
    }

    public get removeEmployeeDisabled(): boolean {
        return this._removeEmployeeProcessing || this._selectedEmployees?.length === 0
            // eslint-disable-next-line max-len
            || [
                SxStatus.INIT,
                SxStatus.INIT_ERROR].indexOf(this.getFormValue('status') || SxStatus.INIT) === -1;
    }

    public get selectedEmployeeErrorMessage(): string {
        return `<ul>${this.selectedEmployee?.error?.errors.map(e => `<li>${this._translateService.instant(e.code, e.params)}</li>`).join('')}</ul>`
    }

    public get entityId() {
        const id = this.getFormValue('id');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public async onContextMenu(event: ContextMenuEvent<EmployeeDeclarationMenuEvent>) {
        this.contextMenuData = event;
        await this.showContextMenu(event.event);
    }

    public ngOnInit() {
        this.loadEmployees();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public removeEmployee() {
        this.selectedEmployee = undefined;
        this._removeEmployeeProcessing = true;
        return this._http.put<EmployeeDeclaration[]>(environment.backendURL + `swissdec/sasx5dec/${this.entityId}/remove-employees`, this._selectedEmployees.map(emp => emp.id))
            .subscribe(sasx5dec => {
                this._removeEmployeeProcessing = false;
                this._selectedEmployees = [];
                this.loadEmployees();
                this._toast.success('declaration_update_success');
            }, (err) => {
                this._toast.error('declaration_update_error');
                this._removeEmployeeProcessing = false;
            });

    }

    public async editEmployee(doc?: RowClickEvent<EmployeeDeclaration>) {
        const employeeDeclarationId = doc?.row?.id;
        if (!employeeDeclarationId || this.isReadonly) {
            return;
        }
        try {
            const editedEmp: EmployeeDeclaration = await this._modalService.showModal(EmployeeDeclarationEditComponent, {
                employeeDeclarationId,
                readonly: this.isReadonly,
                year: (this.getFormValue<string>('annee'))
            });
            this.saveEmployeeDeclaration(employeeDeclarationId, editedEmp);
        } catch (error) {
        }
    }

    public onSort($event?: TableSortEvent<EmployeeDeclaration>) {
        this.tableSortEvent = $event;
        this._onSort();
    }

    public setSelected($event: EmployeeDeclaration[]) {
        this._selectedEmployees = $event;
    }

    public onEmployeeClicked($event: EmployeeDeclaration) {
        this.selectedEmployee = $event;
    }

    public onApplyFilter($event: IwGridColumn<EmployeeDeclaration>) {
        this.filterService.addFilter($event);
        this.filteredEmployees = this.filterService.applyFilters(this.originalEmployees);
    }

    private _onSort() {
        if (!this.tableSortEvent) return;
        this.filteredEmployees = [...sortGridList(this.filteredEmployees, this.tableSortEvent)];
    }

    private async showContextMenu(event: MouseEvent) {
        if (this.menu) {
            await this.menu.show(event);
        }
    }

    private saveEmployeeDeclaration(empId: string, employee: EmployeeDeclaration) {
        this.subscribeToSaveEffect(empId);
        this._store.dispatch(new actions.EntityUpdate(employee));
    }

    private subscribeToSaveEffect(selectedId: string) {
        const sub = this._updates.pipe(ofType(actions.ENTITY_UPDATE_SUCCESS))
            .subscribe((action: actions.EntityUpdateSuccess<EmployeeDeclaration>) => {
                if (this.isEmployeeDeclarationUpdated(selectedId, action.entity)) {
                    this.loadEmployees();
                }
                sub.unsubscribe();
            });
    }

    private isEmployeeDeclarationUpdated(selectedId: string, employee: EmployeeDeclaration): boolean {
        return isRestEntity(employee) && employee.id === selectedId;
    }

    private loadEmployees() {
        this._swissdecService.loadEmployees(this.getFormValue('id') || '').subscribe((employees: EmployeeDeclaration[]) => {
            this.originalEmployees = employees;
            this.filteredEmployees = employees;
            this.employeesWithSplitAvs = employees.filter(e => e.avsIncome ? e.avsIncome < 0 : false);
        });
    }

    private buildContextMenu() {
        const menu: MenuItem[] = [];
        Object.values(EmployeeDeclarationMenuEvent).forEach((event) => {
            menu.push(
                {
                    label: toLower(event),
                    event: event,
                    contextMenuVisibleMode: 'all'
                },
            );
        });
        return menu;
    }

}
