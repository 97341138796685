import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseSideFormComponent} from '@app/sam-base/base/base-side-form.component';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {
    EntityNavigationService,
    IwEventHubService,
    IwEvents,
    IwStoreService,
    ModalService,
    ViewContainerRefService
} from '@app/sam-base/core';
import {FactureService} from '@app/sam-base/core/services/facture.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {SetLoading} from '@app/sam-base/core/store/actions/global-form.actions';
import {Gefachdr} from '@app/sam-base/models/invoices/gefachdr';
import {CommunicationType} from '@core/mail/mail.models';
import {ParametersService} from '@core/services/parameters.service';
import {MailComposerComponent} from '@modules/sam-main/placement/components/mail-composer/mail-composer.component';
import {MessageFormRefreshService} from '@modules/sam-main/subjects/message-service-subject.service';
import {TranslateService} from '@ngx-translate/core';
import {ModalPaiementsOptions} from '@sam-base/components/modal/modal-paiements/modal-paiements-options.model';
import {ModalPaiementsComponent} from '@sam-base/components/modal/modal-paiements/modal-paiements.component';
import {
    factureComRoles,
    factureEncaissementsRole,
    facturePrintRoles
} from '@sam-base/core/auth/access-rules/facture-roles';
import {EncaissementService} from '@sam-base/core/services/encaissement.service';
import {GecliService} from '@sam-base/core/services/gecli.service';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {Subject, takeUntil} from 'rxjs';


@Component({
    templateUrl: './facture-nc-side-menu.component.html'
})
export class FactureNCSideMenuComponent extends BaseSideFormComponent<Gefachdr> implements OnInit, OnDestroy {
    @Input() public isReadonly = true;
    public gefac: Gefachdr = new Gefachdr();
    public isLoading = false;
    public isEditMode = '';
    public facByEmail = false;
    public FACTURE_COMMUNICATION_ROLES: SamUserRole[] = factureComRoles();
    public FACTURE_PRINT_ROLES: SamUserRole[] = facturePrintRoles();
    public FACTURE_ADMIN_ROLES: SamUserRole[] = factureEncaissementsRole();
    private subscription = new Subject();

    constructor(private readonly _store: IwStoreService, private _translate: TranslateService,
                private _modalService: ModalService, private _factureService: FactureService,
                private _encaissementService: EncaissementService, private _parametersService: ParametersService,
                private _navigationService: EntityNavigationService, private _viewHostRef: ViewContainerRefService,
                private readonly _eventHub: IwEventHubService<IwEvents>,
                private readonly refreshFormMessageService: MessageFormRefreshService,
                private readonly _gecliService: GecliService) {
        super();
    }

    get canPublish(): boolean {
        return this.gefac?.published === false;
    }

    get canSendByMail(): boolean {
        return this.gefac?.published === true && this.facByEmail;
    }

    public ngOnInit(): void {
        this._store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscription))
            .subscribe((data: any) => {
                this.gefac = data;
                if (data.cliId) {
                    this._gecliService.getGecliByCliId(data.cliId).subscribe(cli => {
                        this.facByEmail = cli.facbyemail || false;
                    });
                }
            });
        this._store.globalForm(this.uuid)
            .state
            .pipe(takeUntil(this.subscription))
            .subscribe((state: any) => {
                this.isEditMode = state.editMode;
            });
    }

    public ngOnDestroy(): void {
    }

    public async openPrint() {
        this._store.dispatch(new SetLoading(this.uuid, true));
        this._factureService.download([this.gefac?.facId!]).subscribe({
            next: value => {
                this._store.dispatch(new SetLoading(this.uuid, false));
            },
            error: error => {
                this._store.dispatch(new SetLoading(this.uuid, false));
                console.error(error);
            }
        });
    }

    public publish() {
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this._factureService.publish([this.gefac!.facId!]).subscribe({
            next: qvalue => {
                this._store.dispatch(new actions.SetLoading(this.uuid, false));
                this._store.dispatch(new actions.LoadEntity(this.uuid));
            },
            error: error => {
                this._store.dispatch(new actions.SetLoading(this.uuid, false));
                console.error(error);
            }
        });
    }

    public sendByMail() {
        const ids: string[] = [];
        ids.push(this.gefac!.facId! || '');

        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.GEFACHDR_FAC,
            entityIds: ids
        });
    }

    public async copy() {
        const options: ModalMessageOptions = {
            message: [this._translate.instant('copierFacture')],
            showCancel: true,
            title: '',
            width: 400
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            this.isLoading = true;
            this._store.dispatch(new actions.SetLoading(this.uuid, true));
            this._factureService.copyFacture(this.gefac.facId, 1)
                .subscribe((gefac) => {
                    this._navigationService.navigateToEntityForm(Gefachdr, gefac.facId || '', undefined, 'edit');
                    this._store.dispatch(new actions.DestroyForm(this.uuid));
                });
        } catch (error) {
            this.isLoading = false;
            this._store.dispatch(new actions.SetLoading(this.uuid, false));
        }
    }

    public async createFacOrNc() {

        const options: ModalMessageOptions = {
            message: [this.gefac.facOrnc === 'N' ? this._translate.instant('createFAC') : this._translate.instant('createNC')],
            showCancel: true,
            title: '',
            width: 400
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            this.isLoading = true;
            this._store.dispatch(new actions.SetLoading(this.uuid, true));

            this._factureService.copyFacture(this.gefac.facId, this.gefac.facOrnc === 'N' ? 1 : -1)
                .subscribe((gefac) => {
                    this._navigationService.navigateToEntityForm(Gefachdr, gefac.facId || '', undefined, 'edit');
                    this._store.dispatch(new actions.DestroyForm(this.uuid));
                });

        } catch (error) {
            this.isLoading = false;
            this._store.dispatch(new actions.SetLoading(this.uuid, false));
        }
    }

    public openEncaissements(): void {
        if (this.gefac.facId) {
            this._encaissementService.getEncaissementsTable(this.gefac.facId)
                .subscribe((paiements) => {
                    const options: ModalPaiementsOptions = {
                        content: paiements,
                        okDisabled: false,
                        showCancel: false,
                        title: this._translate.instant('paiements'),
                        width: 1065
                    };
                    this._modalService.showModal(ModalPaiementsComponent, options);
                });
        }
    }

}
