<form [formGroup]="formGroup"
      class="row">
    <div [title]="'statpaytype' | translate"
         class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'statpaytype' | translate }}
    </div>
    <iw-enum-dropdown (valueChange)="changeSdsIOptions($event)"
                      [inputEnum]="statpaytype"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-9 padding-top-10"
                      formControlName="statpaytype"></iw-enum-dropdown>

    <div [title]="'contratDeTravail' | translate"
         class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'contratDeTravail' | translate }}
    </div>
    <iw-enum-dropdown [inputEnum]="optionI"
                      [isDisabled]="isReadonly || !optionI"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-9 padding-top-10"
                      formControlName="sdsI"></iw-enum-dropdown>

    <ng-template [ngIf]="sds1Active">
        <div [title]="'monthContSalary' | translate"
             class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'monthContSalary' | translate }}
        </div>
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-9 padding-top-10 text-align-right"
                      formControlName="monthContSalary"
                      type="number"></iw-textfield>
        <div [title]="'monthCont13' | translate"
             class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'monthCont13' | translate }}
        </div>
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-9 padding-top-10 text-align-right"
                      formControlName="monthCont13"
                      type="number"></iw-textfield>
        <div [title]="'monthCont14' | translate"
             class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'monthCont14' | translate }}
        </div>
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-9 padding-top-10 text-align-right"
                      formControlName="monthCont14"
                      type="number"></iw-textfield>
    </ng-template>
    <ng-template [ngIf]="sds2Active">
        <div [title]="'hourlyContPaidByHour' | translate"
             class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'hourlyContPaidByHour' | translate }}
        </div>
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-9 padding-top-10 text-align-right"
                      formControlName="hourlyContPaidByHour"
                      type="number"></iw-textfield>
        <div [title]="'hourlyContPaidByLesson' | translate"
             class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'hourlyContPaidByLesson' | translate }}
        </div>
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-9 padding-top-10 text-align-right"
                      formControlName="hourlyContPaidByLesson"
                      type="number"></iw-textfield>
        <div [title]="'hourlyContVac' | translate"
             class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'hourlyContVac' | translate }}
        </div>
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-9 padding-top-10 text-align-right"
                      formControlName="hourlyContVac"
                      type="number"></iw-textfield>
        <div [title]="'hourlyContJf' | translate"
             class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'hourlyContJf' | translate }}
        </div>
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-9 padding-top-10 text-align-right"
                      formControlName="hourlyContJf"
                      type="number"></iw-textfield>
        <div [title]="'hourlyCont13' | translate"
             class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'hourlyCont13' | translate }}
        </div>
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-9 padding-top-10 text-align-right"
                      formControlName="hourlyCont13"
                      type="number"></iw-textfield>
    </ng-template>

    <ng-template [ngIf]="sds3Active">
        <div [title]="'noTimeContSalary' | translate"
             class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'noTimeContSalary' | translate }}
        </div>
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-9 padding-top-10 text-align-right"
                      formControlName="noTimeContSalary"
                      type="number"></iw-textfield>
        <div [title]="'noTimeContVac' | translate"
             class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'noTimeContVac' | translate }}
        </div>
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-9 padding-top-10 text-align-right"
                      formControlName="noTimeContVac"
                      type="number"></iw-textfield>
        <div [title]="'noTimeContJf' | translate"
             class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'noTimeContJf' | translate }}
        </div>
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-9 padding-top-10 text-align-right"
                      formControlName="noTimeContJf"
                      type="number"></iw-textfield>
        <div [title]="'noTimeCont13' | translate"
             class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'noTimeCont13' | translate }}
        </div>
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-9 padding-top-10 text-align-right"
                      formControlName="noTimeCont13"
                      type="number"></iw-textfield>

    </ng-template>

    <div [title]="'educationFormation' | translate"
         class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'educationFormation' | translate }}
    </div>
    <iw-dropdown #educationFormation
                 [isDisabled]="isReadonly"
                 [labelAlign]="'left'"
                 [options]="optionB"
                 class="iw-input col-xs-9 padding-top-10"
                 formControlName="sdsB"
                 id="educationFormation"></iw-dropdown>

    <div [title]="'position' | translate"
         class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'position' | translate }}
    </div>
    <iw-dropdown #position
                 [isDisabled]="isReadonly"
                 [labelAlign]="'left'"
                 [options]="optionD"
                 class="iw-input col-xs-9 padding-top-10"
                 formControlName="sdsD"
                 id="position"></iw-dropdown>

    <div [title]="'typeEmploi' | translate"
         class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'typeEmploi' | translate }}
    </div>
    <iw-dropdown #typeEmploi
                 [isDisabled]="isReadonly"
                 [labelAlign]="'left'"
                 [options]="optionJ"
                 class="iw-input col-xs-9 padding-top-10"
                 formControlName="sdsJ"
                 id="typeEmploi"></iw-dropdown>

    <div [title]="'confession' | translate"
         class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'confession' | translate }}
    </div>
    <iw-dropdown #confession
                 [isDisabled]="isReadonly"
                 [labelAlign]="'left'"
                 [options]="optionK"
                 class="iw-input col-xs-9 padding-top-10"
                 formControlName="sdsK"
                 id="confession"></iw-dropdown>

    <div [title]="'residence' | translate"
         class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'residence' | translate }}
    </div>
    <iw-dropdown #residence
                 [isDisabled]="isReadonly"
                 [labelAlign]="'left'"
                 [options]="optionM"
                 class="iw-input col-xs-9 padding-top-10"
                 formControlName="sdsM"
                 id="residence"></iw-dropdown>

    <ng-template [ngIf]="residenceWeek">
        <div [title]="'adresse1Oth' | translate"
             class="iw-label label-ellipsis col-xs-offset-1 col-xs-3 flex-end">{{ 'adresse1Oth' | translate }}
        </div>
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-8 padding-top-10"
                      formControlName="adresse1Oth"></iw-textfield>
        <div [title]="'adresse2Oth' | translate"
             class="iw-label label-ellipsis col-xs-offset-1 col-xs-3 flex-end">{{ 'adresse2Oth' | translate }}
        </div>
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-8 padding-top-10"
                      formControlName="adresse2Oth"></iw-textfield>

        <iw-npa-lieu [formGroup]="formGroup"
                     [isDisabled]="isReadonly"
                     [labelNpaAlign]="'left'"
                     [lieuFormControlName]="'lieuoth'"
                     [npaFormControlName]="'npaoth'"
                     [pays]="paysoth"
                     class="iw-input col-xs-offset-1 col-xs-11"></iw-npa-lieu>
    </ng-template>
    <ng-template [ngIf]="residenceWeek || residenceDaily">
        <div [title]="'paysoth' | translate"
             class="iw-label label-ellipsis col-xs-offset-1 col-xs-3 flex-end">{{ 'paysoth' | translate }}
        </div>
        <iw-pays-dropdown [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [width]="'100%'"
                          class="iw-input col-xs-8 padding-top-10"
                          formControlName="paysoth"></iw-pays-dropdown>
    </ng-template>

    <div [title]="'autreActivite' | translate"
         class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'autreActivite' | translate }}
    </div>
    <iw-dropdown #autreActivite
                 [isDisabled]="isReadonly"
                 [labelAlign]="'left'"
                 [options]="optionN"
                 class="iw-input col-xs-9 padding-top-10"
                 formControlName="sdsN"
                 id="autreActivite"></iw-dropdown>

    <ng-template [ngIf]="hide">
        <div [title]="'niveauQualification' | translate"
             class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'niveauQualification' | translate }}
        </div>
        <iw-dropdown #niveauQualification
                     [isDisabled]="isReadonly"
                     [labelAlign]="'left'"
                     [options]="optionC"
                     class="iw-input col-xs-9 padding-top-10"
                     formControlName="sdsC"
                     id="niveauQualification"></iw-dropdown>

        <div [title]="'domaine' | translate"
             class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'domaine' | translate }}
        </div>
        <iw-dropdown #domaine
                     [isDisabled]="isReadonly"
                     [labelAlign]="'left'"
                     [options]="optionE"
                     class="iw-input col-xs-9 padding-top-10"
                     formControlName="sdsE"
                     id="domaine"></iw-dropdown>

        <div [title]="'formeSalaire' | translate"
             class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'formeSalaire' | translate }}
        </div>
        <iw-dropdown #formeSalaire
                     [isDisabled]="isReadonly"
                     [labelAlign]="'left'"
                     [options]="optionH"
                     class="iw-input col-xs-9 padding-top-10"
                     formControlName="sdsH"
                     id="formeSalaire"></iw-dropdown>
    </ng-template>

    <div [title]="'degreeofrel' | translate"
         class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'degreeofrel' | translate }}
    </div>
    <iw-enum-dropdown [inputEnum]="degreeofrel"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      class="iw-input col-xs-9 padding-top-10"
                      formControlName="degreeofrel"></iw-enum-dropdown>

    <div [title]="'pad' | translate"
         class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'pad' | translate }}
    </div>
    <iw-checkbox [isDisabled]="isReadonly"
                 class="iw-input col-xs-9 padding-top-10"
                 formControlName="pad"></iw-checkbox>

    <div [title]="'ppap' | translate"
         class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'ppap' | translate }}
    </div>
    <iw-checkbox [isDisabled]="isReadonly"
                 class="iw-input col-xs-9 padding-top-10"
                 formControlName="ppap"></iw-checkbox>

    <div [title]="'profilage' | translate"
         class="iw-label label-ellipsis col-xs-3 flex-end">{{ 'profilage' | translate }}
    </div>
    <iw-textfield [isDisabled]="isReadonly"
                  [labelAlign]="'left'"
                  class="iw-input col-xs-9 padding-top-10"
                  formControlName="profilage"></iw-textfield>

</form>
