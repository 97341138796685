import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwTableComponent} from '@app/sam-base/components/iw-table/iw-table.component';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {IwEventHubService} from '@app/sam-base/core/events';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {ViewContainerRefService} from '@app/sam-base/core/services/view-container-ref.service';
import {IwStoreService} from '@app/sam-base/core/store';
import * as globalFormActions from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast/toast.service';
import {getWeekNumber} from '@app/sam-base/helpers/date';
import {FormKeys, IwGridColumn} from '@app/sam-base/models';
import {Ppheusaid, Ppind, Pprap} from '@app/sam-base/models/placement';
import {
    HourAddEvent,
    Ppheusaid2Input,
    Ppheusaid2Output
} from '@app/shared/widgets/iw-components/iw-hours-grid/iw-hours-grid.model';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';
import {ModalTextAreaComponent} from '@shared/widgets/modal-components/modal-text-area/modal-text-area.component';
import {lastValueFrom, of, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

import {Ppheusaid2Hours} from '../../../../../shared/widgets/iw-components/iw-hours-grid/iw-hours-grid.model';
import {mapPpheusaidToPpheusaid2} from '../../helpers/map-ppheusaid';
import {checkHoursFormat} from '../../helpers/reports.helper';
import {PlacementForm} from '../../placement.forms';
import {MissionMisIhService} from '../../services/mission-misih.service';
import {PpheusaidService} from '../../services/ppheusaid.service';
import {ReportsService} from '../../services/reports.service';
import {Ppheusaid2Save} from './ppheusaid2-save.model';

/**
 * Component created regarding SAM-4567
 */
@Component({
    templateUrl: './ppheusaid2.component.html'
})
export class Ppheusaid2Component extends BaseStoreFormComponent<Ppheusaid> implements OnInit, OnDestroy {

    /**
     * Configs to indemnite grid
     */
    public columns = this.getIndColumns();
    public indemnites: Ppind[] = [];
    public totEmpValue = '';
    public totCliValue = '';
    public disabledBtnModDelInd = true;

    public ppheusaid?: Ppheusaid;
    public gridOutput?: Ppheusaid2Output;
    public gridHoursInput?: Ppheusaid2Input;

    /**
     * Data to show
     */
    public note?: string;
    public cliId?: string;
    public rapNo?: string;
    public rapId?: string;
    public salId?: string;
    public misId?: string;
    public frais?: string;
    public qualif?: string;
    public empName?: string;
    public noteemp?: string;
    public notecli?: string;
    public rapNote?: string;
    public finmotif?: string;
    public rapnoref?: boolean;
    public freezCha?: boolean;
    public edtValue?: string;
    public faclintxt?: string;
    public datelundi?: string;
    public datelundiFormatted?: string;
    public refclient?: string;
    public weekNumber?: string;
    public finmission?: string;
    public hpauseauto?: boolean;
    public forceFacHeurePause?: boolean;
    public clientName?: string;
    public heurepause?: boolean;


    @ViewChild('grdInd', {static: true}) public table?: IwTableComponent<Ppind>;
    public saveClick = new Subject<boolean>();
    public isHpauseAuto = false;
    private _selected?: Ppind;
    private subscriptions = new Subject();

    constructor(store: IwStoreService, private readonly _events: IwEventHubService<string>,
                private readonly _translate: TranslateService, private readonly _viewHostRef: ViewContainerRefService,
                private readonly _formHandler: FormHandlerService<PlacementForm>,
                private readonly _modalService: ModalService, private readonly _toastService: ToastService,
                private readonly _ppheusaidService: PpheusaidService, private readonly _rapports: ReportsService,
                private readonly _parameters: ParametersService, private readonly _misihService: MissionMisIhService) {
        super(store);
        this._events.forType<[Ppind, number]>('indemnite')
            .pipe(takeUntil(this.subscriptions))
            .subscribe(e => {
                if (e.payload) {
                    this.setIndemnites(e.payload[0], e.payload[1]);
                }
            });
    }

    public set dateLundiFromRapport(datelundi: string) {
        this.datelundi = datelundi;
        this.datelundiFormatted = IwDateHelper
            .dateFormatFromString(new Date(datelundi), 'DD.MM.YYYY');
    }

    public ngOnInit() {
        const rapport: Pprap | undefined = this.getData('pprap');
        this.dateLundiFromRapport = this.getData('datelundi') ?? '';
        this.getActSalId()
            .subscribe(e => this.salId = e);
        if (rapport?.rapId) {
            this._ppheusaidService.getDataByRapId(rapport?.rapId)
                .subscribe((elem) => {
                    this.initializeData(elem, rapport);
                });
        } else if (rapport?.misId) {
            this._ppheusaidService.getDataByMisId(rapport?.misId)
                .subscribe((elem) => {
                    this.initializeData(elem, rapport);
                });
        }
        this.saveClick.pipe(debounceTime(500))
            .subscribe((val) => val ? this.saveData() : '');
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        this.saveClick.next(false);
        this.saveClick.complete();
        super.ngOnDestroy();
    }

    public onHpauseAutoChange(event: boolean) {
        this.hpauseauto = event;
    }

    public onForceFacHeurePauseChange(event: boolean) {
        this.forceFacHeurePause = event;
    }

    public getActSalId() {
        return this._parameters.getString('actSalId');
    }

    /**
     * Function to set indemnites in table
     *
     * @param v indemnite edited or created
     * @param n index of indemnites
     */
    public setIndemnites(v?: Ppind, n?: number): void {
        if (v) {
            if (n !== undefined) {
                this.indemnites[n] = v;
            } else {
                this.indemnites.push(v);
            }
            this.updateIndTable(this.indemnites);
        }
    }

    /**
     * Set grid output with values from hours grid
     *
     * @param event Ppheusaid2Output - value from hours grid
     */
    public setGridOutput(event: Ppheusaid2Output): void {
        this.gridOutput = event;
    }

    /**
     * Function to set selected row
     *
     * @param event Ppind[] - row selected
     */
    public setSelectedRow(event: Ppind[]): void {
        this._selected = (event && event[0]) || undefined;
        if (this._selected) {
            this.disabledBtnModDelInd = false;
        }
    }

    /**
     * Function to call modal to create new indemnite
     */
    public newIndemnite(): void {
        this._formHandler.showFormDialog(PlacementForm.Ppindemnite, {
            misId: this.misId,
            cliId: this.cliId
        }, s => ({
            ...s,
            diagModal: true
        }));
    }

    /**
     * Function to edit indemnite
     */
    public editIndemnite(): void {
        if (this._selected) {
            const i = this.indemnites.findIndex(x => this._selected === x);
            const ppind: Ppind = this.indemnites[i];
            this._formHandler.showFormDialog(PlacementForm.Ppindemnite, {
                misId: this.misId,
                ppind,
                index: (i + 1),
                cliId: this.cliId
            }, s => ({
                ...s,
                editMode: 'edit',
                diagModal: true
            }));
        }
    }

    /**
     * Function to delete indemnite
     */
    public async deleteIndemnite() {
        if (this._selected) {
            const options: ModalMessageOptions = {
                // eslint-disable-next-line max-len
                message: [this._translate.instant('supprimerIndem') + ' (' + this._selected.libelle + ') ?'],
                showCancel: true,
                title: 'SAM'
            };
            try {
                await this._modalService.showModal(ModalMessageComponent, options);
                const i = this.indemnites.findIndex(x => this._selected === x);
                this.indemnites.splice(i, 1);
                this.updateIndTable(this.indemnites);
            } catch (err) {
            }
        }
    }

    /**
     * Modal to edit rapNote field
     */
    public rapportNote(): void {
        this._viewHostRef.loadComponent({
            type: ModalTextAreaComponent,
            data: {
                value: this.rapNote,
                onOk: (val: any) => this.rapNote = val,
                state: {
                    ...this,
                    isReadOnly: this.isReadonly
                },
                btnOk: {
                    enabled: !this.isReadonly,
                    value: 'save',
                    visible: true
                }
            }
        });
    }

    public saveDataClick() {
        this._store.dispatch(new globalFormActions.SetLoading(this.uuid, true));
        this.saveClick.next(true);
    }

    /**
     * Function to save data
     */
    // eslint-disable-next-line complexity
    public async saveData(): Promise<void> {
        if (this.ppheusaid) {
            this.setDataToRapport();

            const dataToSave: Ppheusaid2Save = {
                rapport: this.ppheusaid?.rap,
                hours: this.gridOutput?.gridHours,
                indemnites: this.indemnites,
                timeformat: checkHoursFormat(this.gridOutput)
            };
            try {
                const isRapNoUnique = await lastValueFrom(this.isRapNoUnique());

                if (!isRapNoUnique) {
                    this._toastService.error(this._translate.instant('rapport_no_already_exists'));
                    this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
                    return;
                }
                const addHours = await this.openModal();
                if (addHours) {
                    this._store.dispatch(new globalFormActions.SetLoading(this.uuid, true));
                    await lastValueFrom(this._ppheusaidService.saveData(dataToSave));
                    this._toastService.success('success_rapport');
                    this._events.emit('hour_grid_close', {
                        datelundi: this.datelundi,
                        misId: this.misId
                    });
                    this.destroyForm();
                } else {
                    this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
                }
            } catch (error: any) {
                let message = '';
                this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
                if (error.status === 409) {
                    message = this._translate.instant('conflit_rapport');
                    this._toastService.error(message);
                }
            }
        }
    }

    public async openModal(): Promise<boolean> {
        const options: ModalMessageOptions = {
            message: [],
            showCancel: true,
            title: this._translate.instant('rapport'),
            confirmMessage: this._translate.instant('rapportconfirm'),
            alertsMessage: '',
            okDisabled: false
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            return true;
        } catch (err) {
            return false;
        }
    }

    public addHourToMission(hourEvent: HourAddEvent) {
        if (this.misId) {
            this._misihService.createOrUpdate(this.misId, hourEvent.hourType)
                .subscribe(// eslint-disable-next-line complexity
                    hour => {
                        const newHour: Ppheusaid2Hours = {
                            rapId: this.rapId ?? '',
                            indorheu: hour?.indorheu ?? '',
                            cid: hour?.cid ?? '',
                            grhType: '',
                            grhId: hour?.grhId ?? '',
                            griId: '',
                            gsId: '',
                            day1Hour: 0,
                            day2Hour: 0,
                            day3Hour: 0,
                            day4Hour: 0,
                            day5Hour: 0,
                            day6Hour: 0,
                            day7Hour: 0,
                            totalHours: 0,
                            libelle: hour?.libelle ?? '',
                            lhmm: hourEvent.lhmm,
                            mntemp: hour?.mntemp ?? 0,
                            mntcli: hour?.mntcli ?? 0,
                            misihId: hour?.misihId ?? '',
                            misId: hour?.misId ?? ''
                        };
                        this.gridHoursInput?.gridHours.push(newHour);
                    });
        }
    }

    public addNewChantier() {
        if (!this.cliId) {
            return;
        }

        this._events.forType<string>('chantier_closed_and_update')
            .pipe(takeUntil(this.subscriptions))
            .subscribe((e) => {
                this.setFormValue('chantier', e.payload);
            });

        this._formHandler
            .showFormDialog(PlacementForm.Ppclicha, {
                entityId: this.cliId,
                fromRapportMode: true
            }, s => ({
                ...s,
                diagModal: true
            }));

    }

    protected getFormControlNames(): FormKeys<Ppheusaid> {
        return ['chantier'];
    }

    private initializeData(elem: Ppheusaid, rapport?: Pprap) {
        this.cliId = elem.mis?.cliId;

        this.ppheusaid = elem;
        this.rapnoref = this.ppheusaid?.rapnoref ?? false;

        if (elem.mis?.forcecha) {
            this.formGroup.controls['chantier'].setValidators(Validators.required);
            this.formGroup.controls['chantier'].updateValueAndValidity();
        }
        this.setGridHoursInput(elem);
        if (rapport) {
            this.setDataToForm(rapport.rapId);
        }
    }

    /**
     * Method to set data to show
     */
    // eslint-disable-next-line complexity
    private setDataToForm(rapId?: string): void {
        this.note = this.ppheusaid?.mis?.note ?? '';

        this.rapNo = (this.ppheusaid?.rap?.rapNo || this.ppheusaid?.rap?.rapId);

        this.rapId = this.ppheusaid?.rap?.rapId ?? '';
        if (rapId) {
            this.salId = this.ppheusaid?.rap?.salId ?? '';
            this.hpauseauto = this.ppheusaid?.rap?.hpauseauto ?? false;
            this.forceFacHeurePause = this.ppheusaid?.rap?.forceFacHeurePause ?? false;
        } else {
            this.hpauseauto = this.ppheusaid?.mis?.hpauseauto ?? false;
        }
        this.rapNote = this.ppheusaid?.rap?.note ?? '';
        this.misId = this.ppheusaid?.mis?.misId ?? '';
        this.frais = this.ppheusaid?.mis?.frais ?? '';
        this.qualif = this.ppheusaid?.mis?.qualif ?? '';
        this.empName = this.ppheusaid?.empName ?? '';
        this.noteemp = this.ppheusaid?.mis?.noteemp ?? '';
        this.notecli = this.ppheusaid?.mis?.notecli ?? '';
        if (rapId) {
            this.setFormValue('chantier', this.ppheusaid?.rap?.chantier ?? '');
        } else {
            this.setFormValue('chantier', this.ppheusaid?.mis?.chantier ?? '');
        }
        this.finmotif = this.ppheusaid?.rap?.finmotif ?? '';
        this.freezCha = this.ppheusaid?.mis?.freezcha ?? false;
        this.edtValue = this.getEdtValue(this.ppheusaid);
        this.faclintxt = this.ppheusaid?.rap?.faclintxt ?? this.ppheusaid?.mis?.faclintxt ?? '';
        this.refclient = this.ppheusaid?.mis?.refclient?.trim() ?? '';
        this.weekNumber = this._translate.instant('se') + ' ' + getWeekNumber(this.datelundi || '');
        this.finmission = this.ppheusaid?.rap?.finmission ?? '';
        this.heurepause = this.ppheusaid?.mis?.heurepause ?? false;
        this.clientName = this.ppheusaid?.clientName ?? '';
        this.indemnites = this.ppheusaid?.ind ?? [];
        this.calcTotCli();
        this.calcTotEmp();
    }

    /**
     * Method to set data to rapport
     */
    private setDataToRapport(): void {
        if (this.ppheusaid && this.ppheusaid.rap) {
            this.ppheusaid.rap.chantier = this.getFormValue('chantier');
            this.ppheusaid.rap.finmotif = this.finmotif;
            this.ppheusaid.rap.faclintxt = this.faclintxt;
            this.ppheusaid.rap.finmission = this.finmission;
            this.ppheusaid.rap.hpauseauto = this.hpauseauto;
            this.ppheusaid.rap.forceFacHeurePause = this.forceFacHeurePause;
            this.ppheusaid.rap.misId = this.misId;
            this.ppheusaid.rap.datelundi = this.datelundi;
            this.ppheusaid.rap.note = this.rapNote;
            this.ppheusaid.rap.salId = this.salId;
            this.ppheusaid.rap.rapNo = this.rapNo;
        }
    }


    private isRapNoUnique() {
        const rapNo = this.ppheusaid?.rap?.rapNo;
        const rapId = this.ppheusaid?.rap?.rapId || '0';

        if (!rapNo || rapNo === rapId) {
            return of(true);
        }


        return this._ppheusaidService.isReportNoUnique(rapNo, rapId);
    }

    /**
     * Function to set data for hours grid
     *
     * @param dt Ppheusaid - inital value to put in hours grid
     */
    // eslint-disable-next-line complexity
    private setGridHoursInput(dt?: Ppheusaid): string | void {
        if (!dt) {
            return '';
        }
        const gridInput: Ppheusaid2Input = {
            gridHours: [],
            heurepause: {
                exists: dt.mis?.heurepause || false,
                pauseMode: dt.mis?.hpausaimod || 0,
                pauseMontant: dt.mis?.mntPause || 0,
                pauseTx: dt.mis?.txPause || 0,
                auto: dt.rap?.hpauseauto || false
            },
            datestart: dt.scheduleEvent?.dateStart ?? '',
            datelundi: IwDateHelper.getMoment(this.datelundi)
        };
        gridInput.gridHours = mapPpheusaidToPpheusaid2(dt, new Date(this.datelundi || ''));
        if (!this.gridOutput) {
            return;
        }
        this.gridOutput.gridHours = mapPpheusaidToPpheusaid2(dt, new Date(this.datelundi || ''));
        this.gridHoursInput = gridInput;
        this.gridHoursInput.gridDays = this._rapports.setActiveDays(this.datelundi, dt.scheduleEvent);
    }

    /**
     * Function to get and format edtInfo field value
     *
     * @param dt Ppheusaid - inital value to get value for edtInfo field
     */
    // eslint-disable-next-line complexity
    private getEdtValue(dt?: Ppheusaid): string {
        if (!dt) {
            return '';
        }
        const m1 = 12; // Value from legacy
        const m2 = 29; // Value from legacy
        let val = '';
        val += this._translate.instant('cct') + ': ' + (dt.mis?.cctId || '') + '\n';
        val += '-'.repeat(m2) + '\n';
        val += this._translate.instant('salBase')
            .padEnd(m1) + ': ' + (dt.mis?.salBase || '') + '\n';

        if (dt.mis?.mntVac !== 0 || dt.mis.txVac !== 0) {
            val += this._translate.instant('vacances')
                .padEnd(m1) + ': ' + (dt.mis?.mntVac || '') + ' ' + (dt.mis?.txVac || '') + '%' + (dt.mis?.lprovVac ? ' P' : '') + '\n';
        }
        if (dt.mis?.mnt13 !== 0 || dt.mis.tx13 !== 0) {
            val += '13e'.padEnd(m1) + ': ' + (dt.mis?.mnt13 || '') + ' ' + (dt.mis?.tx13 || '') + '%' + (dt.mis?.lprov13 ? ' P' : '') + '\n';
        }
        if (dt.mis?.mntJf !== 0 || dt.mis.txJf !== 0) {
            val += 'JF'.padEnd(m1) + ': ' + (dt.mis?.mntJf || '') + ' ' + (dt.mis?.txJf || '') + '%' + (dt.mis?.lprovJf ? ' P' : '') + '\n';
        }
        val += this._translate.instant('salGlobal')
            .padEnd(m1) + ': ' + (dt.mis?.salGlobal || '') + '\n';
        val += '-'.repeat(m2) + '\n';
        val += this._translate.instant('tarif')
            .padEnd(m1) + ': ' + (dt.mis?.clitarif || '') + '\n';
        return val;
    }

    /**
     * Function to calculate total of employe indemnites
     */
    private calcTotEmp(): void {
        let tot = 0;
        for (const ind of this.indemnites) {
            if (ind.totemp) {
                tot = tot + Number(ind.totemp);
            }
        }
        this.totEmpValue = this._translate.instant('empId') + ': ' + tot.toFixed(2);
    }

    /**
     * Function to calculate total of client indemnites
     */
    private calcTotCli(): void {
        let tot = 0;
        for (const ind of this.indemnites) {
            if (ind.totcli) {
                tot = tot + Number(ind.totcli);
            }
        }
        this.totCliValue = this._translate.instant('client') + ': ' + tot.toFixed(2);
    }

    /**
     * Method to update table of indemnites
     *
     * @param v array with data to update
     */
    private updateIndTable(v: Ppind[]): void {
        if (this.table) {
            this.table.data = v.sort((a, b) => {
                const libelleA = a.libelle ? a.libelle.toLowerCase() : "";
                const libelleB = b.libelle ? b.libelle.toLowerCase() : "";
                if (libelleA < libelleB) return -1;
                if (libelleA > libelleB) return 1;
                return 0;
            });

            this.table.data = v;
            this.calcTotCli();
            this.calcTotEmp();
            this.table.refresh();
        }
    }

    /**
     * Method to get columns for table of indemnites
     */
    private getIndColumns(): IwGridColumn<Ppind>[] {
        return [
            {
                prop: 'libelle',
                name: 'indemnitee',
                index: 0
            },
            {
                prop: 'qte',
                name: 'nb',
                index: 1,
                decimals: 2
            },
            {
                prop: 'mntemp',
                name: 'mntemp',
                index: 2,
                decimals: 2
            },
            {
                prop: 'mntcli',
                name: 'mntcli',
                index: 3,
                decimals: 2
            },
            {
                prop: 'totemp',
                name: 'totEmp',
                index: 4,
                decimals: 2
            },
            {
                prop: 'totcli',
                name: 'totCli',
                index: 5,
                decimals: 2
            }];
    }

}
