<form [formGroup]="formGroup"
      class="row space-form">
    <iw-textfield
            [isDisabled]="true"
            [value]="domain"
            class="iw-input col-xs-4 no-padding"></iw-textfield>

    <iw-textfield
            [isDisabled]="true"
            [value]="'enum.processFlow.' + processFlow | translate"
            class="iw-input col-xs-5 no-padding label-name"></iw-textfield>

    <!--    <iw-textfield-->
    <!--            [isDisabled]="true"-->
    <!--            [value]="'enum.sxStatus.' + status | translate"-->
    <!--            class="iw-input col-xs-3 no-padding"></iw-textfield>-->
    <div class="row">
        <iw-busy [isBusy]="isLoading"></iw-busy>
        <div class="row col-xs-12 no-padding-right"
             style="padding-left: 10%"></div>
    </div>

    <iw-busy [isBusy]="isLoading"></iw-busy>
</form>

<iw-tab [(selectedTab)]="selectedTab"
        [invalidTabs]="invalidTabList"
        [tabs]="tabList">

    <iw-tab-sasx5institution-general *ngIf="selectedTab?.title === 'general'"
                                     [formGroup]="formGroup"></iw-tab-sasx5institution-general>
    <iw-tab-sasx5institution-dialog (institutionUpdated)="onInstitutionUpdated($event)"
                                    *ngIf="selectedTab?.title === 'dialog'"
                                    [formGroup]="formGroup"
                                    [uuid]="uuid"></iw-tab-sasx5institution-dialog>

    <iw-tab-sasx-xml *ngIf="selectedTab?.title === 'xml'"
                     [xmlContent]="xml"></iw-tab-sasx-xml>


</iw-tab>
