import {FormCollection} from '@app/sam-base/core/form-handler/models';
import {BOOKING_FILE_LIST_FORM} from '@modules/sam-main/salary/forms/booking-files.form';
import {CONTACT_NCS_FORM} from '@modules/sam-main/salary/forms/contact-ncs-form';
import {ENCAISSEMENT_AUTOMATIC_FORM} from '@modules/sam-main/salary/forms/encaissement-automatic.form';
import {FACTURE_COMPTA_FORM} from '@modules/sam-main/salary/forms/facture-compta';
import {GECLICAT_FORM} from '@modules/sam-main/salary/forms/geclicat';
import {ORDRE_PAIEMENT_FORM} from '@modules/sam-main/salary/forms/gedta.form';
import {GEPAR_DEFAULT_FORM} from '@modules/sam-main/salary/forms/gepar';
import {GEPER_DEFAULT_FORM} from '@modules/sam-main/salary/forms/geper';
import {GERPL_FORM} from '@modules/sam-main/salary/forms/gerpl.form';
import {SALARY_COMPTA_FORM} from '@modules/sam-main/salary/forms/salary-compta';
import {SAPAIMODE_FORM} from '@modules/sam-main/salary/forms/sapaimode';
import {ASSURANCES} from './forms/assurances';
import {BAREME} from './forms/bareme';
import {CANTON_BAREME} from './forms/bareme-is';
import {CANTON_IS} from './forms/canton-is';
import {DECOMPTE_GS_INFO_FORM} from './forms/decompte-gs-info';
import {DED_CREATION} from './forms/ded-creation';

import {ELEMSAL_DEFAULT_FORM} from './forms/elemSal';
import {EMPLOYEE_DEDUCTION_INFO_FORM} from './forms/employee-deduction-info';
import {FACTURE_NC_FORM} from './forms/facture-ns.form';
import {FACTURE_NOTE_CREDIT_NEW_FORM} from './forms/facture_note_credit_new';
import {FICHE_SALAIRE_DEFAULT_FORM} from './forms/fiche-salaire';
import {
    FICHEPAIEMENT_ATTRIBUITION_FORM,
    FICHEPAIEMENT_ATTRIBUITION_MANY_CLI_FORM,
    FICHEPAIEMENT_DEFAULT_FORM
} from './forms/fichePaiement';
import {GECLI_DEFAULT_FORM} from './forms/gecli';
import {GECLIATT_EDIT, GECLIATT_NEW} from './forms/gecliatt';
import {GECLISUIVI_FORM} from './forms/geclisuivi';
import {GEFACELE_EDIT, GEFACELE_NEW} from './forms/gefacele';
import {GEPAICOND} from './forms/gepaicond';
import {ENCAISSEMENT_DEFAULT_FORM} from './forms/gepaihdr';
import {GEPAIMODE} from './forms/gepaimode';
import {GESAEXE_DEFAULT_FORM} from './forms/gesaexe';
import {PAIEMENT_CALCULATEBTN} from './forms/paiement-calculatebtn.form';
import {PAYER_PROV_FORM} from './forms/payer-prov';
import {PERIODEIS_DEFAULT_FORM} from './forms/periodeIS';
import {SACONJ_DEFAULT_FORM} from './forms/saconj';
import {SADEDDA_DEFAULT_FORM} from './forms/sadedda';
import {SADEDTX_DEFAULT_FORM} from './forms/sadedtx';
import {SADEDTXAGE_FORM} from './forms/sadedtxage';
import {SADS_DEFAULT_FORM} from './forms/sads';
import {SAEIMPTX_DEFAULT_FORM} from './forms/saeimptx';
import {SAEIO_DEFAULT_FORM} from './forms/saeio';
import {SAEMC_DEFAULT_FORM} from './forms/saemc';
import {SAEMI_AVS_DEFAULT_FORM, SAEMI_IS_DEFAULT_FORM, SAEMI_LPP_DEFAULT_FORM} from './forms/saemi';
import {SAEMP_DEFAULT_FORM} from './forms/saemp';
import {SAEMPOP_DEFAULT_FORM} from './forms/saempop';
import {SAENF_DEFAULT_FORM} from './forms/saenf';
import {SAGEN_DEFAULT_FORM} from './forms/sagen';
import {SAGS_DEFAULT_FORM, SAGS_VERSION_FORM} from './forms/sags';
import {SANCSMOT_DEFAULT_FORM} from './forms/sancsmot';
import {SAPAR_DEFAULT_FORM} from './forms/sapar';
import {SAPARDED_DEFAULT_FORM} from './forms/saparded';
import {SAPARNCS_DEFAULT_FORM} from './forms/saparncs';
import {SAPARNCSMOT_DEFAULT_FORM} from './forms/saparncsmot';
import {SAREP_DEFAULT_FORM} from './forms/sarep';
import {SalaryForm} from './salary.forms';

export const SALARY_FORMS: FormCollection<SalaryForm> = {
    [SalaryForm.GenreSalaire]: SAGS_DEFAULT_FORM,
    [SalaryForm.GenreSalaireVersion]: SAGS_VERSION_FORM,
    [SalaryForm.ParametresGeneraux]: SAPAR_DEFAULT_FORM,
    [SalaryForm.ParametresGenerauxFacturation]: GEPAR_DEFAULT_FORM,
    [SalaryForm.FactureCompta]: FACTURE_COMPTA_FORM,
    [SalaryForm.SalaryCompta]: SALARY_COMPTA_FORM,
    [SalaryForm.FicheSalaire]: FICHE_SALAIRE_DEFAULT_FORM,
    [SalaryForm.Employee]: SAEMP_DEFAULT_FORM,
    [SalaryForm.Encaissement]: ENCAISSEMENT_DEFAULT_FORM,
    [SalaryForm.SalaryDeductions]: SAPARDED_DEFAULT_FORM,
    [SalaryForm.EmployeConjoint]: SACONJ_DEFAULT_FORM,
    [SalaryForm.EmployeEnf]: SAENF_DEFAULT_FORM,
    [SalaryForm.SadedTaux]: SADEDTX_DEFAULT_FORM,
    [SalaryForm.Sadedda]: SADEDDA_DEFAULT_FORM,
    [SalaryForm.Saeio]: SAEIO_DEFAULT_FORM,
    [SalaryForm.DefinitionSalaire]: SADS_DEFAULT_FORM,
    [SalaryForm.SelectionExercise]: GESAEXE_DEFAULT_FORM,
    [SalaryForm.Saeimptx]: SAEIMPTX_DEFAULT_FORM,
    [SalaryForm.Sancsmot]: SANCSMOT_DEFAULT_FORM,
    [SalaryForm.ParametresSD4]: SAEMC_DEFAULT_FORM,
    [SalaryForm.Sapaimode]: SAPAIMODE_FORM,
    [SalaryForm.Periode]: SAGEN_DEFAULT_FORM,
    [SalaryForm.FichePaiement]: FICHEPAIEMENT_DEFAULT_FORM,
    [SalaryForm.Saepview]: FICHEPAIEMENT_DEFAULT_FORM,
    [SalaryForm.EmployeeDeductionInfo]: EMPLOYEE_DEDUCTION_INFO_FORM,
    [SalaryForm.DecompteGS]: DECOMPTE_GS_INFO_FORM,
    [SalaryForm.Repartition]: SAREP_DEFAULT_FORM,
    [SalaryForm.Saempop]: SAEMPOP_DEFAULT_FORM,
    [SalaryForm.JourIS]: PERIODEIS_DEFAULT_FORM,
    [SalaryForm.ElementSalaire]: ELEMSAL_DEFAULT_FORM,
    [SalaryForm.ContactNew]: GECLIATT_NEW,
    [SalaryForm.ContactEdit]: GECLIATT_EDIT,
    [SalaryForm.Client]: GECLI_DEFAULT_FORM,
    [SalaryForm.Assurances]: ASSURANCES,
    [SalaryForm.PayerProv]: PAYER_PROV_FORM,
    [SalaryForm.Gepaimode]: GEPAIMODE,
    [SalaryForm.Geper]: GEPER_DEFAULT_FORM,
    [SalaryForm.BookingFileList]: BOOKING_FILE_LIST_FORM,
    [SalaryForm.Gepaicond]: GEPAICOND,
    [SalaryForm.FactureNC]: FACTURE_NC_FORM,
    [SalaryForm.Gefaccliview]: FACTURE_NC_FORM,
    [SalaryForm.EncaissementManual]: ENCAISSEMENT_AUTOMATIC_FORM,
    [SalaryForm.Geclisuivi]: GECLISUIVI_FORM,
    [SalaryForm.Geclicat]: GECLICAT_FORM,
    [SalaryForm.ContactNCS]: CONTACT_NCS_FORM,
    [SalaryForm.Sadedtxage]: SADEDTXAGE_FORM,
    [SalaryForm.FactureNoteCreditNew]: FACTURE_NOTE_CREDIT_NEW_FORM,
    [SalaryForm.FaceleNew]: GEFACELE_NEW,
    [SalaryForm.FaceleEdit]: GEFACELE_EDIT,
    [SalaryForm.DedCreation]: DED_CREATION,
    [SalaryForm.CantonIS]: CANTON_IS,
    [SalaryForm.ParametresNcs]: SAPARNCS_DEFAULT_FORM,
    [SalaryForm.Saparncsmot]: SAPARNCSMOT_DEFAULT_FORM,
    [SalaryForm.SaemiIS]: SAEMI_IS_DEFAULT_FORM,
    [SalaryForm.SaemiAVS]: SAEMI_AVS_DEFAULT_FORM,
    [SalaryForm.SaemiLPP]: SAEMI_LPP_DEFAULT_FORM,
    [SalaryForm.PaiementCalculatebtn]: PAIEMENT_CALCULATEBTN,
    [SalaryForm.CantonBareme]: CANTON_BAREME,
    [SalaryForm.Bareme]: BAREME,
    [SalaryForm.PaiementAttribuition]: FICHEPAIEMENT_ATTRIBUITION_FORM,
    [SalaryForm.PaiementAttribuitionManyCli]: FICHEPAIEMENT_ATTRIBUITION_MANY_CLI_FORM,
    [SalaryForm.OrdrePaiement]: ORDRE_PAIEMENT_FORM,
    [SalaryForm.GerplView]: GERPL_FORM
};
