import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {EmpsituComponent} from '@modules/sam-main/placement/components/empsitu/empsitu.component';
import {EmpSitu} from '@modules/sam-main/placement/models/empsitu.model';

export const EMPSITU_DEFAULT_FORM: FormDescription<EmpSitu> = {
    form: EmpsituComponent,
    state: {
        ...buildDefaultState(EmpSitu),
        diagTitle: 'title_empsitu',
        isNew: true,
        destroyOnSave: true,
        editMode: 'read',
    }
};
