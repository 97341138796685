import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Ppper} from '@app/sam-base/models/placement';
import {environment} from '@root/environments/environment';
import {CalculatePeriodResponse} from '@sam-base/models/placement/calculate-period-response';
import {Observable} from 'rxjs';

@Injectable()
export class PeriodeService {

    protected readonly _baseUrl = environment.backendURL + 'period/';

    constructor(private readonly _http: HttpClient) {
    }

    public getActivePeriod() {
        return this._http
            .get<Ppper>(this._baseUrl + 'active');
    }

    public create() {
        return this._http
            .post<Ppper>(this._baseUrl + 'create', {})
    }

    public activate(periode: string) {
        return this._http
            .post<Ppper>(this._baseUrl + `activate/${periode}`, {})
    }

    public open(periode: string) {
        return this._http
            .post<Ppper>(this._baseUrl + `open/${periode}`, {})
    }

    public close(periode: string) {
        return this._http
            .post<Ppper>(this._baseUrl + `close/${periode}`, {})
    }

    public calculate(periode: string) {
        return this._http
            .post<CalculatePeriodResponse>(this._baseUrl + `calculate/${periode}`, {})
    }

    public getOpenPeriodes(): Observable<Ppper[]> {
        return this._http.get<Ppper[]>(this._baseUrl + `openppperlist`);
    }
}
