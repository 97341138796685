import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@root/environments/environment';
import {map} from 'rxjs/operators';

@Injectable()
export class PrintRequestService {

    protected readonly _baseUrl: string;

    constructor(private _http: HttpClient) {
        this._baseUrl = environment.backendURL;
    }


    public download(facIds: number[]) {
        return this._http
            .post(environment.backendURL + 'print-request/download', facIds, {
                observe: 'response',
                responseType: 'blob'
            })
            .pipe(map((res: HttpResponse<Blob>) => {
                let blob = new Blob([res.body!], {type: res.headers.get('Content-Type') || ''});
                let fileUrl = URL.createObjectURL(blob);

                let link = document.createElement('a');
                link.href = fileUrl;

                let contentDisposition = res.headers.get('Content-Disposition');
                let filename = contentDisposition?.slice(contentDisposition?.indexOf('=') + 1);
                link.download = filename ?? '';

                link.click();
                URL.revokeObjectURL(link.href);
            }));
    }


}