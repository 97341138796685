<form [formGroup]="formGroup"
      class="row">
    <!-- 1st Column -->

    <div class="row col-xs-6 space-form separator-right">
        <div class="form-label col-xs-12 padding-top-10">
            <b>{{ 'employe' | translate }}</b>
        </div>

        <!-- Employe -->

        <iw-textfield #txtLieunaiss
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'neA' | translate"
                      class="iw-input col-xs-10 padding-top-10"
                      formControlName="lieunaiss"></iw-textfield>

        <iw-pays-dropdown #cboNation
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'nationalite' | translate"
                          class="iw-input col-xs-10 padding-top-5"
                          formControlName="nation"
                          id="cbo-nation"></iw-pays-dropdown>

        <iw-textfield #txtOrigine
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'origine' | translate"
                      class="iw-input col-xs-10 padding-top-5"
                      formControlName="origine"></iw-textfield>

        <iw-lookups-dropdown #cboEtatCivil
                             [isDisabled]="isReadonly"
                             [labelAlign]="'left'"
                             [label]="'etat_civil_column' | translate"
                             [lkname]="'ETATCIVIL'"
                             class="iw-input col-xs-10 padding-top-5"
                             formControlName="etatcivil"
                             id="cbo-etatcivil"></iw-lookups-dropdown>
        <div class="col-xs-2"></div>

        <iw-date-picker #txtDatetatCiv
                        [isDisabled]="isReadonly"
                        [labelAlign]="'left'"
                        [label]="'dateEtatCivil' | translate"
                        class="iw-input col-xs-8 padding-top-5"
                        formControlName="datetatciv"></iw-date-picker>

        <iw-textfield #txtNomjf
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'nomDeJeuneFille' | translate"
                      class="iw-input col-xs-10 padding-top-5"
                      formControlName="nomjf"></iw-textfield>

        <div class="row col-xs-12 space-form separator-top"></div>
        <!-- Enfants -->

        <div class="form-label col-xs-12 padding-top-10">
            <b>{{ 'enfants' | translate }}</b>
        </div>

        <iw-button #btnEnf
                   (press)="onBtnEnfentsClick()"
                   [isDisabled]="isReadonly"
                   [text]="'enfants' | translate"
                   class="iw-button col-xs-3 padding-top-25"
                   style="margin-bottom: 4px"></iw-button>

        <iw-textfield #txtEnf_nb
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'nb' | translate"
                      class="iw-input col-xs-3 text-align-right"
                      formControlName="enfnb"></iw-textfield>

        <iw-textfield #txtEnfnbcharg
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'aCharge' | translate"
                      class="iw-input col-xs-3 text-align-right"
                      formControlName="enfnbcharg"></iw-textfield>

        <iw-textfield #txtEnfnballoc
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'allocations' | translate"
                      class="iw-input col-xs-3 text-align-right"
                      formControlName="enfnballoc"></iw-textfield>

        <iw-textarea #edtEnfants
                     [height]="40"
                     [isDisabled]="isReadonly"
                     class="iw-textarea col-xs-12 padding-top-10"></iw-textarea>
    </div>

    <!-- 2nd Column -->
    <div class="col-xs-6 space-form">
        <!-- Single/Divorced checkboxes -->
        <div class="form-label col-xs-12 padding-top-5">
            <b>{{ 'not_married_label' | translate }}</b>
        </div>


        <iw-checkbox #chkEnf_noconcub
                     [isDisabled]="isReadonly || isMarried"
                     [label]="'noConcubinage' | translate"
                     class="iw-input"
                     formControlName="enfMenind"></iw-checkbox>

        <iw-checkbox #chkEnf_concub
                     [isDisabled]="isReadonly || isMarried"
                     [label]="'concubinage' | translate"
                     class="iw-input"
                     formControlName="enfConcub"></iw-checkbox>

        <iw-radio-list #concubChildOption
                       [isGroupDisabled]="isReadonly || notIsConcub"
                       [radioOptions]="concubinageTypeOptions"
                       formControlName="concubType"></iw-radio-list>

        <div class="col-xs-12 separator-top"></div>
        <div class="form-label col-xs-12 padding-top-5">
            <b>{{ 'conjoint' | translate }}</b>
        </div>
        <!-- Conjoint -->

        <iw-button #btnConj
                   (press)="onBtnConjointClick()"
                   [isDisabled]="isReadonly || !isMarried"
                   [text]="'conjoint' | translate"
                   class="iw-button col-xs-4 padding-top-10"></iw-button>

        <iw-textfield #txtConj_nom
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'nom' | translate"
                      class="iw-input col-xs-10 padding-top-5"
                      formControlName="conjNom"></iw-textfield>

        <iw-textfield #txtConjprenom
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'prenom' | translate"
                      class="iw-input col-xs-10 padding-top-5"
                      formControlName="conjprenom"></iw-textfield>

        <iw-date-picker #txtConj_naiss
                        [isDisabled]="true"
                        [labelAlign]="'left'"
                        [label]="'naissance' | translate"
                        class="iw-input col-xs-8 padding-top-5"
                        formControlName="conjNaiss"></iw-date-picker>

        <iw-textfield #txtNoavs13
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'noAvs13' | translate"
                      [mask]="'000.0000.0000.00'"
                      class="iw-input col-xs-8 padding-top-5 padding-bottom-10"
                      formControlName="conjnoas13"></iw-textfield>

        <!-- READONLY as this should be driven by the PPEMP -->
        <iw-checkbox #chkConj_work
                     [isDisabled]="true"
                     [label]="'leConjointTravaille' | translate"
                     class="iw-input col-xs-7 padding-top-5"
                     formControlName="conjWork"></iw-checkbox>
        <iw-checkbox #chkConj_wkch
                     [isDisabled]="true"
                     [label]="'leConjointTravailleEnSuisse' | translate"
                     class="iw-input col-xs-7"
                     formControlName="conjWkch"></iw-checkbox>
    </div>
</form>
