import {Component, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core/store';
import {EmpSitu} from '@modules/sam-main/placement/models/empsitu.model';
import {HeaderToolbarService} from '@sam-base/core';
import {SaesService} from '@sam-base/core/services/saes.service';
import {FormKeys, IwGridColumn} from '@sam-base/models';
import {Ppper} from '@sam-base/models/placement';

type AcompteInfo = { label: string, amount: number };

@Component({
    templateUrl: './empsitu.component.html'
})
export class EmpsituComponent extends BaseStoreFormComponent<EmpSitu> implements OnInit {

    public salId: string | undefined = '';
    public acompteInfo: AcompteInfo[] = [];
    public acompteTableColumns: IwGridColumn<AcompteInfo>[] = [
        {
            name: 'libelle',
            prop: 'label'
        },
        {
            name: 'montant',
            prop: 'amount',
            type: 'mnt',
            align: 'right'
        }]

    constructor(store: IwStoreService,
                private readonly _headerService: HeaderToolbarService,
                private readonly _saesService: SaesService) {
        super(store);

    }

    public get empId(): string {
        return this.getData('empId', false) || '';
    }

    public ngOnInit() {
        this._headerService.getActivePeriod()
            .subscribe((per: Ppper) => {
                this.salId = per.salId;
                if (this.salId)
                    this.loadAcompteInfo(this.empId, this.salId);
            });
    }

    protected getFormControlNames(): FormKeys<EmpSitu> {
        return [
            'empId',
            'salId'];
    }

    private loadAcompteInfo(empId: string, salId: string) {
        this._saesService.getAcompteInfo(empId, salId).subscribe((data) => {
            this.acompteInfo = data;
        })
    }
}
