<div class="row">
    <div [formGroup]="formGroup"
         class="col-xs-12">
        <div class="row">
            <iw-textfield
                    [isDisabled]="true"
                    [labelAlign]="'top'"
                    [label]="'client' | translate"
                    class="iw-input col-xs-2 no-padding-right no-padding"
                    formControlName="cliNom"></iw-textfield>
            <iw-button #cliBtn
                       (press)="openGecli()"
                       class="iw-button col-xs-1 flex-end no-padding-left"
                       iconClass="fas fa-link"></iw-button>
            <iw-textfield
                    [isDisabled]="true"
                    [labelAlign]="'top'"
                    [label]="'norappel' | translate"
                    class="iw-input col-xs-2 no-padding"
                    formControlName="noRappel"></iw-textfield>
            <iw-textfield
                    [isDisabled]="true"
                    [labelAlign]="'top'"
                    [label]="'sendingStatus' | translate"
                    class="iw-input col-xs no-padding-left"
                    formControlName="sendingStatus"></iw-textfield>

        </div>
        <div class="row">
            <iw-date-picker
                    [isDisabled]="true"
                    [labelAlign]="'top'"
                    [label]="'dateRpl' | translate"
                    class="iw-input col-xs-offset-6 col-xs-2"
                    formControlName="dateRpl"></iw-date-picker>
            <iw-date-picker
                    [isDisabled]="true"
                    [labelAlign]="'top'"
                    [label]="'datePrint' | translate"
                    class="iw-input col-xs-2  label-name"
                    formControlName="datePrint"></iw-date-picker>
            <iw-date-picker
                    [isDisabled]="true"
                    [labelAlign]="'top'"
                    [label]="'dateMail' | translate"
                    class="iw-input col-xs-2 label-name"
                    formControlName="dateMail"></iw-date-picker>

        </div>
        <div class="row no-padding"
             style="height: 200px;">
            <iw-table #gerplListTable
                      (applyFilter)="onApplyFilter($event)"
                      (columnSort)="onSort($event)"
                      (contextMenuClick)="onContextMenu($event)"
                      (rowClicked)="onRappelClicked($event)"
                      (selectedChange)="setSelected($event)"
                      [columns]="rappelGridColumns"
                      [data]="filteredGerplList"
                      [defaultSelected]="true"
                      [isFilterEnable]="true"
                      [isSortIconEnable]="true"
                      [selectionMode]="'single'"
                      [virtualScroll]="true"
                      class="col-xs-12"></iw-table>
            <iw-context-menu #menu
                             [context]="contextMenuData"
                             [items]="contextMenuItems"
                             [popup]="true"></iw-context-menu>

        </div>
    </div>
</div>
