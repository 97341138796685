import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {ClipboardService} from '@app/sam-base/core';
import {TranslateService} from '@ngx-translate/core';
import {BusinessExceptionErrors} from '@sam-base/core/auth/interceptors/error.model';
import {SxNotificationBundle} from '@sam-base/models/admin/sasx5dec.model';

@Component({
    selector: 'iw-tab-sasx-xml',
    templateUrl: './tab-sasx-xml.component.html',
    styleUrls: ['./tab-sasx-xml.component.scss']
})
export class TabSasxXmlComponent extends BasePartialFormComponent<string> implements OnInit, OnDestroy {
    @Input()
    public notifications?: SxNotificationBundle;
    @Input()
    public errorBundle?: BusinessExceptionErrors;

    constructor(private readonly _clipboardService: ClipboardService,
                private readonly _translateService: TranslateService,
    ) {
        super();
    }

    public get formattedErrorMessage(): string {
        return `<ul>${this.errorBundle?.errors.map(e => `<li>${this._translateService.instant(e.code, e.params)}</li>`).join('')}</ul>`
    }


    private _xmlContent = '';

    public get xmlContent() {
        return this._xmlContent;
    }

    @Input()
    public set xmlContent(content: string) {
        this._xmlContent = content;
    }

    public ngOnInit() {
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
    }

    public addToClipboard(xmlContent: string) {
        this._clipboardService.addToClipboard(xmlContent);
    }

    public openInTab(xmlContent: string) {
        const blob = new Blob([xmlContent], {type: 'application/xml'});
        const url = URL.createObjectURL(blob);
        window.open(url);
    }
}
