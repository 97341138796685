import {IwContextMenuClass} from '@app/sam-base/core';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {MenuItem} from '@app/sam-base/models';
import {OperationEvents} from '@modules/sam-main/salary/events';
import {IwColumn} from '../../core/column/decorators/iw-column.decorator';

const entityContextMenu: MenuItem[] = [
    {
        label: 'delete_deduction',
        event: OperationEvents.DELETE_DEDUCTION,
        contextMenuVisibleMode: 'single'
    }];

@IwContextMenuClass(...entityContextMenu) @IwRestEntity('saempdedview')
export class Saempdedview {
    @IwRestEntityId() @IwColumn({
        name: 'ded',
        index: 0
    }) public dedCode?: string;
    public edId?: string;
    public empId?: string;
    @IwColumn({
        name: 'codeSoumis',
        index: 1,
        type: 'status',
        valueFormat: ''
    }) public soumiscodetext?: string;
    public soumiscode?: number;
    public sadedtxId?: number;
    @IwColumn({
        name: 'codeTaux',
        index: 2
    }) public txCode?: string;
    public txCode2?: string;
    public txManuel?: number;
    public cuscode1?: number;
    public cuscode2?: number;
    @IwColumn({
        name: 'employe',
        index: 3,
        type: 'number',
        align: 'right',
        decimals: 4
    }) public cottext?: number;
    @IwColumn({
        name: 'employeur',
        index: 4,
        type: 'number',
        align: 'right',
        decimals: 4
    }) public coetext?: number;
    @IwColumn({
        name: 'mode',
        index: 5
    }) public mode?: string;
    public assCode?: string;
}
