import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {SxNotification, SxNotificationBundle} from '@sam-base/models/admin/sasx5dec.model';

import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {SxProcessFlow} from '@sam-base/models/admin/sx-process-flow.enum';
import {Subject} from 'rxjs';

/**
 * Tab General of Swissdec Declaration
 */
@Component({
    selector: 'iw-tab-sasx5institution-general',
    templateUrl: './tab-sasx5institution-general.component.html',
})
export class TabSasx5institutionGeneralComponent extends BasePartialFormComponent<Sasx5institution> implements OnInit, OnDestroy {


    @Input() public isNew = false;
    public sxNotifications: SxNotification[] = [];
    private subscriptions = new Subject();

    constructor() {
        super();
    }

    public get resultJson(): string | undefined {
        return this.getFormValue('resultJson');
    }

    public get resultJsonParsed(): any {
        const json = this.getFormValue('resultJson');
        if (!!json) {
            return JSON.parse(json + '');
        }
        return '';
    }

    public get entityId() {
        const id = this.getFormValue('id');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public get expectedAvailability(): string | undefined {
        return this.getFormValue('expectedAvailability');
    }


    public get hasNotificationToShow(): boolean {
        const notifications = this.notifications;
        return this.hasErrors(notifications) || this.hasWarnings(notifications) || this.hasInfos(notifications);
    }

    public get notifications(): SxNotificationBundle | undefined {
        return this.getFormValue('notifications');
    }

    public get domain(): string {
        return this.getFormValue('domain') || '';
    }

    public get showCompletionUrl(): boolean {
        return this.getFormValue('processFlow') === SxProcessFlow.RESULT && !!this.completionUrl;
    }

    public get assNocai(): string {
        return this.getFormValue('assNocai') || '';
    }

    public get assNocli(): string {
        return this.getFormValue('assNocli') || '';
    }

    public get subtitle(): string {
        if (this.domain === 'TAX') {
            return `${this.domain} - ${this.getFormValue('cantonImp')}`;
        }
        return `${this.domain} - ${this.assNocai} - ${this.assNocli}`;
    }

    public get xmlReceived(): string | undefined {
        return this.getFormValue('xmlReceived');
    }

    public get institutionState(): string {
        return this.getFormValue('state') || '';
    }

    public get isProcessFlowDialog(): boolean {
        return this.getFormValue('processFlow') === SxProcessFlow.DIALOG_REQUESTING
            || this.getFormValue('processFlow') === SxProcessFlow.DIALOG_FILLING
            || this.getFormValue('processFlow') === SxProcessFlow.DIALOG_PROCESSING;
    }

    public get isProcessFlowResult(): boolean {
        return this.getFormValue('processFlow') === SxProcessFlow.RESULT;
    }

    public get completionUrl(): string {
        return this.getFormValue('completionUrl') || '';
    }

    public get login(): string {
        return this.getFormValue('login') || '';
    }

    public get password(): string {
        return this.getFormValue('password') || '';
    }

    public ngOnInit() {
        this.subscribeValueChange('userMessage', (notifications) => this.parseNotifications(notifications as string));
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    private hasErrors(notifications: SxNotificationBundle | undefined): boolean {
        return (notifications?.errors?.length || 0) > 0;
    }

    private hasWarnings(notifications: SxNotificationBundle | undefined): boolean {
        return (notifications?.warnings?.length || 0) > 0;
    }

    private hasInfos(notifications: SxNotificationBundle | undefined): boolean {
        return (notifications?.infos?.length || 0) > 0;
    }

    private parseNotifications(notificationsString: string) {
        if (!notificationsString) {
            return;
        }
        const notifMessagesArray = notificationsString.split(';');
        this.sxNotifications = notifMessagesArray
            .filter(msg => msg !== '')
            .map((errorMessage) => {
                const [type, level, codeString, description] = errorMessage.split('::');
                const code = +codeString;

                return {
                    type,
                    code,
                    level,
                    description
                };
            });
    }


}
