import {IwColumn, IwRestEntity, IwRestEntityId} from '@sam-base/core';

@IwRestEntity('gerpl')
export class Gerpl {
    @IwRestEntityId() @IwColumn()
    public id?: number;
    public rpsId?: string;
    public facId?: string;
    public cliId?: string;
    public cliNom?: string;
    public rplAdrId?: string;
    public rplAttn?: string;
    public cType?: string;
    public facNo?: string;
    public dateFac?: string;
    public dateEch?: string;
    public nbjRetard?: number;
    public montant?: number;
    public acompte?: number;
    public solde?: number;
    public rplFrais?: number;
    public noRappel?: number;
    public lprintit?: boolean;
    public lprinted?: boolean;
    public dateRpl?: string;
    public datePrint?: string;
    public dateMail?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateTrack?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userModif?: string;
    public userDelet?: string;
}
