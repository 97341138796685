<form [formGroup]="formGroup"
      class="row">
    <iw-textfield [isDisabled]="true"
                  [ngClass]="getEmpStatusClass()"
                  [noLabel]="true"
                  class="iw-textfield col-xs-3 no-padding-left"
                  formControlName="empId"></iw-textfield>
    <iw-textfield [isDisabled]="true"
                  [noLabel]="true"
                  [value]="employeeName"
                  class="iw-textfield col-xs-8 no-padding-right"></iw-textfield>
    <iw-button #empBtn
               (press)="openEmp()"
               *iwAccessGroups="PLACEMENT_USER_ROLES"
               [isDisabled]="false"
               class="iw-button col-xs-1 flex-end"
               iconClass="fas fa-link"></iw-button>

</form>

<iw-tab [(selectedTab)]="selectedTab"
        [disabledTabs]="disabledTabList"
        [invalidTabs]="invalidTabList"
        [tabs]="tabList">
    <iw-tab-saemp-donnees-base *ngIf="selectedTab === tabList[0]"
                               [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"
                               [sapar]="sapar"></iw-tab-saemp-donnees-base>
    <iw-tab-saemp-situation *ngIf="selectedTab === tabList[1]"
                            [formGroup]="formGroup"
                            [isReadonly]="!isWriteMode"></iw-tab-saemp-situation>
    <iw-tab-saemp-permis *ngIf="selectedTab === tabList[2]"
                         [formGroup]="formGroup"
                         [isReadonly]="!isWriteMode"></iw-tab-saemp-permis>
    <iw-tab-saemp-deductions *ngIf="selectedTab === tabList[3]"
                             [formGroup]="formGroup"
                             [isReadonly]="!isWriteMode"
                             [parentEntityId]="empId"
                             [sapar]="sapar"
                             [uuid]="uuid"></iw-tab-saemp-deductions>
    <iw-tab-saemp-impot *ngIf="selectedTab === tabList[4]"
                        [formGroup]="formGroup"
                        [isReadonly]="!isWriteMode"
                        [sapar]="sapar"></iw-tab-saemp-impot>
    <iw-tab-saemp-salaire-vacances *ngIf="selectedTab === tabList[5]"
                                   [formGroup]="formGroup"
                                   [isReadonly]="!isWriteMode"
                                   [sapar]="sapar"></iw-tab-saemp-salaire-vacances>
    <iw-tab-paiement *ngIf="selectedTab === tabList[6]"
                     [formGroup]="formGroup"
                     [isReadonly]="!isWriteMode"></iw-tab-paiement>
    <!--
    <iw-tab-saemp-indemnites *ngIf="selectedTab === tabList[7]"
                             [formGroup]="formGroup"
                             [isReadonly]="!isWriteMode"
                             [optionTableMj1]="optionTableMj1"
                             [optionTableMj2]="optionTableMj2"
                             [optionTableMj3]="optionTableMj3"
                             [optionTableMj4]="optionTableMj4"
                             [sapar]="sapar"></iw-tab-saemp-indemnites>
                             -->
    <iw-tab-saemp-profession *ngIf="selectedTab === tabList[7]"
                             [formGroup]="formGroup"
                             [isReadonly]="!isWriteMode"></iw-tab-saemp-profession>
    <iw-tab-saemp-autre *ngIf="selectedTab === tabList[8]"
                        [empId]="empId"
                        [formGroup]="formGroup"
                        [isReadonly]="!isWriteMode"
                        [sapar]="sapar"></iw-tab-saemp-autre>
    <iw-tab-saemp-ncs *ngIf="selectedTab === tabList[9]"
                      [formGroup]="formGroup"
                      [isReadonly]="!isWriteMode"
                      [sapar]="sapar"></iw-tab-saemp-ncs>
    <iw-tab-saemp-swissdec *ngIf="selectedTab === tabList[10]"
                           [formGroup]="formGroup"
                           [isReadonly]="!isWriteMode"></iw-tab-saemp-swissdec>
    <iw-tab-saemp-annee *ngIf="selectedTab === tabList[11]"
                        [formGroup]="formGroup"
                        [isReadonly]="!isWriteMode"
                        [sapar]="sapar"></iw-tab-saemp-annee>
    <iw-tab-saemp-docs *ngIf="selectedTab === tabList[12]"
                       [entityId]="empId"
                       [isReadonly]="!isWriteMode"></iw-tab-saemp-docs>
</iw-tab>
