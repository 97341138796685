import {HttpClient} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {AdminAction} from '@modules/sam-main/admin/components/ultimate-admin/admin-action.model';
import {environment} from '@root/environments/environment';
import {Observable} from 'rxjs';


@Injectable({providedIn: 'root'})
export class UltimateAdminService {


    constructor(private readonly _http: HttpClient, private zone: NgZone) {
    }

    public indexSingleView(tenant: string, subTenant: string, index: string) {
        return this._http
            .get(environment.backendURL + `admin/index?index=${index}&tenant=${tenant}&subTenant=${subTenant}`);
    }

    public indexAll(tenant: string, subTenant: string) {
        return this._http
            .get(environment.backendURL + `admin/indexAll?&tenant=${tenant}&subTenant=${subTenant}`);
    }

    public profilePictureMigrationV1(tenant: string, subTenant: string) {
        return this._http
            .get(environment.backendURL + `admin/profile-pictures/migration-v1?&tenant=${tenant}&subTenant=${subTenant}`);
    }

    public dataMigrationToUltimate(tenant: string, subTenant: string) {
        return this._http
            .get(environment.backendURL + `admin/data/migration-to-ultimate?&tenant=${tenant}&subTenant=${subTenant}`);
    }

    public streamlog(): Observable<any> {
        return this.getServerSentEvent(environment.backendURL + `admin/stream-logs`);
    }

    public availableView() {
        return this._http
            .get<string[]>(environment.backendURL + `admin/views/available`);
    }

    public applicationInstances() {
        return this._http
            .get<any>(environment.backendURL + `admin/application-instances`);
    }

    public resetSwissdecQualityToolSalId(salId: string) {
        return this._http
            .get<any>(environment.backendURL + `/admin/swissdec/quality-tools/reset/${salId}`);
    }

    public listActions() {
        return this._http
            .get<AdminAction[]>(environment.backendURL + `admin/actions`);
    }

    public executeAction(action: AdminAction, tenant: string, subTenant: string) {
        return this._http
            .post<void>(environment.backendURL + `admin/actions/${action}/execute`, {}, {
                params: {
                    tenant,
                    subTenant
                }
            });
    }

    private getServerSentEvent(url: string): Observable<string> {
        return new Observable(observer => {
            const eventSource = new EventSource(url);

            eventSource.onopen = event => {
                console.log('Connection to SSE opened', event);
            };

            eventSource.onmessage = event => {
                this.zone.run(() => {
                    observer.next(event.data);
                });
            };

            eventSource.onerror = error => {
                console.log('EventSource error:', error);
                this.zone.run(() => {
                    observer.error('EventSource error: ' + error);
                    eventSource.close();
                });
            };
            // Cleanup function when the observable is unsubscribed
            return () => {
                eventSource.close();
            };
        });
    }


}
