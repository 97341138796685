import {FormCollection} from '@app/sam-base/core/form-handler/models';
import {AGENCE_DEFAULT_FORM} from '@modules/sam-main/placement/forms/agence';
import {EMPSITU_DEFAULT_FORM} from '@modules/sam-main/placement/forms/empsitu';
import {LOOKUPS_FORM} from '@modules/sam-main/placement/forms/lookups';
import {USER_TEAMS_CONFIG} from '@modules/sam-main/placement/forms/user-teams';
import {AGETIME_FORM} from '@modules/sam-main/salary/forms/agetime';
import {AUTO_ACOMPTES_FORM} from './forms/autoacomptes';

import {INVOICES_DEFAULT_FORM} from './forms/facturation';
import {GENRE_TRAVAIL} from './forms/genreTravail';
import {GESTION_MODELES} from './forms/gestModels';
import {INTERIJOB_CONFIG} from './forms/interijob-config';
import {MISHEUSPC_EDIT_FORM, MISHEUSPC_NEW_FORM} from './forms/misheuspc';
import {MISIND_CREATE_FORM, MISIND_UPDATE_FORM} from './forms/misind';
import {MISNEWDEF_DEFAULT_FORM} from './forms/misnewdef';
import {PAYMENT_FORM} from './forms/payacomptes';
import {PPACATTEST_CREATE_FORM, PPACATTEST_DEFAULT_FORM} from './forms/ppacattest';
import {PPACO_DEFAULT_FORM} from './forms/ppaco';
import {PPAGI_CREATE_FORM, PPAGI_DEFAULT_FORM} from './forms/ppagi';
import {PPCCT_DEFAULT_FORM} from './forms/ppcct';
import {CDFNEW_DEFAULT_FORM, PPCDEFIXE_DEFAULT_FORM} from './forms/ppcdefixe';
import {CDENEW_DEFAULT_FORM, PPCDETEMPO_DEFAULT_FORM} from './forms/ppcdetempo';
import {PPCLI_DEFAULT_FORM} from './forms/ppcli';
import {PPCLIATT_BY_ID, PPCLIATT_DEFAULT_FORM} from './forms/ppcliatt';
import {PPCLICHA_DEFAULT_FORM} from './forms/ppclichagrd';
import {PPCLISTATS_DEFAULT_FORM} from './forms/ppclistats';
import {PPSUIVI_EDIT, PPSUIVI_NEW} from './forms/ppclisui';
import {PPCON_DEFAULT_FORM} from './forms/ppcon';
import {PPDOC_TYPE} from './forms/ppdocType';
import {PPEMP_DEFAULT_FORM} from './forms/ppemp';
import {PPEMP_CANDIDATE_CREATE_FORM, PPEMP_CREATE_DEFAULT_FORM} from './forms/ppempcreate';
import {PPEMPENF_DEFAULT_FORM} from './forms/ppempenf';
import {PPGRH_DEFAULT_FORM} from './forms/ppgrh';
import {PPGRI_DEFAULT_FORM} from './forms/ppgri';
import {PPHEUSAID2DEF_DEFAULT_FORM} from './forms/ppheusaid2def';
import {PPHEUSAIS_DEFAULT_FORM} from './forms/ppheusais';
import {PPIND_DEFAULT_FORM} from './forms/ppind';
import {PPINDEMNITE_DEFAULT_FORM} from './forms/ppindemnite';
import {PPINDSAID_CREATE_FORM, PPINDSAID_UPDATE_FORM} from './forms/ppindsaid';
import {PPJF_DEFAULT_FORM} from './forms/ppjf';
import {PPJF_CONFIG_DEFAULT_FORM} from './forms/ppjfconfig';
import {PPKW_DEFAULT_FORM} from './forms/ppkw';
import {PPMIS_DEFAULT_FORM} from './forms/ppmis';
import {PPMISMDL_DEFAULT_FORM} from './forms/ppmismdl';
import {INVOICES_PARAMS_DEFAULT_FORM} from './forms/ppofa';
import {SALARY_PARAMS_DEFAULT_FORM} from './forms/ppoptsal';
import {TEXTES_PARAMETRABLES_DEFAULT_FORM} from './forms/ppopttxt';
import {PPPAR_DEFAULT_FORM} from './forms/pppar';
import {PERIOD_DEFAULT_FORM} from './forms/ppper';
import {PPPLF_DEFAULT_FORM, PPPLF_NEW_FORM} from './forms/ppplf';
import {PPQUA_DEFAULT_FORM} from './forms/ppqua';
import {PPRAP_DEFAULT_FORM} from './forms/pprap';
import {RAPPORTS_DEFAULT_FORM} from './forms/ppraps';
import {PPSECTEUR_DEFAULT_FORM} from './forms/ppsecteur';
import {SALARY_DEFAULT_FORM} from './forms/salary';
import {SCHEDULE_EVENT_DEFAULT_FORM} from './forms/schedule-event';
import {USER_ROLES_CONFIG} from './forms/user-roles';
import {PlacementForm} from './placement.forms';

export const PLACEMENT_FORMS: FormCollection<PlacementForm> = {
    [PlacementForm.Client]: PPCLI_DEFAULT_FORM,
    [PlacementForm.EmployeEnf]: PPEMPENF_DEFAULT_FORM,
    [PlacementForm.ContactNew]: PPCLIATT_DEFAULT_FORM,
    [PlacementForm.ContactEdit]: PPCLIATT_BY_ID,
    [PlacementForm.Employee]: PPEMP_DEFAULT_FORM,
    [PlacementForm.CommandesTemporaires]: PPCDETEMPO_DEFAULT_FORM,
    [PlacementForm.CommandesView]: PPCDETEMPO_DEFAULT_FORM,
    [PlacementForm.PeriodManagement]: PERIOD_DEFAULT_FORM,
    [PlacementForm.SuiviNew]: PPSUIVI_NEW,
    [PlacementForm.SuiviEdit]: PPSUIVI_EDIT,
    [PlacementForm.AcompteReadOnly]: PPACO_DEFAULT_FORM,
    [PlacementForm.IndemniteReadonly]: PPIND_DEFAULT_FORM,
    [PlacementForm.Missions]: PPMIS_DEFAULT_FORM,
    [PlacementForm.Rapport]: PPRAP_DEFAULT_FORM,
    [PlacementForm.Ppmismdl]: PPMISMDL_DEFAULT_FORM,
    [PlacementForm.Misnewdef]: MISNEWDEF_DEFAULT_FORM,
    [PlacementForm.Misheuspc]: MISHEUSPC_EDIT_FORM,
    [PlacementForm.Misheuspcnew]: MISHEUSPC_NEW_FORM,
    [PlacementForm.Ppheusaid2def]: PPHEUSAID2DEF_DEFAULT_FORM, //[PlacementForm.IndemniteUpdate]: MISIND_UPDATE_FORM,
    [PlacementForm.Rapports]: RAPPORTS_DEFAULT_FORM,
    [PlacementForm.TextesParametrables]: TEXTES_PARAMETRABLES_DEFAULT_FORM,
    [PlacementForm.MisIndemniteCreate]: MISIND_CREATE_FORM,
    [PlacementForm.MisIndemniteUpdate]: MISIND_UPDATE_FORM,
    [PlacementForm.IndemniteCreate]: PPINDSAID_CREATE_FORM,
    [PlacementForm.IndemniteUpdate]: PPINDSAID_UPDATE_FORM,
    [PlacementForm.ParametresGeneraux]: PPPAR_DEFAULT_FORM,
    [PlacementForm.GenresIndemnites]: PPGRI_DEFAULT_FORM,
    [PlacementForm.Ppheusais]: PPHEUSAIS_DEFAULT_FORM,
    [PlacementForm.CdeNew]: CDENEW_DEFAULT_FORM,
    [PlacementForm.CdfNew]: CDFNEW_DEFAULT_FORM,
    [PlacementForm.Ppindemnite]: PPINDEMNITE_DEFAULT_FORM,
    [PlacementForm.Conseillers]: PPCON_DEFAULT_FORM,
    [PlacementForm.Ppjfcalc]: PPJF_DEFAULT_FORM,
    [PlacementForm.CalendarJoursFeries]: PPJF_CONFIG_DEFAULT_FORM,
    [PlacementForm.ProfessionsConfig]: PPQUA_DEFAULT_FORM,
    [PlacementForm.KeywordConfig]: PPKW_DEFAULT_FORM,
    [PlacementForm.PpgrhConfig]: PPGRH_DEFAULT_FORM,
    [PlacementForm.AgenceConfig]: AGENCE_DEFAULT_FORM,
    [PlacementForm.Agetime]: AGETIME_FORM,
    [PlacementForm.PpsecteurConfig]: PPSECTEUR_DEFAULT_FORM,
    [PlacementForm.PpcctConfig]: PPCCT_DEFAULT_FORM,
    [PlacementForm.Ppdoc_Type]: PPDOC_TYPE,
    [PlacementForm.InvoicesPreparation]: INVOICES_DEFAULT_FORM,
    [PlacementForm.Payments]: PAYMENT_FORM,
    [PlacementForm.AutoAcomptes]: AUTO_ACOMPTES_FORM,
    [PlacementForm.InvoicesParameters]: INVOICES_PARAMS_DEFAULT_FORM,
    [PlacementForm.ScheduleEvent]: SCHEDULE_EVENT_DEFAULT_FORM,
    [PlacementForm.SalaryParameters]: SALARY_PARAMS_DEFAULT_FORM,
    [PlacementForm.SalaryPreparation]: SALARY_DEFAULT_FORM,
    [PlacementForm.Aginter]: PPAGI_DEFAULT_FORM,
    [PlacementForm.Ppagiview]: PPAGI_DEFAULT_FORM,
    [PlacementForm.AgiCreate]: PPAGI_CREATE_FORM,
    [PlacementForm.GestionModeles]: GESTION_MODELES,
    [PlacementForm.GenreTravail]: GENRE_TRAVAIL,
    [PlacementForm.Ppclistats]: PPCLISTATS_DEFAULT_FORM,
    [PlacementForm.Empsitu]: EMPSITU_DEFAULT_FORM,
    [PlacementForm.Ppclicha]: PPCLICHA_DEFAULT_FORM,
    [PlacementForm.Ppplf]: PPPLF_DEFAULT_FORM,
    [PlacementForm.Interijob]: INTERIJOB_CONFIG,
    [PlacementForm.PpplfNew]: PPPLF_NEW_FORM,
    [PlacementForm.UserRoles]: USER_ROLES_CONFIG,
    [PlacementForm.UserTeams]: USER_TEAMS_CONFIG,
    [PlacementForm.Ppacattest]: PPACATTEST_DEFAULT_FORM,
    [PlacementForm.PpacattestView]: PPACATTEST_DEFAULT_FORM,
    [PlacementForm.PpempCreate]: PPEMP_CREATE_DEFAULT_FORM,
    [PlacementForm.PpempCandidateCreate]: PPEMP_CANDIDATE_CREATE_FORM,
    [PlacementForm.PpacattestCreate]: PPACATTEST_CREATE_FORM,
    [PlacementForm.CommandesFixes]: PPCDEFIXE_DEFAULT_FORM,
    [PlacementForm.CommandeFixe]: PPCDEFIXE_DEFAULT_FORM,
    [PlacementForm.Lookups]: LOOKUPS_FORM
};
