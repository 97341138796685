<form [formGroup]="formGroup"
      autocomplete="no"
      class="row spaceForm">
    <div class="col-xs-2 buttons-right col-xs-offset-10">
        <iw-button #btnAdd
                   (press)="addElem()"
                   [isDisabled]="!canAdd"
                   [tooltip]="'factures_new_element' | translate"
                   iconClass="fas fa-file"></iw-button>

        <iw-button #btnMod
                   (press)="editElem()"
                   [isDisabled]="!canDelete"
                   [tooltip]="'factures_edit_element' | translate"
                   class="padding-left-5"
                   iconClass="fas fa-pencil-alt"></iw-button>

        <iw-button #btnDel
                   (press)="deleteElem()"
                   [isDisabled]="!canDelete"
                   [tooltip]="'factures_delete_element' | translate"
                   class="padding-left-5"
                   iconClass="fas fa-trash"></iw-button>
    </div>
    <div class="col-xs-12 separator-top no-padding"></div>
    <div *ngIf="facType === 'PT'"
         class="col-xs-12"
         style="height: 500px;">
        <iw-table (selectedChange)="getSelectedRow($event)"
                  [columns]="manualInvColumns"
                  [data]="faceleList"
                  [defaultSelected]="true"
                  [isDisabled]="false"
                  [isFilterEnable]="false"
                  [isGridColumnMenuEnable]="false"
                  [isSortIconEnable]="true"
                  [selectionMode]="'single'"
                  class="iw-grid col-xs-12"
                  style="height: 400px"></iw-table>
    </div>

    <div *ngIf="facType === 'PF'"
         class="row col-xs-12">
        <div class="row col-xs-6">
            <iw-textarea #edttexte
                         [height]="122"
                         [isDisabled]="isReadonly"
                         [label]="'description' | translate"
                         [value]="facele?.texte"
                         class="iw-textarea col-xs-12"></iw-textarea>
        </div>
        <div class=" row col-xs-6">
            <iw-textfield [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'total' | translate"
                          [size]="'small'"
                          [value]="facele?.montant"
                          class="iw-input col-xs-10 padding-top-5 text-align-right"
                          type="number"></iw-textfield>

            <iw-secteur-dropdown #cboSalMode
                                 [isDisabled]="true"
                                 [label]="'sec_id' | translate"
                                 [value]="facele?.secId"
                                 class="col-xs-10 padding-bottom-5"
                                 labelAlign="top"></iw-secteur-dropdown>

            <iw-conseiller-ppcon-dropdown #cboCon_id
                                          [isDisabled]="true"
                                          [labelAlign]="'top'"
                                          [label]="'conseiller' | translate"
                                          [value]="facele?.conId"
                                          class="iw-combobox col-xs-10"></iw-conseiller-ppcon-dropdown>

            <iw-conseiller-ppcon-dropdown #cboCon_id
                                          [isDisabled]="true"
                                          [labelAlign]="'top'"
                                          [label]="'conseiller2' | translate"
                                          [value]="facele?.conId2"
                                          class="iw-combobox col-xs-7"></iw-conseiller-ppcon-dropdown>

            <iw-textfield [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'%' | translate"
                          [size]="'small'"
                          [value]="facele?.conId2Pc"
                          class="iw-input col-xs-3 padding-top-5"></iw-textfield>

            <iw-textfield [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'compte' | translate"
                          [size]="'small'"
                          [value]="facele?.compte"
                          class="iw-input col-xs-10 padding-top-5"></iw-textfield>
        </div>
    </div>

    <div *ngIf="facType !== 'PT' && facType !== 'PF'"
         class="col-xs-12"
         style="height: 500px;">
        <iw-table (selectedChange)="getSelectedRow($event)"
                  [columns]="manualInvColumns"
                  [data]="faceleList"
                  [defaultSelected]="true"
                  [isDisabled]="false"
                  [isFilterEnable]="false"
                  [isGridColumnMenuEnable]="false"
                  [isSortIconEnable]="true"
                  [selectionMode]="'single'"
                  class="iw-grid col-xs-12"
                  style="height: 400px"></iw-table>

    </div>

</form>
