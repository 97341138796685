<form [formGroup]="formGroup"
      class="row">
    <iw-textfield #txtLieuSoc
                  [isDisabled]="isReadonly"
                  [labelAlign]="'top'"
                  [label]="'lieu_de_salaire' | translate"
                  class="iw-input col-xs-6 padding-top-15"
                  formControlName="lieuSoc"></iw-textfield>
    <div class="col-xs-6 padding-top-15"></div>

    <iw-canton-dropdown #cboCantonSoc
                        [isDisabled]="isReadonly"
                        [labelAlign]="'top'"
                        [label]="'canton' | translate"
                        class="iw-input col-xs-6 padding-top-15"
                        formControlName="cantonSoc"></iw-canton-dropdown>
    <div class="col-xs-6 padding-top-15"></div>

    <iw-textfield #txtImpComtra
                  [isDisabled]="isReadonly"
                  [labelAlign]="'top'"
                  [label]="'communeGe' | translate"
                  class="iw-input col-xs-6 padding-top-15"
                  formControlName="impComtra"
                  id="txtImpComtra"></iw-textfield>
    <div class="col-xs-6 padding-top-15"></div>

    <iw-smtpconfig-dropdown [isDisabled]="isReadonly"
                            [label]="'smtp_config' | translate"
                            class="iw-input col-xs-6 padding-top-15"
                            formControlName="smtpConfigId"></iw-smtpconfig-dropdown>
    <div class="col-xs-6 padding-top-15"></div>

    <iw-textfield [isDisabled]="isReadonly"
                  [label]="'emailSalary' | translate"
                  class="iw-input col-xs-6 padding-top-15"
                  formControlName="emailSalary"
                  id="txtEmailSalaire"></iw-textfield>
    <div class="col-xs-6 padding-top-15"></div>

    <iw-textfield [isDisabled]="isReadonly"
                  [label]="'laaEmail' | translate"
                  class="iw-input col-xs-6 padding-top-15"
                  formControlName="laaEmail"
                  id="txtLaaEmail"></iw-textfield>
    <div class="col-xs-6 padding-top-15"></div>

    <iw-textfield [isDisabled]="isReadonly"
                  [label]="'pgmEmail' | translate"
                  class="iw-input col-xs-6 padding-top-15"
                  formControlName="pgmEmail"
                  id="txtPgmEmail"></iw-textfield>
    <div class="col-xs-6 padding-top-15"></div>

    <iw-checkbox [isDisabled]="isReadonly"
                 [label]="'activate_multi_window_feature' | translate"
                 class="col-xs-10 margin-top-20"
                 formControlName="entityMultiWindow"></iw-checkbox>
    <div class="col-xs-6 padding-top-15"></div>


</form>
