<div class="row">
    <div class="col-xs-12">
        <div class="row">
            <iw-button (press)="toggleInstitutionProcessing()"
                       [isDisabled]="isReadonly || removeInstitutionDisabled"
                       [text]="'toggle_institutions_process' | translate"
                       class="col-xs-offset-8 col-xs no-padding"></iw-button>

        </div>

        <div style="height: 350px;">
            <iw-table #institutionsGrid
                      (applyFilter)="onApplyFilter($event)"
                      (contextMenuClick)="onContextMenu($event)"
                      (rowDoubleClick)="onRowDoubleClick($event)"
                      (selectedChange)="setSelected($event)"
                      [columns]="institutionGridColumns"
                      [data]="filteredInstitutions"
                      [defaultSelected]="true"
                      [isFilterEnable]="true"
                      [isSortIconEnable]="true"
                      [selectionMode]="'checkbox'"
                      [virtualScroll]="true"
                      id="institutionsGrid"></iw-table>
            <iw-context-menu #menu
                             [context]="contextMenuData"
                             [items]="contextMenuItems"
                             [popup]="true"></iw-context-menu>

        </div>
    </div>
</div>

