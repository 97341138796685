import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {ToastService} from '@app/sam-base/core/toast';
import {Ppheusaid, Pprap, ScheduleEvent} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {Ppheusaid2Input} from '@shared/widgets/iw-components/iw-hours-grid/iw-hours-grid.model';
import {lastValueFrom, Observable} from 'rxjs';

import {Ppheusaid2Save} from '../components/ppheusaid2/ppheusaid2-save.model';
import {mapPpheusaidToPpheusaid2} from '../helpers/map-ppheusaid';
import {PpheusaidService} from './ppheusaid.service';

@Injectable()
export class ReportsService extends RestClient<Pprap | undefined> {
    public constructor(public readonly _http: HttpClient, private _translate: TranslateService,
                       private _ppheusaidService: PpheusaidService, private _toastService: ToastService) {
        super(_http);
    }

    protected _salId?: string;

    public get salId(): string {
        return this._salId || '';
    }

    public set salId(val: string) {
        this._salId = val;
    }

    /**
     * Calculates if the day is editable based on the mission schedule event
     *
     * @param datelundi first day of the report
     * @param scheduleEvent schedule event of the mission related to the report
     */
    public setActiveDays(datelundi?: string, scheduleEvent?: ScheduleEvent): boolean[] | undefined {
        const days = [];
        for (let i = 0; i < 7; i++) {
            if (!scheduleEvent?.dateEnd) {
                days.push(true);
            } else {
                days.push(IwDateHelper.isSameOrBefore(IwDateHelper.addToDate(i, 'days', datelundi), scheduleEvent.dateEnd));
            }
        }
        return days;
    }

    // eslint-disable-next-line complexity
    public getGridHoursInput(dt: Ppheusaid): Ppheusaid2Input | undefined {
        const datelundi: string | undefined = dt.rap?.datelundi;
        const gridInput: Ppheusaid2Input = {
            gridHours: [],
            heurepause: {
                exists: dt.mis?.heurepause || false,
                pauseMode: dt.mis?.hpausaimod || 0,
                pauseMontant: dt.mis?.mntPause || 0,
                pauseTx: dt.mis?.txPause || 0,
                auto: dt.rap?.hpauseauto || false
            },
            datestart: dt.scheduleEvent?.dateStart ?? '',
            datelundi: IwDateHelper.getMoment(datelundi)
        };
        gridInput.gridHours = mapPpheusaidToPpheusaid2(dt, new Date(datelundi || ''));
        gridInput.gridDays = this.setActiveDays(datelundi, dt.scheduleEvent);
        return gridInput;
    }

    public setReportChantier(rapId: string, chantier: string): Observable<void> {
        return this._http.put<void>(environment.backendURL + 'pprap/chantier/' + rapId, chantier);
    }

    public setReportSalId(rapId: string, salId: string): Observable<void> {
        return this._http.put<void>(environment.backendURL + 'pprap/' + rapId + '/' + salId, "");
    }

    /**
     * Recalculates some report parameters and updates it's dependencies
     *
     * @param reportData
     */
    public async calculateTotals(report: Pprap) {
        if (!report.rapId) {
            return;
        }
        const hoursData: Ppheusaid | undefined = await lastValueFrom(this._ppheusaidService.getDataByRapId(report.rapId));
        const hoursInput: Ppheusaid2Input | undefined = await this.getGridHoursInput(hoursData);
        if (!hoursInput) {
            return;
        }
        const reportData: Ppheusaid2Save = {
            hours: hoursInput.gridHours,
            rapport: report,
            timeformat: true
        };
        return await this.triggerCalculateTotals(reportData);
    }

    /**
     * Get rapports by empId and datelundi
     *
     * @param rapport rapport data to save
     */
    public getPprapsByEmpIdAndDateLundi(empId: string, datelundi: string): Observable<Pprap[]> {
        return this.GET<Pprap[]>(undefined, `pprap/${empId}/${datelundi}`);
    }

    /**
     * Get rapports by empId and datelundi
     *
     * @param rapport rapport data to save
     */
    public deleteRapport(rapId: string): Observable<void> {
        return this.DELETE<void>(undefined, `pprap/${rapId}`);
    }

    private async triggerCalculateTotals(reportData: Ppheusaid2Save): Promise<boolean> {
        const dataToSave: Ppheusaid2Save = {
            rapport: reportData.rapport,
            hours: reportData.hours,
            indemnites: reportData.indemnites,
            timeformat: reportData.timeformat
        };
        try {
            await lastValueFrom(this._ppheusaidService.recalculateReportTotals(dataToSave));
            return true;
        } catch (error) {
            const message = this._translate.instant('error_rapport');
            this._toastService.error(message);
            return false;
        }
    }
}
