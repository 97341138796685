import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {MessageFormRefreshService} from '@app/sam-base/components/subjects/message-service-subject.service';
import {FormHandlerService} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {ToastService} from '@app/sam-base/core/toast';
import {FormKeys, RowClickEvent} from '@app/sam-base/models';
import {IwGridColumn} from '@app/sam-base/models/components/grid-column.model';
import {AdminForm} from '@modules/sam-main/admin/admin.forms';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {SamUser} from '@sam-base/models/admin/sam-user';
import {FilterService} from '@sam-base/models/components/filtering.service';
import {Subject, takeUntil} from 'rxjs';

@Component({
    templateUrl: './sam-user-admin-table.component.html'
})
export class SamUserAdminTableComponent extends BaseStoreFormComponent<SamUser> implements OnInit, OnDestroy {
    public libelle?: string;
    public columns = this.getMismdlColumns();
    public filteredData: SamUser[] = [];
    public isLoading = false;
    public tableData: SamUser[] = [];

    private subscriptions = new Subject();
    private filterService = new FilterService<SamUser>();

    constructor(store: IwStoreService, _restService: RestApiService, private readonly _http: HttpClient,
                private _translate: TranslateService,
                private readonly refreshFormMessageService: MessageFormRefreshService,
                private _toastService: ToastService, private readonly _formHandler: FormHandlerService<AdminForm>,
                private readonly messageService: MessageFormRefreshService) {
        super(store);
    }

    public ngOnInit() {
        this.getAllUsers();
        this.messageService.getMessage()
            .pipe(takeUntil(this.subscriptions))
            .subscribe(message => {
                if (message && 'sam_user.table' === message) {
                    this.getAllUsers();
                }
            });
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }


    public openCreate() {
        this._formHandler.showFormDialog(AdminForm.SamUserAdminEdit, undefined, s => ({
            ...s,
            diagModal: true,
            diagTitle: 'title_kuser_form',
        }));
    }

    public onApplyFilter($event: IwGridColumn<SamUser>) {
        this.filterService.addFilter($event);
        this.filteredData = this.filterService.applyFilters(this.tableData);
    }

    public onRowDoubleClick(event: RowClickEvent<SamUser>) {
        this._formHandler.showFormDialog(AdminForm.SamUserAdminEdit, event, s => ({
            ...s,
            entityId: event.row.id || '',
            diagModal: true,
            diagTitle: 'title_kuser_form',
        }));
    }

    public getAllUsers() {
        return this._http.get<SamUser[]>(environment.backendURL + 'sam-user/all-gestions')
            .subscribe((data: SamUser[]) => {
                this.tableData = data;
                this.filteredData = data; // init
            });
    }

    uploadDocument(files: File[]) {
        if (files?.length && files[0].name) {
            const file: File = files[0];
            const formData: FormData = new FormData();
            formData.append('file', file);
            this._http.post(environment.backendURL + 'sam-user/import', formData).subscribe(users => {
                this._toastService.success('import_users_success');
            })
        }

    }

    protected getFormControlNames(): FormKeys<SamUser> {
        return [
            'id',
            'username',
            'email',
            'firstName',
            'lastName',
            'locale',
            'tenant'];
    }

    private getMismdlColumns(): IwGridColumn<SamUser>[] {
        return [
            {
                prop: 'username',
                name: this._translate.instant('utilisateur'),
                index: 1,
                type: 'string'
            },
            {
                prop: 'tenant',
                name: this._translate.instant('tenant'),
                index: 2,
                type: 'string'
            },
            {
                prop: 'firstName',
                name: this._translate.instant('prenom'),
                index: 3,
                type: 'string'
            },
            {
                prop: 'lastName',
                name: this._translate.instant('nom'),
                index: 4,
                type: 'string'
            },
            {
                prop: 'email',
                name: this._translate.instant('email'),
                index: 5,
                type: 'string'
            }];
    }
}
