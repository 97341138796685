<form [formGroup]="formGroup"
      class="row no-padding">
    <iw-canton-dropdown #cboCanton
                        (valueChange)="cantonChange($event)"
                        [(value)]="canton"
                        [isDisabled]="isReadonly"
                        [labelAlign]="'top'"
                        [label]="'canton' | translate"
                        class="iw-input col-xs-10"></iw-canton-dropdown>
    <iw-textfield #txtTexte
                  [(value)]="activeSapar"
                  [isDisabled]="true"
                  [label]="'' | translate"
                  class="iw-input col-xs-2 no-label"></iw-textfield>
    <iw-textfield #txtTexte
                  [(value)]="isBareme"
                  [isDisabled]="true"
                  [label]="'' | translate"
                  class="iw-input col-xs-12"></iw-textfield>
    <div class=" flex col-xs-12">
        <iw-textarea [(value)]="isBareme"
                     [height]="60"
                     [isDisabled]="true"
                     [label]="''"
                     class="iw-textarea col-xs-12"></iw-textarea>
    </div>
    <div class=" row col-xs-6">
        <iw-textfield [(value)]="isnordre"
                      [isDisabled]="true"
                      [label]="'no_dpi' | translate"
                      class="iw-input col-xs-10"></iw-textfield>
        <iw-textfield [(value)]="iscommper"
                      [isDisabled]="true"
                      [label]="'comm_perception' | translate"
                      class="iw-input col-xs-10"></iw-textfield>
        <iw-textfield [(value)]="isnbheures"
                      [isDisabled]="true"
                      [label]="'heures_mois' | translate"
                      class="iw-input col-xs-10"></iw-textfield>
    </div>
    <div class="col-xs-4 col-xs-offset-2 padding-top-20">
        <iw-button (press)="majCodes()"
                   [isDisabled]=""
                   [text]="'maj_codes' | translate"></iw-button>
        <iw-button (press)="openBareme()"
                   [isDisabled]="!isBareme.length"
                   [text]="'consulter' | translate"></iw-button>

        <iw-button #Closebutton
                   (press)="closeDialog()"
                   [text]="'fermer' | translate"
                   class="iw-button col-xs-12 flex-end"></iw-button>
    </div>
</form>