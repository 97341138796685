import {Component, Input} from '@angular/core';

@Component({
    templateUrl: './avs-institution-result-viewer.component.html',
    selector: 'avs-institution-result-viewer',
})
export class AvsInstitutionResultViewerComponent {

    constructor() {
    }

    private _json?: AvsResult;
    public get json(): AvsResult | undefined {
        return this._json;
    }

    @Input()
    public set json(v: string) {
        if (v) {
            this._json = JSON.parse(v);
        }
    }
}
