import {Component, OnInit, Type, ViewChild} from '@angular/core';
import {BaseFormComponent} from '@sam-base/base';
import {IwRestGridComponent} from '@sam-base/components';
import {IwStoreService} from '@sam-base/core';
import {RestQueryParam} from '@sam-base/core/rest-api';
import {LookupsService} from '@sam-base/core/services/lookups.service';
import * as actions from '@sam-base/core/store/actions/global-form.actions';
import {ComboboxItem, FormKeys, IwGridColumn, Lookups} from '@sam-base/models'

@Component({
    templateUrl: './lookups.component.html'
})
export class LookupsComponent extends BaseFormComponent<Lookups> implements OnInit {

    @ViewChild('lookupGrid') public lookupGrid?: IwRestGridComponent<Lookups>;
    public type: Type<Lookups> = Lookups;
    public query: RestQueryParam<Lookups, string | string>[] = [];
    public tableData: Lookups[] = [];
    public lookupOptions: ComboboxItem<string>[] = [];
    public selectedElement?: Lookups | undefined;
    public selectedLookupType = '';

    public tableColumns: IwGridColumn<Lookups>[] = [
        {
            prop: 'lkupname',
            name: 'type',
            index: 0
        },
        {
            prop: 'cvalue',
            name: 'texte',
            index: 1
        },
        {
            prop: 'cid',
            name: 'id',
            index: 2
        }];

    constructor(private _lookupsService: LookupsService, protected readonly _store: IwStoreService) {
        super();
    }

    private _isNew = false;

    public get isNew(): boolean {
        return this._isNew;
    }

    private _isEditing = false;

    public get isEditing(): boolean {
        return this._isEditing;
    }

    public get isSelected(): boolean {
        return this.selectedElement !== undefined;
    }


    public ngOnInit(): void {
        this.loadLookupsNames();
    }

    public newEntry() {
        this._isEditing = true;
        this._isNew = true;
        this.selectedElement = undefined;
        this.setFormValue('lkupname', this.selectedLookupType);
        this.setFormValue('cvalue', '');
        this.setFormValue('cvalue1', '');
        this.setFormValue('cvalue2', '');
        this.setFormValue('cvalue3', '');
        this.setFormValue('cvalue4', '');
        this.setFormValue('cid', '');

    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public selectedChanged(lookups: Lookups[]) {
        if (lookups.length > 0) {
            this.selectedElement = lookups[0];
            this.setFormValue('cvalue', lookups[0].cvalue);
            this.setFormValue('cvalue1', lookups[0].cvalue1);
            this.setFormValue('cvalue2', lookups[0].cvalue2);
            this.setFormValue('cvalue3', lookups[0].cvalue3);
            this.setFormValue('cvalue4', lookups[0].cvalue4);
            this.setFormValue('cid', lookups[0].cid);
        } else {
            this.selectedElement = undefined;
        }
    }

    public saveEntry() {
        this.setFormValue('lkupname', this.selectedLookupType);
        this._lookupsService.save(this.getFormData())
            .subscribe({
                next: () => this.loadLookupTable(this.getFormValue('lkupname'))
            });
        this._isEditing = false;
        this._isNew = false;
    }

    public cancelEditionMode() {
        this._isEditing = false;
        this._isNew = false;
    }

    public editEntry() {
        this._isEditing = true;
    }

    public deleteEntry() {
        if (this.selectedElement) {
            this._lookupsService.delete(this.selectedLookupType, this.selectedElement.cvalue)
                .subscribe({
                    next: () => this.loadLookupTable(this.selectedLookupType)
                });
        }
    }

    public loadLookupTable(event: string) {
        this.selectedElement = undefined;
        this.tableData = [];
        if (event) {
            this._lookupsService.getLookupsByName(event)
                .subscribe({
                    next: (data: Lookups[]) => this.tableData = data
                });
        }
    }


    protected getValidationType() {
        return Lookups;
    }


    protected getFormControlNames(): FormKeys<Lookups> {
        return [
            'lkupname',
            'cvalue',
            'cvalue1',
            'cvalue2',
            'cvalue3',
            'cvalue4',
            'cvalue5',
            'cid'];
    }

    private refreshGrid() {
        this._isNew = false;
        this._isEditing = false;
        this.lookupGrid?.refresh();
        this.selectedElement = undefined;
    }

    private loadLookupsNames(): void {
        this._lookupsService.getLookupsNames().subscribe({
            next: (names: string[]) => {
                this.lookupOptions = names.map(name => this.mapToComboboxItem(name));
                if (this.lookupOptions.length > 0) {
                    // Set the default selected option to the first item in the list
                    this.selectedLookupType = this.lookupOptions[0].value;
                    this.loadLookupTable(this.selectedLookupType);
                } else {
                    // Optionally handle the case where there are no options
                    this.selectedLookupType = ''; // or any appropriate default value
                }
            },
            error: (err) => {
                console.error('Error fetching lookup names', err);
            }
        });
    }

    private mapToComboboxItem(name: string): ComboboxItem<string> {
        return {
            name: name, // This will be displayed in the combobox
            value: name  // This will be the actual value associated with the item
        };
    }
}
