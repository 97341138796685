import {Component, EventEmitter, OnInit, Type} from '@angular/core';
import {ComboboxItem, ModalComponent} from '@app/sam-base/models';
import {ParametersService} from '@core/services/parameters.service';
import {BulkManagerOptions} from '@modules/sam-main/admin/components/bulk/bulk-manager.options';
import {
    ModalOperationDetailComponent
} from '@modules/sam-main/admin/components/operation/operation-detail-modal/modal-operation-detail.component';
import {
    ModalOperationDetailOptions
} from '@modules/sam-main/admin/components/operation/operation-detail-modal/operation-modal.options';
import {Operation, OperationDetail, OperationType} from '@modules/sam-main/admin/models/operation.model';
import {OperationService} from '@modules/sam-main/admin/services/operation-service';
import {SalaryPeriodeService} from '@modules/sam-main/salary/services/salary-periode.service';
import {TranslateService} from '@ngx-translate/core';
import {EsQueryStatement, getEntityMetadata, ModalService} from '@sam-base/core';
import {GeperService} from '@sam-base/core/services/geper.service';
import {busyIndicator} from '@sam-base/helpers/busy-indicator';
import {GridType} from '@sam-base/models/grid-type.model';
import {Sapar} from '@sam-base/models/salary';
import {multiply} from 'lodash';

@Component({
    templateUrl: './bulk-manager.component.html',
    styleUrls: ['./bulk-manager.component.scss']
})
export class BulkManagerComponent<T> implements ModalComponent<string, BulkManagerOptions>, OnInit {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public operations: Operation[] = [];
    public loading = false;
    /** Selected items in table */
    public selected: number[] | string[] = [];
    /** Entity to load in table */
    public entity?: Type<T>;
    public salaryOptionPeriodes: ComboboxItem<string>[] = [];
    public invoiceOptionPeriodes: ComboboxItem<string>[] = [];
    public operationType?: OperationType;
    public statements: EsQueryStatement<T>[] = [];
    public gridType: GridType = GridType.default;
    public operationDetail?: OperationDetail
    public salId?: string;
    public year?: string;
    public force: boolean = false;
    protected readonly operationTypeType? = OperationType;
    protected readonly multiply = multiply;
    private _ok?: (result: string) => void;
    private _cancel?: (result?: undefined) => void;
    private sapar?: Sapar;

    constructor(private readonly _translate: TranslateService,
                private readonly _modalService: ModalService,
                private readonly _operationService: OperationService,
                private readonly _salaryPeriodeService: SalaryPeriodeService,
                private readonly _parametersService: ParametersService,
                private readonly _gerperService: GeperService) {
    }

    public get canGoToStep2() {
        return !!this.operationType;
    }

    public get bulkOperationNotice(): string {
        if (this.operationType) {
            return this._translate.instant(`operation_component.notice.${this.operationType}`);
        }
        return ''
    }

    public get isSaempEntityOperation(): boolean {
        return [
            OperationType.CREATE_SALARY,
            OperationType.EMAIL_YEAR_SALARIES].includes(this.operationType!);
    }

    public get isSaemsEntityOperation(): boolean {
        return [
            OperationType.EMAIL_SALARY,
            OperationType.PUBLISH_SALARY,
            OperationType.CALCULATE_SALARY].includes(this.operationType!);
    }

    public get showYear(): boolean {
        return [
            OperationType.EMAIL_YEAR_SALARIES].includes(this.operationType!);
    }

    public get showSalaryPeriod(): boolean {
        return [
            OperationType.CREATE_SALARY,
            OperationType.EMAIL_SALARY,
            OperationType.PUBLISH_SALARY,
            OperationType.CALCULATE_SALARY].includes(this.operationType!);
    }

    public get showInvoicePeriod(): boolean {
        return [
            OperationType.PUBLISH_INVOICE,
            OperationType.EMAIL_INVOICE,
        ].includes(this.operationType!);
    }

    public get isInvoiceEntityOperation(): boolean {
        return [
            OperationType.PUBLISH_INVOICE,
            OperationType.EMAIL_INVOICE,
            OperationType.CREATE_REMINDER,
            OperationType.SEND_INVOICE,
        ].includes(this.operationType!);
    }

    public get isPaymentEntityOperation(): boolean {
        return [
            OperationType.VALIDATE_WAITING_PAYMENT
        ].includes(this.operationType!);
    }

    public ngOnInit(): void {
        if (this.operationType !== OperationType.EMAIL_YEAR_SALARIES) {
            this.loadSaparRelatedInfo();
        }
        this.loadInvoiceRelatedInfo();
        this._operationService.gelAll().subscribe({
            next: (operations) => {
                this.operations = operations;
            }
        })
    }

    public setData(data: BulkManagerOptions): Promise<void> {
        this.operationType = data.type;
        this.salId = data.salId;
        this.year = data.year;
        this.force = data.force || false;
        return Promise.resolve();
    }

    public registerOk(action: (result: string) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public onSelect(entities: any[]) {
        this.selected = entities.map(e => {
            const meta = getEntityMetadata(e);
            // meta.$pk
            return meta.$getPk()
        });
    }

    public confirmOperation() {
        if (!this.operationDetail) return;
        this._operationService.submitOperation(this.operationDetail)
            .pipe(busyIndicator(this))
            .subscribe({
                next: (operationDetail) => {
                    const options: ModalOperationDetailOptions = {
                        showCancel: true,
                        title: this._translate.instant('operations_modal_title'),
                        width: 1500,
                        height: 900,
                        operationId: operationDetail.operation?.id!
                    };
                    this._modalService.showModal(ModalOperationDetailComponent, options);
                    if (this._ok) {
                        this._ok('osef');
                    }
                }
            })
    }

    public createOperation(nextCallback: any) {
        if (!this.operationType) return;

        let entityIds: string[] | number[];
        switch (this.operationType) {
            case OperationType.CREATE_SALARY:
                entityIds = this.selected.map(id => id + '_' + this.salId);
                break;
            case OperationType.EMAIL_YEAR_SALARIES:
                entityIds = this.selected.map(id => id + '_' + this.year);
                break;
            default:
                entityIds = this.selected;
        }

        this._operationService.createOperation(this.operationType, entityIds)
            .pipe(busyIndicator(this))
            .subscribe({
                next: (operationDetail) => {
                    this.operationDetail = operationDetail;
                    nextCallback.emit();
                },
            })
    }

    public onBeforeHide() {
        if (this._ok) {
            this._ok('');
        }
    }

    private loadSaparRelatedInfo() {
        this._parametersService.getCurrentSapar()
            .subscribe((sapar) => {
                this.sapar = sapar;
                if (sapar.annee) {
                    this.salaryOptionPeriodes = this._salaryPeriodeService.getPeriodesForYear(sapar.annee);
                }
            });

    }

    private loadInvoiceRelatedInfo() {
        this._gerperService.list().subscribe((geperList) => {
            this.invoiceOptionPeriodes.length = 0;
            geperList.forEach(per => {
                this.invoiceOptionPeriodes.push({
                    name: per.texte!,
                    value: per.salId!
                });
            })
        })
    }
}
