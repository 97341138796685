<div class="flex flex-v flex-evenly">
    <iw-button #btnPdf
               (press)="doPrint()"
               [iconClass]="'fas fa-print'"
               class="btn-block"></iw-button>

    <iw-enum-dropdown #cboFacEleOrd
                      [(value)]="pdf"
                      [inputEnum]="pdfOptions"
                      class="iw-input col-xs-12 margin-bottom-5 margin-top-5 col-no-padding"
                      formControlName="acatype"></iw-enum-dropdown>

    <iw-button (press)="sendByMail()"
               class="btn-block  padding-top-15"
               iconClass="fas fa-envelope"></iw-button>
</div>