import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {RestEntityClient} from '@app/sam-base/core/rest-api/entity/rest-entity-client';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys} from '@app/sam-base/models';
import {Ppgri, Ppmis, Ppmisih} from '@app/sam-base/models/placement';
import {SetLoading} from '@sam-base/core/store/actions/global-form.actions';

import {SaisieEnum} from './misind-enums';

@Component({
    templateUrl: './misind.component.html'
})
export class MisindComponent extends BaseStoreFormComponent<Ppmisih> implements OnInit {
    @ViewChild('chknCoeff', {static: false}) chknCoeff: any;

    public get misId(): string | undefined {
        return this.getData('misId');
    }

    public mis?: Ppmis = {};
    public gri: string | undefined;
    public saisie = SaisieEnum;
    private _entityPpgriService: RestEntityClient<Ppgri>;
    private _entityPpmisService: RestEntityClient<Ppmis>

    constructor(store: IwStoreService, restService: RestApiService) {
        super(store);
        this._entityPpgriService = restService.getEntityClient(Ppgri);
        this._entityPpmisService = restService.getEntityClient(Ppmis);
        this.subscribeValueChange('griId', () => {
            this.gri = this.getFormValue('griId');
            if (!this.gri) {
                return;
            }
            this._entityPpgriService.getById(this.gri)
                .subscribe((ppgri: Ppgri) => {
                    this.setFormValue('libelle', ppgri.griName);


                });
        });
    }

    public ngOnInit() {
        if (this.isNew) {
            this.setFormValue('misId', this.misId);
            this.setFormValue('cid', this.misId);
            this.setFormValue('cdeormis', 'M');
            this.setFormValue('indorheu', 'I');
            this.setFormValue('saisiemode', SaisieEnum.semaine);
        }
        if (this.misId)
            this._entityPpmisService.getById(this.misId)
                .subscribe((ppmis: Ppmis) => {
                    this.mis = ppmis;
                })
    }

    public saveIndemnity() {
        this._store.dispatch(new SetLoading(this.uuid, true));
        setTimeout(() => {
            this.saveChanges();
        }, 500);
    }

    protected getFormControlNames(): FormKeys<Ppmisih> {
        return [
            [
                'griId',
                new UntypedFormControl(undefined, Validators.required)],
            'misId',
            'cid',
            'qte',
            'unite',
            'cdeormis',
            'indorheu',
            'mntemp',
            'mntcli',
            'mntcli2',
            'libelle',
            'saisiemode',
            'notes'];
    }

    public onMntEmpChange(value?: number) {
        if (value) {
            console.log(this.mis?.coeff);
            if (this.isCoeffChecked()) {
                if (this.mis && this.mis.coeff)
                    this.setFormValue('mntcli', value * this.mis.coeff);
            } else {
                this.setFormValue('mntcli', value);
            }
        }
    }

    private isCoeffChecked() {
        return this.chknCoeff.value;
    }

    public onChkCoeffChange(value?: boolean | undefined) {
        if (value) {
            if (this.mis && this.mis.coeff && this.getFormValue('mntemp'))
                this.setFormValue('mntcli', this.getFormValue('mntemp') * this.mis.coeff);
        } else {
            this.setFormValue('mntcli', this.getFormValue('mntemp'));
        }
    }
}
