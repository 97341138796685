import {AfterViewInit, Component} from '@angular/core';
import {BaseSideFormComponent} from '@app/sam-base/base';
import {FormHandlerService, IwEventHubService, IwStoreService, ModalService} from '@app/sam-base/core';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {Sagen} from '@app/sam-base/models/salary/sagen';
import {BulkManagerComponent} from '@modules/sam-main/admin/components/bulk/bulk-manager.component';
import {BulkManagerOptions} from '@modules/sam-main/admin/components/bulk/bulk-manager.options';
import {OperationType} from '@modules/sam-main/admin/models/operation.model';
import {SalaryForm} from '@modules/sam-main/salary/salary.forms';
import {
    accountingRoles,
    salaryComRoles,
    salaryMgtPeriodRoles,
    salaryMgtRoles
} from '@sam-base/core/auth/access-rules/facture-roles';
import {SagenService} from '@sam-base/core/services/sagen.service';
import {SetLoading} from '@sam-base/core/store/actions/global-form.actions';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {Subject, takeUntil} from 'rxjs';

@Component({
    templateUrl: './periode-side-menu.component.html'
})
export class PeriodSideMenuComponent extends BaseSideFormComponent<Sagen> implements AfterViewInit {
    public ACCOUNTING_ROLES: SamUserRole[] = accountingRoles();
    public SALARY_PERIOD_ADMIN_ROLES: SamUserRole[] = salaryMgtPeriodRoles();
    public SALARY_ADMIN_ROLES: SamUserRole[] = salaryMgtRoles();
    public SALARY_COMMUNICATION_ROLES: SamUserRole[] = salaryComRoles();
    private subscriptions = new Subject();
    private sagen?: Sagen;

    constructor(private _store: IwStoreService, private readonly _eventHub: IwEventHubService<string>,
                private readonly _modalService: ModalService,
                private readonly _formHandler: FormHandlerService<SalaryForm>,
                private readonly _sagenService: SagenService) {
        super();
    }

    public get isPeriodOpen(): boolean {
        return this.sagen?.status === 1;
    }

    public ngAfterViewInit(): void {
        this._store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscriptions))
            .subscribe((data: any) => {
                console.log('data', data);
                this.sagen = data;
            });
    }


    public openPeriod() {
        this._store.dispatch(new SetLoading(this.uuid, true));
        this._sagenService.openPeriode(this.sagen?.salId || '')
            .subscribe({
                next: (sagen) => {
                    const status = sagen.status
                    if (status) {
                        this._sagenService.updateStatus(status);
                    }
                    this._store.dispatch(new actions.SetLoading(this.uuid, false))

                },
                error: () => this._store.dispatch(new actions.SetLoading(this.uuid, false))
            });
    }

    public closePeriode() {
        this._store.dispatch(new SetLoading(this.uuid, true));
        this._sagenService.closePeriode(this.sagen?.salId || '')
            .subscribe({
                next: (sagen) => {
                    const status = sagen.status
                    if (status) {
                        this._sagenService.updateStatus(status);
                    }
                    this._store.dispatch(new actions.SetLoading(this.uuid, false))

                },
                error: () => this._store.dispatch(new actions.SetLoading(this.uuid, false))
            });

    }


    public onCalculate() {
        this.openBulkManager(OperationType.CALCULATE_SALARY, false);
    }

    public onCalculateForce() {
        this.openBulkManager(OperationType.CALCULATE_SALARY, true);
    }

    public openPublish() {
        this.openBulkManager(OperationType.PUBLISH_SALARY)
    }

    public sendByEmail() {
        this.openBulkManager(OperationType.EMAIL_SALARY)
    }

    public openValidateWaitingPayments() {
        this.openBulkManager(OperationType.VALIDATE_WAITING_PAYMENT)
    }

    public async openComptabilite() {
        await this._formHandler.showFormDialog(SalaryForm.SalaryCompta, {salId: this.sagen?.salId});
    }

    public openGenerate() {
        this.openBulkManager(OperationType.CREATE_SALARY)
    }

    public openForcePublish() {
        this.openBulkManager(OperationType.PUBLISH_SALARY, true)
    }

    private openBulkManager(operationType: OperationType, force: boolean = false) {
        const options: BulkManagerOptions = {
            type: operationType,
            salId: this.sagen?.salId,
            force: force
        };

        this._modalService.showModal(BulkManagerComponent, options);
    }
}
