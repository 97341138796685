<div *ngIf="json">
    <h1>{{ 'swissdec.institution.result.title' | translate }}</h1>

    <h2>{{ 'swissdec.institution.result.addresseeContext.title' | translate }}</h2>
    <div>
        <h3>{{ 'swissdec.institution.result.addresseeContext.userAgent.title' | translate }}</h3>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.userAgent.producer' | translate }}
            :</strong> {{ json.addresseeContext.userAgent.producer }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.userAgent.name' | translate }}
            :</strong> {{ json.addresseeContext.userAgent.name }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.userAgent.version' | translate }}
            :</strong> {{ json.addresseeContext.userAgent.version }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.userAgent.standardVersion' | translate }}
            :</strong> {{ json.addresseeContext.userAgent.standardVersion }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.userAgent.certificate' | translate }}
            :</strong> {{ json.addresseeContext.userAgent.certificate }}</p>

        <p><strong>{{ 'swissdec.institution.result.addresseeContext.institutionName' | translate }}
            :</strong> {{ json.addresseeContext.institutionName }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.transmissionDate' | translate }}
            :</strong> {{ json.addresseeContext.transmissionDate | date:'medium' }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.responseID' | translate }}
            :</strong> {{ json.addresseeContext.responseID }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.requestID' | translate }}
            :</strong> {{ json.addresseeContext.requestID }}</p>
    </div>

    <h2>{{ 'swissdec.institution.result.institution.title' | translate }}</h2>
    <div>
        <p><strong>{{ 'swissdec.institution.result.institution.cantonID' | translate }}
            :</strong> {{ json.institution.cantonID }}</p>
        <p><strong>{{ 'swissdec.institution.result.institution.customerIdentity' | translate }}
            :</strong> {{ json.institution.customerIdentity }}</p>
    </div>

    <ng-container *ngIf="json.company">
        <h2>{{ 'swissdec.institution.result.company.title' | translate }}</h2>
        <div>
            <p><strong>{{ 'swissdec.institution.result.company.name' | translate }}
                :</strong> {{ json.company.name.hrrcname }}</p>
            <p><strong>{{ 'swissdec.institution.result.company.uid' | translate }}
                :</strong> {{ json.company.uidbfs.uid }}</p>
        </div>
    </ng-container>

    <p><strong>{{ 'swissdec.institution.result.changesConsideredUpTo' | translate }}
        :</strong> {{ json.changesConsideredUpTo | date:'mediumDate' }}</p>
    <persons-institution-result-viewer [persons]="json?.staff?.person"></persons-institution-result-viewer>
    <h2>{{ 'swissdec.institution.result.title' | translate }}</h2>
    <p><strong>{{ 'swissdec.institution.result.summary.numberOfTaxAtSourceSalaryTags' | translate }}
        :</strong> {{ json.summary?.numberOfTaxAtSourceSalaryTags }}</p>

    <h2>{{ 'swissdec.institution.result.quittanceDetails.title' | translate }}</h2>
    <div>
        <h3>{{ 'swissdec.institution.result.quittanceDetails.totalMonth.title' | translate }}</h3>
        <p><strong>{{ 'swissdec.institution.result.quittanceDetails.totalMonth.totalTaxableEarning' | translate }}
            :</strong> {{ json.taxAtSourceDeclarationQuittance?.totalMonth?.totalTaxableEarning | currency:'CHF' }}
        </p>
        <p><strong>{{ 'swissdec.institution.result.quittanceDetails.totalMonth.totalTaxAtSource' | translate }}
            :</strong> {{ json.taxAtSourceDeclarationQuittance?.totalMonth?.totalTaxAtSource | currency:'CHF' }}
        </p>
        <p><strong>{{ 'swissdec.institution.result.quittanceDetails.totalMonth.totalCommission' | translate }}
            :</strong> {{ json.taxAtSourceDeclarationQuittance?.totalMonth?.totalCommission | currency:'CHF' }}
        </p>
        <p><strong>{{ 'swissdec.institution.result.quittanceDetails.totalMonth.currentMonth' | translate }}
            :</strong> {{ json.taxAtSourceDeclarationQuittance?.totalMonth?.currentMonth | date:'mediumDate' }}
        </p>
    </div>
    <ng-container *ngIf="json.taxAtSourceQuittanceWithoutCompletion?.totalMonth">
        <h2>{{ 'swissdec.institution.result.quittanceDetails.withoutCompletion.title' | translate }}</h2>
        <div>
            <h3>{{ 'swissdec.institution.result.quittanceDetails.totalMonth.title' | translate }}</h3>
            <p><strong>{{ 'swissdec.institution.result.quittanceDetails.totalMonth.totalTaxableEarning' | translate }}
                :</strong> {{ json?.taxAtSourceQuittanceWithoutCompletion?.totalMonth?.totalTaxableEarning | currency:'CHF' }}
            </p>
            <p><strong>{{ 'swissdec.institution.result.quittanceDetails.totalMonth.totalTaxAtSource' | translate }}
                :</strong> {{ json?.taxAtSourceQuittanceWithoutCompletion?.totalMonth?.totalTaxAtSource | currency:'CHF' }}
            </p>
            <p><strong>{{ 'swissdec.institution.result.quittanceDetails.totalMonth.totalCommission' | translate }}
                :</strong> {{ json?.taxAtSourceQuittanceWithoutCompletion?.totalMonth?.totalCommission | currency:'CHF' }}
            </p>
            <p><strong>{{ 'swissdec.institution.result.quittanceDetails.totalMonth.currentMonth' | translate }}
                :</strong> {{ json?.taxAtSourceQuittanceWithoutCompletion?.totalMonth?.currentMonth | date:'mediumDate' }}
            </p>
        </div>
    </ng-container>
</div>