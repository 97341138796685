// eslint-disable-next-line max-classes-per-file
export enum ViewgenReportType {
    AhvFreeReport = 'AhvFreeReport',
    AhvReport = 'AhvReport',
    FakReport = 'FakReport',
    FakDetailedReport = 'FakDetailedReport',
    KtgReport = 'KtgReport', // Barcode = 'Barcode',
    // ORDBarcode = 'ORDBarcode',
    TaxAccountingReport = 'TaxAccountingReport',
    UvgReport = 'UvgReport',
    UvgzReport = 'UvgzReport', // StatisticReport = 'StatisticReport',
    PlausibilityReport = 'PlausibilityReport',
    Anonymizer = 'Anonymizer',
    Validator = 'Validator',
    AhvProofOfInsuranceReport = 'AhvProofOfInsuranceReport',
    OwnershipRightDetail = 'OwnershipRightDetail',
}

export enum MonthlyViewgenReportType {
    QstReport = 'QstReport'
}

export enum MonthlyEMAViewgenReportType {
    AhvProofOfInsuranceReport = 'AhvProofOfInsuranceReport',
}

export class ViewgenReportCmd {
    public reportType?: ViewgenReportType;
    public filename?: string;
    public options?: Option[];

}

export class Option {
    public name?: string;
    public value?: string;
}
