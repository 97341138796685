import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {DialogResponse} from '@sam-base/models/admin/dialog-response.model';
import {SxProcessFlow} from '@sam-base/models/admin/sx-process-flow.enum';
import {SxResponseState} from '@sam-base/models/admin/sx-response-state.enum';


@IwRestEntity('sasx5institution', '', 'common')
export class Sasx5institution {
    @IwRestEntityId() public id?: string;
    @IwColumn({
        index: 1,
        name: 'domain'
    }) public domain?: string;
    public cantonImp?: string;
    @IwColumn({
        index: 2,
        name: 'state'
    }) public state?: SxResponseState;
    public xmlReceived?: string;
    public gestion?: string;
    public institutionCode?: string;
    public institutionIdref?: string;
    @IwColumn({
        index: 3,
        name: 'assNocli'
    }) public assNocli?: string;
    @IwColumn({
        index: 4,
        name: 'assNocai'
    }) public assNocai?: string;
    @IwColumn({
        index: 5,
        name: 'assNocta'
    }) public assNocta?: string;
    public assNom?: string;
    public processed?: boolean;
    public userMessage?: string;
    public notifications?: SxNotification[];
    public completionUrl?: string;
    public login?: string;
    public password?: string;
    public processFlow?: SxProcessFlow;
    public declarationId?: string;
    public expectedAvailability?: string;
    public resultJson?: string;
    @IwColumn({
        index: 6,
        name: 'incompleteDeclaration'
    }) public incompleteDeclaration?: boolean;
    public dialogJson?: DialogResponse;
}


export class SxNotification {
    qualityLevel?: string
    descriptionCode?: number;
    description?: string
}
