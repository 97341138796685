<form [formGroup]="formGroup"
      class="row space-form"
      style="max-height: 40vh; overflow: auto;">
    <div class="col-12">
        <h3>{{ 'title_element_in_salary_slip' | translate }}</h3>

        <div class="table-container"
             style="width: 19vw; height: 20vh; overflow: auto;">
            <iw-table
                    [columns]="acompteTableColumns"
                    [data]="acompteInfo"
                    [defaultSelected]="false"
                    [isDisabled]="false"
                    [isFilterEnable]="false"
                    [isGridColumnMenuEnable]="false"
                    [isSortIconEnable]="false"
                    [selectionMode]="'single'"
                    [virtualScroll]="true"
                    class="iw-grid"></iw-table>
        </div>
        <div class="close-button-container"
             style="display: flex; justify-content: flex-end; margin-top: 10px;">
            <iw-button #closeButton
                       (press)="destroyForm()"
                       [text]="'fermer' | translate"
                       class="iw-button"></iw-button>
        </div>
    </div>
</form>
