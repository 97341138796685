import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Ppacattest} from '@app/sam-base/models/placement';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const acattestTabsFormControls: TabFormControl<Ppacattest>[] = [
    {
        formControls: [
            'acaId',
            'dateaca',
            'empId',
            'salIdLim',
            'pdftplname',
            'acatype']
    },
    {
        title: 'Années',
        formControls: []
    },
    {
        title: 'Périodes',
        formControls: []
    },
    {
        title: 'Dernière mission',
        formControls: []
    },
    {
        title: 'Première mission',
        formControls: []
    },
    {
        title: 'Historique missions',
        formControls: []
    }];
