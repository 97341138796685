<div *ngIf="persons?.length > 0">
    <h2>{{ 'swissdec.institution.result.staff.title' | translate }}</h2>

    <div style="height: 200px;">
        <iw-table #employeesGrid
                  (applyFilter)="onApplyFilter($event)"
                  (columnSort)="onSort()"
                  (rowClicked)="onEmployeeClicked($event)"
                  [columns]="personGridColumns"
                  [data]="filteredPersons"
                  [isFilterEnable]="true"
                  [isSortIconEnable]="true"
                  [virtualScroll]="true"></iw-table>
    </div>

    <p-fieldset *ngIf="selectedPerson"
                [legend]="selectedPerson?.lastname + ' ' + selectedPerson?.firstname">

        <div *ngIf="selectedPerson">
            <p><strong>{{ 'swissdec.institution.result.staff.svAsNumber' | translate }}
                :</strong> {{ selectedPerson.socialInsuranceIdentification.svasnumber }}</p>
            <p><strong>{{ 'swissdec.institution.result.staff.employeeNumber' | translate }}
                :</strong> {{ selectedPerson.employeeNumber }}</p>
            <p><strong>{{ 'swissdec.institution.result.staff.lastname' | translate }}
                :</strong> {{ selectedPerson.lastname }}
            </p>
            <p><strong>{{ 'swissdec.institution.result.staff.firstname' | translate }}
                :</strong> {{ selectedPerson.firstname }}
            </p>
            <p><strong>{{ 'swissdec.institution.result.staff.sex' | translate }}:</strong> {{ selectedPerson.sex }}</p>
            <p><strong>{{ 'swissdec.institution.result.staff.dateOfBirth' | translate }}
                :</strong> {{ selectedPerson.dateOfBirth | date:'mediumDate' }}</p>
            <p><strong>{{ 'swissdec.institution.result.staff.civilStatus' | translate }}
                :</strong> {{ selectedPerson.civilStatus.status }}</p>

            <div *ngIf="selectedPerson?.declarationCategory?.entry">
                <p><strong>{{ 'swissdec.institution.result.staff.declarationCategory.entry' | translate }}
                    :</strong> {{ selectedPerson?.declarationCategory?.entry.validAsOf | date:'mediumDate' }}</p>
            </div>

            <div *ngIf="selectedPerson?.declarationCategory?.withdrawal">
                <p><strong>{{ 'swissdec.institution.result.staff.declarationCategory.withdrawal' | translate }}
                    :</strong> {{ selectedPerson?.declarationCategory?.withdrawal.validAsOf | date:'mediumDate' }}</p>
            </div>
            
            <ng-container *ngIf="selectedPerson.contributions?.contribution?.length > 0">
                <h4>{{ 'swissdec.institution.result.staff.contributions.title' | translate }}</h4>
                <div *ngFor="let contribution of selectedPerson.contributions?.contribution; let j = index">
                    <h5>{{ 'swissdec.institution.result.staff.contributions.contribution' | translate }} {{ j + 1 }}</h5>
                    <p><strong>{{ 'swissdec.institution.result.staff.contributions.validAsOf' | translate }}
                        :</strong> {{ contribution.validAsOf | date:'medium' }}</p>
                    <p>
                        <strong>{{ 'swissdec.institution.result.staff.contributions.employeeContribution' | translate }}
                            :</strong> {{ contribution.employeeContribution | currency:'CHF' }}</p>
                    <p>
                        <strong>{{ 'swissdec.institution.result.staff.contributions.employerContribution' | translate }}
                            :</strong> {{ contribution.employerContribution | currency:'CHF' }}</p>
                    <p>
                        <strong>{{ 'swissdec.institution.result.staff.contributions.thirdPartyContribution' | translate }}
                            :</strong> {{ contribution.thirdPartyContribution | currency:'CHF' }}</p>
                    <p><strong>{{ 'swissdec.institution.result.staff.contributions.bvglppcode' | translate }}
                        :</strong> {{ contribution.bvglppcode }}</p>

                    <div *ngIf="contribution.info && contribution.info.notification">
                        <h6>{{ 'swissdec.institution.result.staff.contributions.notifications.title' | translate }}</h6>
                        <ul>
                            <li *ngFor="let notification of contribution.info.notification">
                                {{ notification.description }}
                                ({{ 'swissdec.institution.result.staff.contributions.notifications.code' | translate }}
                                : {{ notification.descriptionCode }})
                            </li>
                        </ul>
                    </div>
                </div>
            </ng-container>

            <div *ngFor="let salary of selectedPerson.taxAtSourceSalaries?.taxAtSourceSalary">
                <h4>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.title' | translate }}</h4>
                <p><strong>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.canton' | translate }}
                    :</strong> {{ salary.taxAtSourceCanton }}</p>
                <p><strong>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.municipalityID' | translate }}
                    :</strong> {{ salary.taxAtSourceMunicipalityID }}</p>
                <p><strong>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.currentMonth' | translate }}
                    :</strong> {{ salary.currentMonth | date:'mediumDate' }}</p>

                <div *ngIf="salary.correction">
                    <h4>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.title' | translate }}</h4>

                    <div *ngFor="let corr of salary.correction">
                        <!-- Section pour awaitCorrectionFromCompany -->
                        <div *ngIf="corr.awaitCorrectionFromCompany">
                            <h5 class="notification warn">{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.await.title' | translate }}</h5>
                            <p>
                                <strong>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.await.validAsOf' | translate }}
                                    :</strong>
                                {{ corr.awaitCorrectionFromCompany.validAsOf | date:'mediumDate' }}</p>
                            <p>
                                <strong>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.await.taxAtSourceCode' | translate }}
                                    :</strong>
                                {{ corr.awaitCorrectionFromCompany.taxAtSourceCategory.taxAtSourceCode }}</p>

                            <div *ngIf="corr.awaitCorrectionFromCompany.comment?.notification">
                                <h5>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.await.comments' | translate }}</h5>
                                <div *ngFor="let notification of corr.awaitCorrectionFromCompany.comment.notification">
                                    <p>{{ notification.description }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- Section pour reversal -->
                        <div *ngIf="corr.reversal">
                            <h5 class="notification error">{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.reversal.title' | translate }}</h5>

                            <div>
                                <h5>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.reversal.old' | translate }}</h5>
                                <p>
                                    <strong>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.reversal.taxAtSourceCode' | translate }}
                                        :</strong>
                                    {{ corr.reversal.old.taxAtSourceCategory.taxAtSourceCode }}</p>
                                <p>
                                    <strong>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.reversal.taxableEarning' | translate }}
                                        :</strong>
                                    {{ corr.reversal.old.taxableEarning | currency:'CHF' }}</p>
                                <p>
                                    <strong>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.reversal.ascertainedTaxableEarning' | translate }}
                                        :</strong>
                                    {{ corr.reversal.old.ascertainedTaxableEarning | currency:'CHF' }}</p>
                                <p>
                                    <strong>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.reversal.taxAtSource' | translate }}
                                        :</strong>
                                    {{ corr.reversal.old.taxAtSource | currency:'CHF' }}</p>
                            </div>

                            <div>
                                <h5>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.reversal.new' | translate }}</h5>
                                <p>
                                    <strong>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.reversal.categoryPredefined' | translate }}
                                        :</strong>
                                    {{ corr.reversal.new.taxAtSourceCategory.categoryPredefined }}</p>
                                <p>
                                    <strong>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.reversal.taxableEarning' | translate }}
                                        :</strong>
                                    {{ corr.reversal.new.taxableEarning | currency:'CHF' }}</p>
                                <p>
                                    <strong>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.reversal.ascertainedTaxableEarning' | translate }}
                                        :</strong>
                                    {{ corr.reversal.new.ascertainedTaxableEarning | currency:'CHF' }}</p>
                                <p>
                                    <strong>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.reversal.taxAtSource' | translate }}
                                        :</strong>
                                    {{ corr.reversal.new.taxAtSource | currency:'CHF' }}</p>
                            </div>

                            <div *ngIf="corr.reversal.comment?.notification">
                                <h5>{{ 'swissdec.institution.result.staff.taxAtSourceSalary.corrections.reversal.comments' | translate }}</h5>
                                <div *ngFor="let notification of corr.reversal.comment.notification">
                                    <p>{{ notification.description }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <hr>
        </div>
    </p-fieldset>

</div>