import {IwRestEntity} from '../../core/rest-api';
import {CusCodeInfo} from './cusCodeInfo';
import {TxInfo} from './txInfo';


@IwRestEntity('employeeDeductionInfo')
export class EmployeeDeductionInfo {
    public edId?: string;
    public deductionCode?: string;
    public soumisCode?: number;
    public avsNoFranchise?: boolean;
    public txInfo?: TxInfo;
    public cusCodeInfo?: CusCodeInfo;
}
