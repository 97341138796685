<div *ngIf="json">
    <h1>{{ 'swissdec.institution.result.title' | translate }}</h1>

    <h2>{{ 'swissdec.institution.result.addresseeContext.title' | translate }}</h2>
    <div>
        <h3>{{ 'swissdec.institution.result.addresseeContext.userAgent.title' | translate }}</h3>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.userAgent.producer' | translate }}
            :</strong> {{ json.addresseeContext.userAgent.producer }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.userAgent.name' | translate }}
            :</strong> {{ json.addresseeContext.userAgent.name }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.userAgent.version' | translate }}
            :</strong> {{ json.addresseeContext.userAgent.version }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.userAgent.standardVersion' | translate }}
            :</strong> {{ json.addresseeContext.userAgent.standardVersion }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.userAgent.certificate' | translate }}
            :</strong> {{ json.addresseeContext.userAgent.certificate }}</p>

        <p><strong>{{ 'swissdec.institution.result.addresseeContext.institutionName' | translate }}
            :</strong> {{ json.addresseeContext.institutionName }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.transmissionDate' | translate }}
            :</strong> {{ json.addresseeContext.transmissionDate | date:'medium' }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.responseID' | translate }}
            :</strong> {{ json.addresseeContext.responseID }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.requestID' | translate }}
            :</strong> {{ json.addresseeContext.requestID }}</p>
    </div>

    <h2>{{ 'swissdec.institution.result.institution.title' | translate }}</h2>
    <div>
        <p><strong>{{ 'swissdec.institution.result.institution.cantonID' | translate }}
            :</strong> {{ json.institution.cantonID }}</p>
        <p><strong>{{ 'swissdec.institution.result.institution.customerIdentity' | translate }}
            :</strong> {{ json.institution.customerIdentity }}</p>
    </div>

    <h2>{{ 'swissdec.institution.result.quittanceDetails.title' | translate }}</h2>
    <div>
        <h3>{{ 'swissdec.institution.result.quittanceDetails.period.title' | translate }}</h3>
        <p><strong>{{ 'swissdec.institution.result.quittanceDetails.period.from' | translate }}
            :</strong> {{ json.taxCrossborderQuittanceWithoutCompletion.period.from | date:'mediumDate' }}</p>
        <p><strong>{{ 'swissdec.institution.result.quittanceDetails.period.until' | translate }}
            :</strong> {{ json.taxCrossborderQuittanceWithoutCompletion.period.until | date:'mediumDate' }}</p>

        <p><strong>{{ 'swissdec.institution.result.quittanceDetails.taxAtSourceCanton' | translate }}
            :</strong> {{ json.taxCrossborderQuittanceWithoutCompletion.taxAtSourceCanton }}</p>
        <p><strong>{{ 'swissdec.institution.result.quittanceDetails.taxableEarning' | translate }}
            :</strong> {{ json.taxCrossborderQuittanceWithoutCompletion.taxableEarning | currency:'CHF' }}</p>
    </div>
</div>