import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseSideFormComponent} from '@app/sam-base/base';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {FormHandlerService, GridProfile, IwEventHubService} from '@app/sam-base/core';
import {
    employeeCommunicationAccessRoles,
    employeeMgtAccessRoles,
    employeeMissionAccessRoles,
    salaryUserRoles
} from '@app/sam-base/core/auth/access-rules/employee-form';
import {pprapviewDefaultProfile} from '@app/sam-base/core/grid-profile/models/grid-profile-default';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {employeToSmsType} from '@app/sam-base/helpers/sms-map';
import {Ppaco, Ppclimis, Ppemp, Ppind, Pprapview} from '@app/sam-base/models/placement';
import {CommunicationType} from '@core/mail/mail.models';
import {MailComposerComponent} from '@modules/sam-main/placement/components/mail-composer/mail-composer.component';
import {PlacementForm} from '@modules/sam-main/placement/placement.forms';
import {PpempService} from '@modules/sam-main/placement/services/ppemp.service';
import {TranslateService} from '@ngx-translate/core';
import {LayoutSubNavigationEvent} from '@sam-base/layout';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {lastValueFrom, of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {PlacementSearchNavigation} from '../../../../../core/placement-search/placement-search.service';
import {EntityService} from '../../services/entity.service';
import {IwSendSMSModalComponent} from '../send-sms/iw-send-sms-modal/iw-send-sms-modal.component';

@Component({
    templateUrl: './employe-side-menu.component.html'
})
export class EmployeSideMenuComponent extends BaseSideFormComponent<Ppemp> implements OnInit, OnDestroy {

    public ppemp: Ppemp = new Ppemp();
    public employeeMgtAccessRoles: SamUserRole[] = employeeMgtAccessRoles();
    public employeeCommunicationRoles: SamUserRole[] = employeeCommunicationAccessRoles();
    public employeeMissionButtonRoles: SamUserRole[] = employeeMissionAccessRoles();
    public salaryUserRoles: SamUserRole[] = salaryUserRoles();

    public showPlanning = false;
    public cansms = false;
    private readonly _empRest: RestEntityClient<Ppemp>;
    private subscription = new Subject();

    constructor(
        private readonly _store: IwStoreService,
        private readonly _entityService: EntityService,
        private readonly _toastService: ToastService,
        private readonly _modalService: ModalService,
        private _translate: TranslateService,
        private readonly _gridNavigation: PlacementSearchNavigation,
        private _ppempService: PpempService,
        private readonly _formHandler: FormHandlerService<PlacementForm>,
        _restService: RestApiService,
        private readonly _eventHub: IwEventHubService<string>
    ) {
        super();
        this._empRest = _restService.getEntityClient(Ppemp);
    }

    private get _isEmployeeClosed() {
        return this.ppemp.cempstatus === '9';
    }

    private get ocDialogTitle() {
        return this._isEmployeeClosed ? 'open_employee_title' : 'close_employee_title';
    }

    private get ocAConfirmMessage(): string {
        return this._isEmployeeClosed ? 'open_employee' : 'close_employee';
    }

    public ngOnInit() {
        this._store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscription))
            .subscribe((data: any) => {
                this.ppemp = data;
                if (data) {
                    this.cansms = data.allowsms ?? false;
                }
            });
    }

    public ngOnDestroy() {
        this.subscription.next(undefined);
        this.subscription.complete();
    }

    public async toggleBlockEmployee() {
        if (this.ppemp && this.ppemp.empId) {

            const options: ModalMessageOptions = {
                message: [`${this.ppemp.cempstatus === '8' ? this._translate.instant('unblock_employe') : this._translate.instant('block_employe')} `],
                showCancel: true,
                title: this._translate.instant('ppemp_form_title')
            };

            try {
                await this._modalService.showModal(ModalMessageComponent, options);
                this._entityService.blockEmploye(this.ppemp.empId)
                    .pipe(catchError((err: any) => {
                        this._toastService.error('block_error');
                        return of(false);
                    }))
                    .subscribe(() => {
                        this._store
                            .dispatch(new actions.LoadEntity(this.uuid));
                    });
            } catch {
            }
        }
    }

    /**
     * Function to open email form
     */
    public openEmailForm() {
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.PPEMP_GENERIC,
            entityIds: [this.ppemp.empId || '']
        });
    }

    /**
     * Function to open missions grid with employee in context
     */
    public openMissions() {
        if (this.ppemp.empId) {
            this._gridNavigation.navigate({
                entity: Ppclimis,
                baseFilters: {empId: this.ppemp.empId}
            });
        }
    }

    /**
     * Function to open sms form
     */
    public async openSendSMSForm() {
        if (this.ppemp.empId) {
            const entity = await lastValueFrom(this._empRest.getById(this.ppemp.empId))
                .then(e => employeToSmsType([e]));
            this._modalService.showModal(IwSendSMSModalComponent, entity);
        }
    }

    /**
     * Function to open rapports
     */
    public openRapports() {
        if (this.ppemp.empId) {
            const gridProfile: GridProfile<Pprapview> = JSON.parse(JSON.stringify(new GridProfile(pprapviewDefaultProfile(<string>'ppemp'))));
            this._gridNavigation.navigate({
                entity: Pprapview,
                profile: gridProfile,
                baseFilters: {empId: this.ppemp.empId}
            });
        }
    }

    /**
     * Function to open indemnites
     */
    public openIndemnites() {
        if (this.ppemp.empId) {
            this._gridNavigation.navigate({
                entity: Ppind,
                baseFilters: {empId: this.ppemp.empId}
            });
        }
    }

    /**
     * Function to open acomptes
     */
    public openAcomptes() {
        if (this.ppemp.empId) {
            this._gridNavigation.navigate({
                entity: Ppaco,
                baseFilters: {empId: this.ppemp.empId}
            });
        }
    }

    /**
     * Function to open heures travaillees
     */
    public openHeuresT() {
        this._eventHub.emit(LayoutSubNavigationEvent.TIMESHEET_EMP,
            this.ppemp.empId);
    }

    /**
     * Function to open planning
     */
    public openPlanning() {
        this.showPlanning = true;
    }

    public syncEmp() {
        if (!this.ppemp.empId) {
            return;
        }
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this._ppempService.updateEmp(this.ppemp.empId)
            .subscribe({
                next: () => this._store.dispatch(new actions.SetLoading(this.uuid, false)),
                error: () => this._store.dispatch(new actions.SetLoading(this.uuid, false))
            });
    }

    public closeDialogPlanning() {
        this.showPlanning = false;
    }

    /**
     * Function to open situation employe form
     */
    public openSituation() {
        this._formHandler.showFormDialog(PlacementForm.Empsitu, {
            empId: this.ppemp.empId
        });
    }

    public async openCloseEmployeeDialog() {
        const options: ModalMessageOptions = {
            message: [],
            showCancel: true,
            title: this._translate.instant(this.ocDialogTitle),
            confirmMessage: this._translate.instant(this.ocAConfirmMessage),
            okDisabled: false
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            if (this._isEmployeeClosed) {
                this.openEmployee(this.ppemp?.empId);
                return;
            }
            this.closeEmployee(this.ppemp?.empId);
        } catch (error) {
            return false;
        }
    }

    private closeEmployee(empId?: string) {
        if (!empId) {
            return;
        }
        this._entityService.closeEmployee(empId)
            .subscribe({
                next: () => this._store.dispatch(new actions.LoadEntity(this.uuid)),
                error: () => this._toastService.warning('close_employee_error')
            });
    }

    private openEmployee(empId?: string) {
        if (!empId) {
            return;
        }
        this._entityService.openEmployee(empId)
            .subscribe({
                next: () => this._store.dispatch(new actions.LoadEntity(this.uuid)),
                error: () => this._toastService.warning('open_employee_error')
            });
    }
}
