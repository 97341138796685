import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {BusinessExceptionErrors} from '@sam-base/core/auth/interceptors/error.model';
import {EmployeeDeclaration} from '@sam-base/models/admin/employee-declaration.model';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {SxMode} from '@sam-base/models/admin/sx-mode.enum';
import {SxMonthlyType} from '@sam-base/models/admin/sx-monthly-type.enum';
import {SxStatus} from '@sam-base/models/admin/sx-status.enum';
import {SxType} from '@sam-base/models/admin/sx-type.enum';
import {SxYearlyType} from '@sam-base/models/admin/sx-yearly-type.enum';

@IwRestEntity('sasx5dec', '', 'common')
export class Sasx5dec {
    @IwColumn({
        index: 0,
        width: 80
    }) @IwRestEntityId() public id?: string;
    @IwColumn({
        index: 1,
        type: 'status'
    }) public status?: SxStatus;
    @IwColumn({index: 2}) public sxType?: SxType;
    public sxMonthlyType?: SxMonthlyType;
    public sxYearlyType?: SxYearlyType;
    @IwColumn({index: 3}) public annee?: string;
    @IwColumn({index: 4}) public month?: string;
    @IwColumn({index: 5}) public gestion?: string;
    @IwColumn({
        index: 6,
        type: 'salId'
    }) public salId?: string;
    @IwColumn({index: 7}) public sxMode?: SxMode;
    @IwColumn({
        index: 8,
        type: 'keyword'
    }) public sxJobkey?: string;
    @IwColumn({index: 10}) public sxDeclid?: string;
    public declRespId?: string;
    public prevRequestId?: string;
    public prevResponseId?: string;
    public isSubstitution?: boolean;
    public xmlReceived?: string;
    public xmlSent?: string;
    public notifications?: SxNotificationBundle;
    public error?: BusinessExceptionErrors;
    public institutions?: Sasx5institution[];
    public employeeDeclarations?: EmployeeDeclaration[];
    public hrrcName?: string;
    public street?: string;
    public zipCode?: string;
    public city?: string;
    public uidBfs?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
}


export interface SxNotificationBundle {
    errors?: SxNotification[];
    warnings?: SxNotification[];
    infos?: SxNotification[];
}

export interface SxNotification {
    qualityLevel?: string;
    descriptionCode?: number;
    description?: string;
}


export enum SwissdecUIEvents {
    DECLARE_SALARY_DONE = 'DECLARE_SALARY_DONE'
}