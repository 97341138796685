{
  "name": "sam-placemanent-app",
  "version": "2.0.6-12092024-0924",
  "scripts": {
    "ng": "ng",
    "build": "ng build --configuration production",
    "create-trad-link": "node tools/trad-create-link.js",
    "local-dev": "ng run iw-sam-app:serve-local-dev --port=4200 --open",
    "local-staging": "ng run iw-sam-app:serve-local-staging --port=4200 --open",
    "local-prod": "ng run iw-sam-app:serve-local-prod --port=4200 --open",
    "remote-dev": "ng run iw-sam-app:serve-remote-dev --port=4200 --open",
    "remote-staging": "ng run iw-sam-app:serve-remote-staging --port=4200 --open",
    "build-dev": "ng build",
    "build-docs": "compodoc -c .compodocrc.json -p ./src/tsconfig.app.json",
    "lint": "ng lint",
    "docs": "compodoc -c .compodocrc.json -p ./src/tsconfig.app.json -s -o"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.10",
    "@angular/cdk": "^18.2.11",
    "@angular/common": "^18.2.10",
    "@angular/compiler": "^18.2.10",
    "@angular/core": "^18.2.10",
    "@angular/forms": "^18.2.10",
    "@angular/platform-browser": "^18.2.10",
    "@angular/platform-browser-dynamic": "^18.2.10",
    "@angular/router": "^18.2.10",
    "@fullcalendar/angular": "^6.1.15",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/daygrid": "^6.1.15",
    "@fullcalendar/interaction": "^6.1.15",
    "@fullcalendar/timegrid": "^6.1.15",
    "@ngrx/effects": "^18.1.1",
    "@ngrx/store": "^18.1.1",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^16.0.0",
    "angular-iban": "^18.0.0",
    "file-saver": "^2.0.5",
    "flatted": "^3.3.1",
    "flexboxgrid": "^6.3.1",
    "iban": "^0.0.14",
    "jwt-decode": "^4.0.0",
    "keycloak-angular": "^16.0.1",
    "keycloak-js": "^25.0.6",
    "lodash": "^4.17.21",
    "moment": "^2.30.1",
    "ngx-mask": "^18.0.0",
    "normalize.css": "^8.0.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.11",
    "quill": "^2.0.2",
    "rxjs": "^7.8.1",
    "timers": "^0.1.1",
    "tslib": "^2.7.0",
    "uuidjs": "^4.2.1",
    "xml2js": "^0.6.2",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.11",
    "@angular-devkit/core": "^18.2.11",
    "@angular-devkit/schematics": "^18.2.11",
    "@angular-eslint/builder": "^18.4.0",
    "@angular-eslint/eslint-plugin": "^18.4.0",
    "@angular-eslint/eslint-plugin-template": "^18.4.0",
    "@angular-eslint/schematics": "^18.4.0",
    "@angular-eslint/template-parser": "^18.4.0",
    "@angular/cli": "^18.2.11",
    "@angular/compiler-cli": "^18.2.10",
    "@angular/language-service": "^18.2.10",
    "@compodoc/compodoc": "^1.1.11",
    "@fortawesome/fontawesome-free": "^6.6.0",
    "@ngrx/store-devtools": "18.1.1",
    "@types/file-saver": "^2.0.7",
    "@types/lodash": "^4.17.10",
    "@types/node": "^22.7.4",
    "@types/xml2js": "^0.4.14",
    "@types/yargs": "^17.0.32",
    "@typescript-eslint/eslint-plugin": "^8.8.0",
    "@typescript-eslint/utils": "^8.8.0",
    "@typescript-eslint/parser": "^8.8.0",
    "eslint": "^9.11.1",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-jsdoc": "^50.3.1",
    "eslint-plugin-no-null": "^1.0.2",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "ng-packagr": "^18.2.1",
    "sort-json": "^2.0.0",
    "stylus": "^0.63.0",
    "ts-node": "~10.9.2",
    "typescript": "~5.5.4"
  }
}
