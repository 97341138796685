<form [formGroup]="formGroup"
      class="row">

    <iw-checkbox #chkNomempuc
                 [isDisabled]="true"
                 [label]="'forcerLeNom' | translate"
                 class="iw-input col-xs-6"
                 formControlName="nomempuc"></iw-checkbox>
    <div class="col-xs-6"></div>

    <iw-checkbox #chkPrenomempuc
                 [isDisabled]="true"
                 [label]="'forcerLePrenom' | translate"
                 class="iw-input col-xs-6 margin-top-5"
                 formControlName="prenomempu"></iw-checkbox>

</form>
