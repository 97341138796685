<form [formGroup]="formGroup"
      class="row">

    <iw-checkbox #chkNomcliuc
                 [isDisabled]="true"
                 [label]="'nomDuClientEnMajuscules' | translate"
                 class="iw-input col-xs-6"
                 formControlName="nomcliuc"></iw-checkbox>
    <div class="col-xs-6"></div>

    <iw-enum-dropdown #cboModeFactu
                      [inputEnum]="modeFact"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'modeDeFacturationParDefaut' | translate"
                      class="iw-input iw-input col-xs-6 margin-top-5"
                      formControlName="modefactu"></iw-enum-dropdown>
    <div class="col-xs-6"></div>

    <iw-enum-dropdown #cboCliadrMode
                      [inputEnum]="constAdresse"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'constructionDeLadresse' | translate"
                      class="iw-input iw-input col-xs-6"
                      formControlName="cliadrmode"></iw-enum-dropdown>
    <div class="col-xs-6"></div>

    <iw-checkbox #chkAlloTraif0
                 [isDisabled]="isReadonly"
                 [label]="'autoriserUnTarifAZero' | translate"
                 class="iw-input col-xs-6 margin-top-10"
                 formControlName="allotarif0"></iw-checkbox>

</form>