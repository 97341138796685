import {Component, OnDestroy, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {ToastService} from '@app/sam-base/core/toast';
import {monthsBetweenDates} from '@app/sam-base/helpers';
import {ComboboxItem} from '@app/sam-base/models';
import {SwissdecService} from '@modules/sam-main/admin/services/swissdec-service';
import {TranslateService} from '@ngx-translate/core';
import {Sasx5dec} from '@sam-base/models/admin/sasx5dec.model';
import {SxMonthlyType} from '@sam-base/models/admin/sx-monthly-type.enum';
import {SxType} from '@sam-base/models/admin/sx-type.enum';
import {
    MonthlyEMAViewgenReportType,
    MonthlyViewgenReportType,
    ViewgenReportCmd,
    ViewgenReportType
} from '@sam-base/models/admin/viewgen.model';
import {Subject} from 'rxjs';

@Component({
    selector: 'iw-tab-sasx5dec-viewgen',
    templateUrl: './tab-sasx5dec-viewgen.component.html'
})
export class TabSasx5decViewgenComponent extends BasePartialFormComponent<Sasx5dec> implements OnInit, OnDestroy {
    public optionsLanguage: ComboboxItem<string>[] = [
        {
            name: 'FR',
            value: 'FR'
        },
        {
            name: 'DE',
            value: 'DE'
        },
        {
            name: 'IT',
            value: 'IT'
        }];
    public optionsAlignment: ComboboxItem<string>[];
    public readonly yearlyOptionsViewgenReportType = ViewgenReportType;
    public readonly monthlyOptionsViewgenReportType = MonthlyViewgenReportType;
    public readonly monthlyEMAOptionsViewgenReportType = MonthlyEMAViewgenReportType;
    public cmdReportType?: ViewgenReportType;
    public cmdLanguage?: string;
    public cmdAlignment?: string;
    public cmdSpecimen?: boolean;
    public cmdEnglish?: boolean;
    public cmdCanton?: string;
    public isLoading = false;
    protected readonly monthsBetweenDates = monthsBetweenDates;
    private _excludedType: (keyof ViewgenReportType)[] = [];
    private subscriptions = new Subject();

    constructor(private readonly _toastService: ToastService, private readonly _translate: TranslateService,
                private readonly _swissdecService: SwissdecService) {
        super();
        this.optionsAlignment = [
            {
                name: this._translate.instant('LEFT'),
                value: 'LEFT'
            },
            {
                name: this._translate.instant('RIGHT'),
                value: 'RIGHT'
            }];
    }

    public get isTaxAccountingReport(): boolean {
        return this.cmdReportType === ViewgenReportType.TaxAccountingReport;
    }

    public get isMonthlyType(): boolean {
        return this.getFormValue('sxType') === SxType.MONTHLY;
    }

    public get canGenerate(): boolean {
        const isTypeReportDefined = !!this.cmdReportType;
        if (this.isMonthlyType) {
            return isTypeReportDefined && !!this.cmdCanton && this.cmdCanton !== '';
        }
        return isTypeReportDefined;
    }

    public get reportTypeEnum(): any {
        if (this.isMonthlyType) {
            const monthlyType = this.getFormValue('sxMonthlyType');
            if (monthlyType === SxMonthlyType.EMA) {
                return this.monthlyEMAOptionsViewgenReportType;
            }
            return this.monthlyOptionsViewgenReportType;
        }
        return this.yearlyOptionsViewgenReportType;
    }

    public get entityId() {
        const id = this.getFormValue('id');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public ngOnInit() {
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    // eslint-disable-next-line complexity
    public onGenerate() {
        const cmd = new ViewgenReportCmd();
        cmd.reportType = this.cmdReportType;
        // eslint-disable-next-line max-len
        cmd.filename = `${cmd.reportType}_${this.getFormValue('salId')}_${this.getFormValue('gestion')}_${this.getFormValue('sxType')}`;
        cmd.options = [];
        // build options based from values
        if (this.cmdLanguage) {
            cmd.options.push({
                name: 'language',
                value: this.cmdLanguage
            });
        }
        if (this.cmdAlignment) {
            cmd.options.push({
                name: 'alignment',
                value: this.cmdAlignment
            });
        }
        if (this.cmdSpecimen) {
            cmd.options.push({name: 'specimen'});
        }
        if (this.cmdEnglish) {
            cmd.options.push({name: 'english'});
        }
        if (this.cmdCanton) {
            cmd.options.push({
                name: 'Canton',
                value: this.cmdCanton
            });
        }
        const id = this.getFormValue('id') as string;
        this.isLoading = true;
        this._swissdecService.viewgen(id, cmd)
            .subscribe({
                next: xml => {
                    this._toastService.success('swissdec_viewgen_success');
                },
                error: () => {
                    this._toastService.error('swissdec_viewgen_error');
                    this.isLoading = false;
                },
                complete: () => {
                    this.isLoading = false;
                }
            });
    }
}
