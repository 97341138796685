<div *ngIf="json">
    <h1>{{ 'swissdec.institution.result.title' | translate }}</h1>

    <h2>{{ 'swissdec.institution.result.addresseeContext.title' | translate }}</h2>
    <div>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.institutionName' | translate }}
            :</strong> {{ json.addresseeContext.institutionName }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.transmissionDate' | translate }}
            :</strong> {{ json.addresseeContext.transmissionDate | date:'medium' }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.responseID' | translate }}
            :</strong> {{ json.addresseeContext.responseID }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.requestID' | translate }}
            :</strong> {{ json.addresseeContext.requestID }}</p>
    </div>

    <h2>{{ 'swissdec.institution.result.institution.title' | translate }}</h2>
    <div>
        <p><strong>{{ 'swissdec.institution.result.institution.insuranceID' | translate }}
            :</strong> {{ json.institution.insuranceID }}</p>
        <p><strong>{{ 'swissdec.institution.result.institution.insuranceCompanyName' | translate }}
            :</strong> {{ json.institution.insuranceCompanyName }}</p>
        <p><strong>{{ 'swissdec.institution.result.institution.customerIdentity' | translate }}
            :</strong> {{ json.institution.customerIdentity }}</p>
        <p><strong>{{ 'swissdec.institution.result.institution.contractIdentity' | translate }}
            :</strong> {{ json.institution.contractIdentity }}</p>
        <p><strong>{{ 'swissdec.institution.result.institution.institutionID' | translate }}
            :</strong> {{ json.institution.institutionID }}</p>
        <p><strong>{{ 'swissdec.institution.result.institution.generalValidAsOf' | translate }}
            :</strong> {{ json.institution.generalValidAsOf | date:'medium' }}</p>
    </div>

    <h2>{{ 'swissdec.institution.result.changesConsideredUpTo' | translate }}</h2>
    <p>{{ json.changesConsideredUpTo | date:'medium' }}</p>

    <persons-institution-result-viewer [persons]="json?.staff?.person"></persons-institution-result-viewer>
    
</div>
