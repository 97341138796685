import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Ppcdf} from '@app/sam-base/models/placement';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const cdefixeTabsFormControls: TabFormControl<Ppcdf>[] = [
    {
        formControls: [
            'cdfId',
            'cdfstatus',
            'cliId',
            'datedebut',
            'webData',
            'region',
            'cliattId']
    },
    {
        title: 'general',
        formControls: [
            'refinterne',
            'refclient',
            'ageId',
            'secId',
            'conId',
            'noGestion',
            'dateCdf',
            'typecdf',
            'service',
            'resp',
            'tel',
            'fax',
            'natel',
            'salaire',
            'cddtPlace',
            'email',
            'satisfaite',
            'motifSat',
            'cdffrx']
    },
    {
        title: 'descremarques',
        formControls: [
            'note',
            'webData']
    },
    {
        title: 'matching',
        formControls: [
            'qualif',
            'qualifDescription',
            'needSummary',
            'region',
            'cliadrId']
    },
    {
        title: 'layout_menu_placement',
        formControls: []
    },
    {
        title: 'suivi',
        formControls: []
    },
    {
        title: 'docs',
        formControls: []
    }
];
