import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Sapar} from '@app/sam-base/models/salary/sapar';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const parametresGenerauxTabsFormControls: TabFormControl<Sapar>[] = [
    {
        formControls: ['annee']
    },
    {
        title: 'infoEntreprise',
        formControls: [
            'entname',
            'entdep',
            'entadr1',
            'entadr2',
            'entadr3',
            'entreg',
            'entcp',
            'entnpa',
            'entlieu',
            'entpays',
            'entofsnr']
    },
    {
        title: 'general',
        formControls: [
            'lieuSoc',
            'cantonSoc',
            'impComtra',
            'emailSalary',
            'smtpConfigId',
            'entityMultiWindow',
            'laaEmail',
            'pgmEmail']
    },
    {
        title: 'salaryType',
        formControls: [
            'gsSalBru',
            'gsTotDed',
            'gsSalNet',
            'gsTotRet',
            'gsSalNap',
            'gsSalNeg',
            'gsTotCoe',
            'gsNbH',
            'gsNbJ',
            'gsNbJavs']
    },
    {
        title: 'interfaceCompatibility',
        formControls: [
            'compteNap',
            'anNap',
            'cptaanobli']
    },
    {
        title: 'formats',
        formControls: [
            'bsfrx',
            'bsFrxEml'],
        tabType: 'printTemplate'
    },
    {
        title: 'paiement',
        formControls: [
            'mpaId1',
            'mpaId2',
            'mpaId3',
            'mpaId4',
            'mpaId8',
            'mpaId9',
            'lpayifnotcalc']
    },
    {
        title: 'horaires2',
        formControls: [
            'jParAn',
            'hParJ']
    },
    {
        title: 'options',
        formControls: [
            'libcatemp1',
            'libcatemp2',
            'libcatemp3',
            'libuserdat',
            'libuserda2',
            'libuserda3',
            'libusercha',
            'libuserch2',
            'libuserch3',
            'cat1Force',
            'cat2Force',
            'cat3Force']
    },
    {
        title: 'textes',
        formControls: [
            'usertext1',
            'usertext2',
            'usertext3']
    },
    {
        title: 'fiduciaire',
        formControls: [
            'fidname',
            'fidname2',
            'fidadr1',
            'fidadr2',
            'fidnpa',
            'fidlieu',
            'fidpays',
            'fidofsnr']
    },
    {
        title: 'swissdec_title',
        formControls: [
            'sdaccord',
            'sdentc']
    }];
