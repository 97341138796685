import {HttpClient} from '@angular/common/http';
import {Component, Input, OnDestroy, OnInit, Type} from '@angular/core';
import {
    BulkEntitySelectComponent
} from '@modules/sam-main/admin/components/bulk/bulk-entity-select/bulk-entity-select.component';
import {OperationType} from '@modules/sam-main/admin/models/operation.model';
import {environment} from '@root/environments/environment';
import {EsQueryStatement, Term, Terms} from '@sam-base/core';
import {IwGridColumn} from '@sam-base/models';
import {Gefacliview} from '@sam-base/models/invoices/gefacliview';

@Component({
    selector: 'iw-bulk-entity-select-invoices',
    templateUrl: './bulk-entity-select.component.html',
})
export class BulkEntitySelectInvoicesComponent extends BulkEntitySelectComponent<Gefacliview> implements OnInit, OnDestroy {
    public entity: Type<Gefacliview> = <any>Gefacliview;

    @Input()
    public operationType?: OperationType;

    constructor(private readonly http: HttpClient) {
        super();
    }

    public ngOnInit() {
        this.buildQueryStatements();
        this.setCustomColumns();
    }

    private buildQueryStatements() {
        const list: EsQueryStatement<Gefacliview>[] = [];
        list.push(EsQueryStatement.fromTerm(<Term<Gefacliview>>{type: 'gefacliview'}, 'filter'));
        if (this.operationType === OperationType.PUBLISH_INVOICE) {
            list.push(EsQueryStatement.fromTerm(<Term<Gefacliview>>{published: 'false'}, 'must'));
            this.statements = list;
        }
        if (this.operationType === OperationType.EMAIL_INVOICE) {
            this.http.get<string[]>(`${environment.backendURL}gefachdr/send-by-mail`).subscribe(facIds => {
                list.push(EsQueryStatement.fromTerms(<Terms<Gefacliview>>{facId: facIds}, 'must'));
                this.statements = list;
            });
        }
        if (this.operationType === OperationType.CREATE_REMINDER) {
            this.http.get<string[]>(`${environment.backendURL}gefachdr/create-rpl`).subscribe(facIds => {
                list.push(EsQueryStatement.fromTerms(<Terms<Gefacliview>>{facId: facIds}, 'must'));
                this.statements = list;
            });
        }
        if (this.operationType === OperationType.SEND_INVOICE) {
            this.http.get<string[]>(`${environment.backendURL}gefachdr/send-candidates`).subscribe(facIds => {
                list.push(EsQueryStatement.fromTerms(<Terms<Gefacliview>>{facId: facIds}, 'must'));
                this.statements = list;
            });
        }
    }

    private setCustomColumns() {
        let facNoColConf = this.operationType === OperationType.CREATE_REMINDER ? {
            name: 'norappel',
            prop: 'noRappel',
            colorMapper: (row: Gefacliview) => {
                return this.gefacliViewRappelColorMapper(row);
            },
        } : {
            name: 'facNo',
            prop: 'facNo',
        };

        this.columns = [
            facNoColConf as IwGridColumn<Gefacliview>,
            {
                name: 'cliNomFactureNC',
                prop: 'nom',
                type: 'string'
            },
            {
                prop: 'mntTtc',
                name: 'mntttc',
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'facornc',
                name: 'facornc',
                type: 'string'
            },

            {
                name: 'date_facture',
                prop: 'dateFac',
                type: 'date'
            },
            {
                name: 'fact_type',
                prop: 'facType',
                type: 'string'
            },
            {
                name: 'agence',
                prop: 'ageId',
                type: 'string'
            },
            {
                name: 'published',
                prop: 'published',
                type: 'boolean'
            },
            {
                name: 'facByEmail',
                prop: 'facByEmail',
                type: 'boolean'
            },
            {
                name: 'facEmail',
                prop: 'facEmail',
                type: 'string'
            },
        ];
    }

    private gefacliViewRappelColorMapper(row: Gefacliview): string {
        if (row.noRappel === 1) {
            return '#f3f300'
        }
        if (row.noRappel === 2) {
            return '#fa4c00';
        }
        if (row.noRappel || 0 >= 3) {
            return '#a00101';
        }

        return "#C0C0C0";
    }
}
