<form [formGroup]="formGroup"
      class="row">

    <div class="row col-xs-6 space-form separator-right">

        <div class="form-label col-xs-12"> {{ 'employe' | translate }}</div>
        <!-- Employe -->
        <iw-lookups-dropdown #cboEtatCivil
                             [isDisabled]="isReadonly"
                             [labelAlign]="'left'"
                             [label]="'etatCivilDate' | translate"
                             [lkname]="'ETATCIVIL'"
                             class="iw-input col-xs-9"
                             formControlName="etatcivil"
                             id="cbo-etatcivil"></iw-lookups-dropdown>

        <iw-date-picker #txtDatetatCiv
                        [isDisabled]="isReadonly"
                        class="iw-input col-xs-3"
                        formControlName="datetatciv"></iw-date-picker>


        <iw-pays-dropdown #cboNation
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'nationalite' | translate"
                          class="iw-input col-xs-10"
                          formControlName="nation"
                          id="cbo-nation"></iw-pays-dropdown>

        <iw-textfield #txtOrigine
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'origine' | translate"
                      class="iw-input col-xs-10"
                      formControlName="origine"></iw-textfield>

        <iw-textfield #txtLieunaiss
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'neA' | translate"
                      class="iw-input col-xs-10"
                      formControlName="lieunaiss"></iw-textfield>

        <iw-lookups-dropdown #cboPermis
                             [isDisabled]="isReadonly || !hasPermis"
                             [labelAlign]="'left'"
                             [label]="'permis' | translate"
                             [lkname]="'PERMIS'"
                             class="iw-input col-xs-9"
                             formControlName="permis"></iw-lookups-dropdown>

        <iw-textfield #txtpermis
                      [isDisabled]="true"
                      [value]="getFormValue('permis')"
                      class="iw-input col-xs-3"></iw-textfield>

        <iw-textfield #txtPermisno
                      [isDisabled]="isReadonly || !hasPermis"
                      [labelAlign]="'left'"
                      [label]="'noPermis' | translate"
                      class="iw-input col-xs-10"
                      formControlName="permisno"></iw-textfield>

        <iw-date-picker #txtPerm_dtde
                        [isDisabled]="isReadonly || !hasPermis"
                        [labelAlign]="'left'"
                        [label]="'validiteDuAu' | translate"
                        class="iw-input col-xs-7"
                        formControlName="permDtde"></iw-date-picker>

        <iw-date-picker #txtPerm_dta
                        [isDisabled]="isReadonly || !hasPermis"
                        [labelAlign]="'left'"
                        class="iw-input col-xs-3"
                        formControlName="permDta"></iw-date-picker>

        <iw-button #btnPermNote
                   (press)="txtPermNote.onDoubleClick()"
                   [isDisabled]="isReadonly"
                   class="iw-button col-xs-1"
                   iconClass="fa fa-angle-double-right"></iw-button>

        <!-- Hidden to give the #btnPermNote the same modal functionality
         as double click on a textarea -->
        <iw-textarea #txtPermNote
                     formControlName="permNote"
                     style="display: none;"></iw-textarea>

        <iw-textfield #txtNomjf
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'nomDeJeuneFille' | translate"
                      class="iw-input col-xs-10"
                      formControlName="nomjf"></iw-textfield>
        <!-- ! -->

        <div class="row col-xs-12 space-form separator-top"></div>


        <!-- Employe checkboxes -->
        <iw-checkbox #chkSoumisIS
                     [isDisabled]="isReadonly"
                     [label]="'soumisALimpotALaSource' | translate"
                     class="iw-input col-xs-6"
                     formControlName="soumisis"></iw-checkbox>

        <iw-checkbox #chkSoumisIS
                     [isDisabled]="isReadonly || !isMarried"
                     [label]="'conjischLabel' | translate"
                     class="iw-input col-xs-6"
                     formControlName="conjisch"></iw-checkbox>

        <iw-checkbox #chkRequerant
                     [isDisabled]="isReadonly || !hasPermis"
                     [label]="'requerant' | translate"
                     class="iw-input col-xs-5"
                     formControlName="requerant"></iw-checkbox>

        <iw-checkbox #chkLchomage
                     [isDisabled]="isReadonly"
                     [label]="'chomage' | translate"
                     class="iw-input col-xs-8"
                     formControlName="lchomage"></iw-checkbox>

        <iw-checkbox #chkAgi
                     [isDisabled]="isReadonly"
                     [label]="'attestationDeGainIntermediaire' | translate"
                     class="iw-input col-xs-7"
                     formControlName="agi"></iw-checkbox>

        <iw-button #btnAgi
                   (press)="openAgi()"
                   [isDisabled]="!isReadonly"
                   [text]="'agi' | translate"
                   class="iw-button col-xs-3"></iw-button>

        <iw-textfield #txtCaisseCho
                      [isDisabled]="isReadonly || !getFormValue('agi')"
                      [labelAlign]="'left'"
                      [label]="'caisseChomage' | translate"
                      class="iw-input col-xs-10"
                      formControlName="caissecho"></iw-textfield>

        <div class="row col-xs-12 space-form separator-top"></div>

        <iw-button #btnEnf
                   (press)="onBtnEnfentsClick()"
                   *ngIf="(isNew || !isValid) && !isReadonly"
                   [isDisabled]="isNew || !isValid"
                   [text]="'enfants' | translate"
                   [tooltip]="'save_first' | translate"
                   class="iw-button col-xs-3 flex-end"
                   style="margin-bottom: 4px;"></iw-button>
        <iw-button #btnEnfenb
                   (press)="onBtnEnfentsClick()"
                   *ngIf="(!isNew && isValid) || isReadonly"
                   [isDisabled]="isReadonly"
                   [text]="'enfants' | translate"
                   class="iw-button col-xs-3 flex-end"
                   style="margin-bottom: 4px;"></iw-button>

        <iw-textfield #txtEnf_nb
                      (valueChange)="setPaiementForcer($event) "
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'nb' | translate"
                      class="iw-input col-xs-3"
                      formControlName="enfnb"></iw-textfield>

        <iw-textfield #txtEnfnbcharg
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'aCharge' | translate"
                      class="iw-input col-xs-3"
                      formControlName="enfnbcharg"></iw-textfield>

        <iw-textfield #txtEnfnballoc
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'allocations' | translate"
                      class="iw-input col-xs-3"
                      formControlName="enfnballoc"></iw-textfield>

        <iw-textarea #edtEnfants
                     [height]="40"
                     [isDisabled]="isReadonly"
                     class="iw-textarea col-xs-12"></iw-textarea>

        <!-- ! -->
    </div>


    <!-- 2nd Column -->
    <div class="row col-xs-6 space-form">

        <!-- Single/Divorced checkboxes -->
        <div class="form-label col-xs-12 padding-top-5">
            {{ 'not_married_label' | translate }}
        </div>

        <iw-checkbox #chkEnf_menind
                     [isDisabled]="isReadonly || isMarried"
                     [label]="'noConcubinage' | translate"
                     class="iw-input col-xs-5"
                     formControlName="enfMenind"></iw-checkbox>

        <iw-checkbox #chkEnf_concub
                     [isDisabled]="isReadonly || isMarried"
                     [label]="'concubinage' | translate"
                     class="iw-input col-xs-5"
                     formControlName="enfConcub"></iw-checkbox>

        <iw-radio-list #concubChildOption
                       [isGroupDisabled]="isReadonly || notIsConcub"
                       [radioOptions]="concubinageTypeOptions"
                       formControlName="concubType"></iw-radio-list>


        <!-- ! -->

        <div class="col-xs-12 separator-top"></div>

        <!-- Conjoint -->
        <iw-button #btnConj
                   (press)="onBtnConjointClick()"
                   [isDisabled]="isReadonly || !isMarried"
                   [text]="'conjoint' | translate"
                   class="iw-button col-xs-4"></iw-button>

        <iw-textfield #txtConj_nom
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'nom' | translate"
                      class="iw-input col-xs-10"
                      formControlName="conjNom"></iw-textfield>

        <iw-textfield #txtConjprenom
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'prenom' | translate"
                      class="iw-input col-xs-10"
                      formControlName="conjprenom"></iw-textfield>

        <iw-date-picker #txtConj_naiss
                        [isDisabled]="true"
                        [labelAlign]="'left'"
                        [label]="'naissance' | translate"
                        class="iw-input col-xs-8"
                        formControlName="conjNaiss"></iw-date-picker>

        <iw-textfield #txtNoavs13
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'noAvs13' | translate"
                      [mask]="'000.0000.0000.00'"
                      class="iw-input col-xs-8"
                      formControlName="conjnoas13"></iw-textfield>

        <iw-checkbox #chkConj_work
                     [isDisabled]="true"
                     [label]="'leConjointTravaille' | translate"
                     class="iw-input col-xs-7"
                     formControlName="conjWork"></iw-checkbox>
        <iw-checkbox #chkConj_wkch
                     [isDisabled]="true"
                     [label]="'leConjointTravailleEnSuisse' | translate"
                     class="iw-input col-xs-7"
                     formControlName="conjWkch"></iw-checkbox>

        <div class="col-xs-12 separator-top"></div>

        <iw-checkbox #Standardlogic2
                     [isDisabled]="!hasenf"
                     [label]="'lemployeToucheLesAllocationsFamiliales' | translate"
                     class="iw-input col-xs-10"
                     formControlName="lallocs"></iw-checkbox>

        <iw-checkbox #Standardlogic3
                     [isDisabled]="!hasenf"
                     [label]="'leConjointToucheLesAllocationsFamiliales' | translate"
                     class="iw-input col-xs-10"
                     formControlName="lconjalloc"></iw-checkbox>

        <iw-textfield #txtNobenalloc
                      [isDisabled]="isReadonly || !getFormValue('lallocs')"
                      [labelAlign]="'left'"
                      [label]="'noBeneficiaireAllocations' | translate"
                      [size]="'large'"
                      class="iw-input col-xs-10"
                      formControlName="nobenalloc"></iw-textfield>

    </div>
</form>
