import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {IwGridColumn, ModalComponent} from '@app/sam-base/models';
import {PrintRequestService} from '@core/print/print-request.service';
import {
    PrintRequestManagerOptions
} from '@modules/sam-main/admin/components/print-request/print-request-manager.options';
import {PrintRequest, PrintRequestType} from '@modules/sam-main/admin/models/print-request.model';
import {IwRestGridComponent} from '@sam-base/components';
import {RestQueryParam} from '@sam-base/core';

@Component({
    templateUrl: './print-request-manager.component.html',
    styleUrls: ['./print-request-manager.component.scss']
})
export class PrintRequestManagerComponent<T> implements ModalComponent<string, PrintRequestManagerOptions>, OnInit {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public loading = false;
    @ViewChild('printRequestGrid') public grid?: IwRestGridComponent<PrintRequest>;
    public query: RestQueryParam<PrintRequest, string>[] = [];
    public columnsToShow: IwGridColumn<PrintRequest>[] = [
        {
            name: 'id',
            prop: 'id',
            type: 'number'
        },
        {
            name: 'type',
            prop: 'type',
            enumPrefix: 'print_request.type',
            valueFormat: 'print_request_type',
            // statusQuery: EntityStatusMap.getFilterConfigFrom('print_request_type', [PrintRequestType.INVOICE]),
            // filterOperator: SearchEntityStatusOperator.In,
            type: 'enum'
        },
        {
            name: 'approved',
            prop: 'approved',
            type: 'boolean'
        },
        {
            name: 'downloaded',
            prop: 'downloaded',
            type: 'boolean'
        },
        {
            name: 'downloadedAt',
            prop: 'downloadedAt',
            type: 'dateTime'
        }
    ];
    protected readonly typePrintRequest = PrintRequest;


    private _ok?: (result: string) => void;
    private _cancel?: (result?: undefined) => void;
    private selectedPrintRequest: PrintRequest[] = [];

    constructor(private readonly _printRequestService: PrintRequestService) {
    }

    private _printRequestType?: PrintRequestType;

    public set printRequestType(value: PrintRequestType | undefined) {
        this._printRequestType = value;
        if (value === undefined) {
            this.columnsToShow = [];
            this.query = [];
            return;
        }
        this.query = [
            // {
            //     operation: RestQueryOperation.Equals,
            //     prop: 'approved',
            //     value: 'false'
            // }
        ];
    }

    public ngOnInit(): void {
    }

    public setData(data: PrintRequestManagerOptions): Promise<void> {
        this.printRequestType = data.type;
        return Promise.resolve();
    }

    public registerOk(action: (result: string) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public onBeforeHide() {
        if (this._ok) {
            this._ok('');
        }
    }

    public downloadDocument() {
        this._printRequestService.download(this.selectedPrintRequest.map(e => e.id!)).subscribe(() => {
            this.grid?.refresh();
        });
    }

    public onSelection($event: PrintRequest[]) {
        this.selectedPrintRequest = $event;
    }
}
