import {Component, Input} from '@angular/core';
import {IwGridColumn, sortGridList, TableSortEvent} from '@sam-base/models';
import {FilterService} from '@sam-base/models/components/filtering.service';

@Component({
    templateUrl: './persons-institution-result-viewer.component.html',
    selector: 'persons-institution-result-viewer',
})
export class PersonsInstitutionResultViewerComponent {

    public filteredPersons: Person[] = [];
    public personGridColumns: IwGridColumn<Person>[] = [
        {
            name: 'empId',
            prop: 'employeeNumber',
            type: 'string',
        },
        {
            name: 'nom',
            prop: 'lastname',
            type: 'string',
        },
        {
            name: 'prenom',
            prop: 'firstname',
            type: 'string'
        },
        {
            name: 'info',
            prop: 'info',
            type: 'warning'
        },
        {
            name: 'warning',
            prop: 'warning',
            type: 'warning'
        },
        {
            name: 'taxAtSourceSalaries',
            prop: 'taxAtSourceSalaries',
            type: 'warning'
        }
    ];
    public selectedPerson?: Person;
    private _originalPersons: Person[] = [];
    private filterService = new FilterService<Person>();
    private tableSortEvent?: TableSortEvent<Person>;

    constructor() {
    }

    public get persons(): Person[] | undefined {
        return this._originalPersons;
    }

    @Input()
    public set persons(v: Person[]) {
        if (v) {
            this._originalPersons = v;
            this.filteredPersons = v;
        }
    }

    onApplyFilter($event: IwGridColumn<any>) {
        this.filterService.addFilter($event);
        this.filteredPersons = this.filterService.applyFilters(this._originalPersons);
    }

    onSort() {
        if (!this.tableSortEvent) return;
        this.filteredPersons = [...sortGridList(this.filteredPersons, this.tableSortEvent)];

    }

    onEmployeeClicked($event: Person) {
        this.selectedPerson = $event;
    }
}
