<button (click)="debug()">DEBUG</button>
<form [formGroup]="formGroup"
      class="row space-form">
    <iw-textfield #txtDecId
                  [isDisabled]="true"
                  [value]="id"
                  class="iw-input col-xs-4 no-padding"
                  id="id_employee"></iw-textfield>

    <iw-textfield #txt
                  [isDisabled]="true"
                  [value]="titleInfo"
                  class="iw-input col-xs-5 no-padding label-name"></iw-textfield>

    <iw-textfield #txtROProfession
                  [isDisabled]="true"
                  [value]="'enum.sxStatus.' + status | translate"
                  class="iw-input col-xs-3 no-padding"></iw-textfield>
    <div class="row">
        <iw-busy [isBusy]="isLoading"></iw-busy>
        <div class="row col-xs-12 no-padding-right"
             style="padding-left: 10%"></div>
    </div>
</form>

<iw-tab [(selectedTab)]="selectedTab"
        [invalidTabs]="invalidTabList"
        [tabs]="tabList">
    <iw-tab-sasx5dec-general *ngIf="selectedTab?.title === 'general'"
                             [formGroup]="formGroup"
                             [isNew]="isCreateMode"
                             [isReadonly]="!isWriteMode"></iw-tab-sasx5dec-general>
    <iw-tab-sasx5dec-employees *ngIf="selectedTab?.title === 'employees'"
                               [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"></iw-tab-sasx5dec-employees>
    <iw-tab-sasx5dec-institutions *ngIf="selectedTab?.title === 'institutions'"
                                  [formGroup]="formGroup"
                                  [isReadonly]="!isWriteMode"></iw-tab-sasx5dec-institutions>
    <iw-tab-sasx-xml *ngIf="selectedTab?.title === 'dataSent'"
                     [xmlContent]="xmlSent"></iw-tab-sasx-xml>
    <iw-tab-sasx-xml *ngIf="selectedTab?.title === 'dataReceived'"
                     [errorBundle]="errorBundle"
                     [notifications]="notifications"
                     [xmlContent]="xmlReceived"></iw-tab-sasx-xml>
    <iw-tab-sasx5dec-viewgen *ngIf="selectedTab?.title === 'viewgen'"
                             [formGroup]="formGroup"></iw-tab-sasx5dec-viewgen>

</iw-tab>
