import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {SwissdecTableComponent} from '@modules/sam-main/admin/components/swissdec/table/swissdec-table.component';
import {Sasx5dec} from '@sam-base/models/admin/sasx5dec.model';

export const SASX5DEC_LIST: FormDescription<Sasx5dec> = {
    form: SwissdecTableComponent,
    state: {
        ...buildDefaultState(Sasx5dec),
        diagModal: false,
        editMode: 'waiting',
        width: 1600
    }
};

