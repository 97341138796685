<div class="row">
    <iw-button (press)="addToClipboard(xmlContent)"
               [tooltip]="'copierDans' | translate"
               class="iw-button col-xs-1"
               iconClass="fas fa-copy"></iw-button>
    <iw-button (press)="openInTab(xmlContent)"
               [tooltip]="'open' | translate"
               class="iw-button col-xs-1"
               iconClass="fas fa-up-right-from-square"></iw-button>
</div>
<!--<div *ngIf="xmlContent"-->
<!--     class="row spaceForm">-->
<!--    <p-scrollPanel [style]="{width: '100%', height: '30vh'}">-->
<!--        <div class="form-host row col-xs-12">-->
<!--            <div class="xml-wrapper-simple">-->
<!--                <pre>{{ xmlContent }}</pre>-->
<!--            </div>-->
<!--        </div>-->
<!--    </p-scrollPanel>-->


<!--</div>-->
<div *ngIf="errorBundle"
     class="row">
    <p-scrollPanel [style]="{width: '100%', height: '30vh'}">
        <div [innerHTML]="formattedErrorMessage"
             class="iw-textarea col-xs-12"></div>
    </p-scrollPanel>
</div>

<ng-container *ngIf="notifications?.errors?.length > 0">
    <div class="row">
        <h4 class="col-xs-12 notification error">{{ 'errors' | translate }}</h4>
        <ul *ngFor="let notification of notifications?.errors">
            <li class="col-xs-12">
                {{ notification.qualityLevel | translate }} - {{ notification.description }}
                ({{ notification.descriptionCode }})
            </li>
        </ul>
    </div>
</ng-container>
<ng-container *ngIf="notifications?.warnings?.length > 0">
    <div class="row">
        <h4 class="col-xs-12 notification warn">{{ 'warnings' | translate }}</h4>
        <ul *ngFor="let notification of notifications?.warnings">
            <li class="col-xs-12">
                {{ notification.qualityLevel | translate }} - {{ notification.description }}
                ({{ notification.descriptionCode }})
            </li>
        </ul>
    </div>
</ng-container>
<ng-container *ngIf="notifications?.infos?.length > 0">
    <div class="row">
        <h4 class="col-xs-12 notification info">{{ 'information' | translate }}</h4>
        <ul *ngFor="let notification of notifications?.infos">
            <li class="col-xs-12">
                {{ notification.qualityLevel | translate }} - {{ notification.description }}
                ({{ notification.descriptionCode }})
            </li>
        </ul>
    </div>
</ng-container>

