<form [formGroup]="formGroup"
      class="row">

    <iw-textfield #txtMisarcnbj
                  [isDisabled]="isReadonly"
                  [labelAlign]="'top'"
                  [label]="'nbJoursArchivage' | translate"
                  class="iw-input col-xs-3"
                  decimals="0"
                  formControlName="misarcnbj"
                  type="number"></iw-textfield>
    <div class="col-xs-9"></div>
    <iw-checkbox [isDisabled]="isReadonly"
                 [label]="'activate_report_no' | translate"
                 class="col-xs-10 margin-top-20"
                 formControlName="rapnoref"></iw-checkbox>

    <iw-checkbox [isDisabled]="isReadonly"
                 [label]="'activate_auto_close_mission' | translate"
                 class="col-xs-10 margin-top-20"
                 formControlName="autocloseMission"></iw-checkbox>

    <iw-checkbox [isDisabled]="isReadonly"
                 [label]="'activate_force_cct_tempdata' | translate"
                 class="col-xs-10 margin-top-20"
                 formControlName="forceUseCctTempData"></iw-checkbox>

    <iw-checkbox [isDisabled]="isReadonly"
                 [label]="'forceLppMisDureeInd' | translate"
                 class="col-xs-10 margin-top-20"
                 formControlName="lppAutoDureeInd"></iw-checkbox>

</form>
