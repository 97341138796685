import {Component, Input, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Gecli} from '@app/sam-base/models/invoices';

@Component({
    selector: 'iw-client-tab-ij',
    templateUrl: './tab-client-ij.component.html'
})
export class TabClientIjComponent extends BasePartialFormComponent<Gecli> implements OnInit {

    @Input() public isReadonly = true;

    @Input() public entityId?: string;

    @Input() public isNew = false;

    public ngOnInit(): void {
    }
}

