<div class="row">
    <iw-button (press)="declareSalary()"
               [isDisabled]="declareSalaryDisabled"
               [text]="'declare_salary' | translate"
               class="btn-block"></iw-button>
</div>
<div class="row">
    <iw-button (press)="getDeclarationStatus()"
               [isDisabled]="getStatusDisabled"
               [text]="'get_declaration_status' | translate"
               class="btn-block"></iw-button>
</div>
<div class="row">
    <iw-button (press)="resetDeclaration()"
               *iwAccessGroups="[SamUserRole.SAM_ADMIN_GOD_MODE]"
               [text]="'reset' | translate"
               class="btn-block"></iw-button>
</div>