import {HttpClient} from '@angular/common/http';
import {Component, Input, OnDestroy, OnInit, Type, ViewChild} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {ContextMenuEvent, FormHandlerService, IwEventHubService, IwStoreService} from '@app/sam-base/core';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {EmployeeDeductionService, SaempdedSubscrition} from '@app/sam-base/core/services/employee-deduction-service';
import {SatblService} from '@app/sam-base/core/services/satbl.service';
import {ComboboxItem, RowClickEvent} from '@app/sam-base/models';
import {Ppwxdocs} from '@app/sam-base/models/placement';
import {Satbl} from '@app/sam-base/models/salary';
import {Sadedtx} from '@app/sam-base/models/salary/sadedtx';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {Saempdedview} from '@app/sam-base/models/salary/saempdedview';
import {Sapar} from '@app/sam-base/models/salary/sapar';
import {
    EmployeeDeductionData
} from '@modules/sam-main/salary/components/employee/tab-saemp-deductions/deductionEdit/employeeDeduction.component';
import {OperationEvents} from '@modules/sam-main/salary/events';
import {environment} from '@root/environments/environment';
import {Subject, takeUntil} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {SalaryForm} from '../../../salary.forms';

@Component({
    selector: 'iw-tab-saemp-deductions',
    templateUrl: './tab-saemp-deductions.component.html'
})
export class TabSaempDeductionsComponent extends BasePartialFormComponent<Saempc> implements OnInit, OnDestroy {
    @ViewChild('saempdedviewGrid') public saempdedviewGrid?: IwRestGridComponent<Saempdedview>;

    @Input() public sapar?: Sapar;
    public saempc?: Saempc;
    @Input() public uuid?: string;
    public saempdedview?: Saempdedview;
    public selectedRow?: Saempdedview;
    public type: Type<Saempdedview> = Saempdedview;
    public query: RestQueryParam<Saempdedview, string | number>[] = [];
    public subscritions = new Subject();
    // ComboBox
    public optionTableLPP: ComboboxItem<string>[] = [];
    public optionTableReq: ComboboxItem<number>[] = [];
    public optionTableCu1: ComboboxItem<string>[] = [];
    public optionTableCu2: ComboboxItem<string>[] = [];
    public optionTableCu3: ComboboxItem<string>[] = [];
    public optionTableCu4: ComboboxItem<string>[] = [];
    // Texfiled values
    public valueCot?: string;
    public valueCoe?: string;
    public valueAllCoe?: string;
    public valueD11?: string;
    public valueD12?: string;
    public valueD21?: string;
    public valueD22?: string;
    public valueD31?: string;
    public valueD32?: string;
    public valueD41?: string;
    public valueD42?: string;
    public soumisValue?: string;
    // Event
    public enableDropLpp = true;
    public enableDropLppH = true;
    public enableDropD1 = true;
    public enableDropD2 = true;
    public enableDropD3 = true;
    public enableDropD4 = true;
    public enableImpot = true;
    public enableSurete = true;
    public enableCct = true;
    public isDeductionSelected = true;
    public isFormDisabled = false;
    protected readonly ondblclick = ondblclick;
    private _baseUrl = ' ';
    private saempdedview2: Saempdedview = new Saempdedview();

    constructor(private readonly _http: HttpClient,
                private readonly _eventHubService: IwEventHubService<string>,
                private readonly _formHandler: FormHandlerService<SalaryForm>,
                private readonly _employeeDeductionService: EmployeeDeductionService,
                private readonly _store: IwStoreService, private readonly _satblService: SatblService) {
        super();
        this._baseUrl = environment.backendURL;

        this._eventHubService
            .forType<ContextMenuEvent<Ppwxdocs>>(OperationEvents.DELETE_DEDUCTION)
            .pipe(debounceTime(300))
            .subscribe(() => this.saempdedviewGrid?.refresh());

    }

    @Input()
    public set parentEntityId(v: string) {
        this.setQuery(v);
    }

    public get saempId() {
        return this.getFormValue<string>('empId');
    }

    public ngOnInit() {
        const empId: string = this.getFormValue('empId') || '';
        const empIdExists = empId !== undefined && empId !== '';
        this.isFormDisabled = !empIdExists;
        this._store.globalForm(this.uuid || '')
            .mode
            .pipe(takeUntil(this._subs))
            .subscribe((mode) => {
                this.isFormDisabled = mode === 'read';
            });

        if (empIdExists) {
            this.setQuery(empId);
            if (this.sapar?.annee) {
                this.getSadedtxOptions(this.sapar?.annee, 'LPP');
            }
            this._employeeDeductionService.sampededSaveSub
                .pipe(takeUntil(this.subscritions))
                .subscribe((data: SaempdedSubscrition) => {
                    if (this.uuid === data.uuid && data.saved) {
                        this.saempdedviewGrid?.refresh();
                    }
                });
            this.getSatblOptions('43', 0, this.optionTableReq);
            this.getSatblOptions('50', 0, this.optionTableCu1);
            this.getSatblOptions('51', 0, this.optionTableCu2);
            this.getSatblOptions('52', 0, this.optionTableCu3);
            this.getSatblOptions('53', 0, this.optionTableCu4);

            if (this.getFormValue('cantonimp')) {
                this.valueAllCoe = this.getFormValue('cantonimp') + ' \\ ' + this.getFormValue('baremImp') + ' \\ ' + this.getFormValue('modcalcimp') + ' \\ ' + this.getFormValue('tauxImp');
            }
        }
    }

    public openCreateDed(): void {
        this._formHandler.showFormDialog(SalaryForm.DedCreation, {
            empId: this.saempId,
            parentUUID: this.uuid
        });
    }

    public ngOnDestroy(): void {
        this.subscritions.next(undefined);
        this.subscritions.complete();
    }

    public getSelectedRow(event: Saempdedview[]) {
        this.saempdedview2 = event[0];
        if (event.length > 0) {
            this.isDeductionSelected = false;
        } else {
            this.isDeductionSelected = true;
        }
    }

    public openEmployeeDeduction() {
        const data: EmployeeDeductionData = {
            empId: this.saempId,
            dedCode: this.saempdedview2.dedCode,
            annee: this.sapar?.annee,
            soumisCode: this.saempdedview2.soumiscode,
            codeTaux: this.saempdedview2.txCode,
            codeTaux2: this.saempdedview2.txCode2,
            edId: this.saempdedview2.edId,
            cuscode1: this.saempdedview2.cuscode1,
            cuscode2: this.saempdedview2.cuscode2,
            key: `${this.sapar?.annee ?? ''}_${this.saempdedview2.dedCode ?? ''}_${this.saempdedview2.assCode ?? ''}_${this.saempdedview2.txCode ?? ''}`,
            txCode: this.saempdedview2.txCode,
            txCode2: this.saempdedview2.txCode2,
            txManuel: this.saempdedview2.txManuel,
            parentUUID: this.uuid,
            readOnly: this.isFormDisabled
        };
        this._formHandler.showFormDialog(SalaryForm.EmployeeDeductionInfo, data);
    }

    public onDblclickEmployeeDeduction($event: RowClickEvent<Saempdedview>) {
        this.saempdedview2 = $event.row;
        this.openEmployeeDeduction();
    }

    private getSadedtxOptions(annee: string, dedCode: string) {
        return this._http
            .get<Sadedtx>(this._baseUrl + `sadedtx/${annee}/${dedCode}`)
            .subscribe((res: any) => {
                for (const k of Object.keys(res)) {
                    this.optionTableLPP.push({
                        name: `${res[k].txCode} | ${res[k].titre} | ${res[k].txcot} | ${res[k].txcoe}`,
                        value: res[k].txCode
                    });
                }
                this.getLppvalues();
            });
    }

    private getSatblOptions(tableNo: string, recType: number, optionType: ComboboxItem<number | string>[]) {
        return this._satblService.getSatblOptions(tableNo, recType)
            .subscribe((res: Satbl[]) => {
                res.forEach(satbl => optionType.push({
                    name: `${satbl.elemId} | ${satbl.titre} | ${satbl.nvalue1} | ${satbl.nvalue2}`,
                    value: satbl.elemId || ''
                }));
                this.getD1values(this.optionTableCu1, 'tableCu1');
                this.getD2values(this.optionTableCu2, 'tableCu2');
                this.getD3values(this.optionTableCu3, 'tableCu3');
                this.getD4values(this.optionTableCu4, 'tableCu4');
            });
    }

    private getLppvalues() {
        const value = this.optionTableLPP.find((element) => element.value === this.getFormValue('tableLpp'))?.name;
        this.valueCot = this.callRegex1(value ?? '');
        this.valueCoe = this.callRegex2(value ?? '');
    }

    private getD1values(optionType: ComboboxItem<string>[], formName: keyof Saempc) {
        const value = optionType.find((element) => element.value === this.getFormValue(formName))?.name;
        this.valueD11 = this.callRegex1(value ?? '');
        this.valueD12 = this.callRegex2(value ?? '');
    }

    private getD2values(optionType: ComboboxItem<string>[], formName: keyof Saempc) {
        const value = optionType.find((element) => element.value === this.getFormValue(formName))?.name;
        this.valueD21 = this.callRegex1(value ?? '');
        this.valueD22 = this.callRegex2(value ?? '');
    }

    private getD3values(optionType: ComboboxItem<string>[], formName: keyof Saempc) {
        const value = optionType.find((element) => element.value === this.getFormValue(formName))?.name;
        this.valueD31 = this.callRegex1(value ?? '');
        this.valueD32 = this.callRegex2(value ?? '');
    }

    private getD4values(optionType: ComboboxItem<string>[], formName: keyof Saempc) {
        const value = optionType.find((element) => element.value === this.getFormValue(formName))?.name;
        this.valueD41 = this.callRegex1(value ?? '');
        this.valueD42 = this.callRegex2(value ?? '');
    }

    private callRegex1(str: string) {
        return str?.replace(/(?:[^|]*\|){2} | (\|\s[0-9.]+$)/g, '');
    }

    private callRegex2(str: string) {
        return str?.replace(/(?:[^|]*\|){3} | (\|\s[0-9.]+$)/g, '');
    }

    private setQuery(empId: string) {
        this.query = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'empId',
                value: empId
            }];
    }
}
