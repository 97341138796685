import {HttpClient} from '@angular/common/http';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {MessageFormRefreshService} from '@app/sam-base/components/subjects/message-service-subject.service';
import {FormHandlerService} from '@app/sam-base/core';
import {IwStoreService} from '@app/sam-base/core/store';
import {ToastService} from '@app/sam-base/core/toast';
import {FormKeys, TT6Ges} from '@app/sam-base/models';
import {AdminForm} from '@modules/sam-main/admin/admin.forms';
import {Localetypes} from '@modules/sam-main/admin/components/sam-user-admin/locale-enums';
import {NewElem} from '@modules/sam-main/admin/components/tt6mail/tt6mail-models';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {SamUserService} from '@sam-base/core/services/sam-user.service';
import {SamUser, SamUserRole} from '@sam-base/models/admin/sam-user';
import {ProfileService} from '@shared/profile/profile.service';
import {ListItem} from '@shared/widgets/form-components/list-box/list-item.model';
import {lastValueFrom, Observable, Subject} from 'rxjs';

@Component({
    templateUrl: './sam-user-admin.component.html'
})
export class SamUserAdminComponent extends BaseStoreFormComponent<SamUser> implements OnInit, OnDestroy {

    public username?: string;
    public lastName?: string;
    public firstName?: string;
    public tenant?: string;
    public email?: string;
    public locale?: string;
    public gestions?: TT6Ges[];
    public usernameDisabled = false;
    public updateDisabled = false;
    public createDisabled = false;
    public deleteDisabled = false;
    public closeDisabled = false;
    public isUpdate = false;
    public delete = false;
    public isLoading = false;
    public password?: string;
    public confirmPassword?: string;
    public availableGestions: TT6Ges[] = [];
    public options = Localetypes;
    public allRoles = Object.values(SamUserRole) as SamUserRole[];
    public availableRoles: ListItem<SamUserRole>[] = [];
    public actualRoles: ListItem<SamUserRole>[] = [];
    private subscriptions = new Subject();

    constructor(store: IwStoreService,
                private readonly _profileService: ProfileService,
                private readonly _http: HttpClient,
                private readonly _translateService: TranslateService,
                private readonly _samUserService: SamUserService,
                private readonly refreshFormMessageService: MessageFormRefreshService,
                private readonly _toast: ToastService, protected readonly _store: IwStoreService,
                private readonly _formHandler: FormHandlerService<AdminForm>) {
        super(store);
    }

    @Input()
    public set entityId(v: string | undefined) {
        if (v) {
            this.setEntityId(v);
        }
    }

    public get id() {
        return this.getFormValue('id') || '';
    }

    public get isFormValid(): boolean {
        return this.formGroup.valid;
    }

    public get userExists(): boolean {
        return !!this.getFormValue('id');
    }

    public matchSourceName = (e: TT6Ges) => `${e.libgestion} (${e.nogestion})`;

    public async ngOnInit() {

        this.availableGestions = await lastValueFrom(this.getGestions());
        const samUserData: SamUser | undefined = this.getData('row');
        if (samUserData) {
            this.isUpdate = true;
            this.setFormValue('id', samUserData.id);
            this.usernameDisabled = true;
            this.refreshFormMessageService.sendMessage('sam_user.table');
            this._samUserService.getSamUser(samUserData.id!)
                .subscribe(samUser => {
                    this.setFormValues(samUser);
                    // copy samUser.roles to this.actualRoles
                    this.actualRoles = samUser.roles?.map(role => ({
                        label: this._translateService.instant('sam_user_role.' + role),
                        value: role
                    })) || [];
                    // init availableRoles with SamRoles enum but not those that are present in actualRoles
                    this.availableRoles = this.allRoles.filter(role => !this.actualRoles.some(usrRole => usrRole.value === role)).map(val => ({
                        label: this._translateService.instant('sam_user_role.' + val),
                        value: val
                    }));
                    if (!this._profileService.checkUserPermission([SamUserRole.SAM_ADMIN_GOD_MODE])) {
                        this.availableRoles = this.availableRoles.filter(role => role.value !== SamUserRole.SAM_ADMIN_GOD_MODE);
                    }
                });
        } else {
            this.isUpdate = false;
            this.availableRoles = this.allRoles.map(val => ({
                label: this._translateService.instant('sam_user_role.' + val),
                value: val
            }));
            this._profileService.getTenant().then(tenant => this.setFormValue('tenant', tenant));
        }

        this.syncFormValueValidation();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public saveSamUser() {
        this.createDisabled = true;
        this.isLoading = true;
        const body = this.createPayload();

        if (body.password !== body.confirmPassword) {
            this._toast.error('passwords_nomatch');
            this.isLoading = false;
            this.createDisabled = false;
            return;
        }
        return this._samUserService.createSamUser(body)
            .subscribe(samUser => {
                this.setFormValues(samUser);
                this.usernameDisabled = true;
                this.isUpdate = true;
                this._toast.success('user_create_success');
                this.refreshFormMessageService.sendMessage('sam-user.table');
                this.isLoading = false;
            }, (err) => {
                this._toast.error('user_create_error');
                this.isLoading = false;
                this.createDisabled = false;
            });
    }

    public updateSamUser() {
        this.isLoading = true;
        this.updateDisabled = true;
        this.deleteDisabled = true;
        this.closeDisabled = true;
        const body = this.createPayload();
        return this._samUserService.updateSamUser(this.id, body)
            .subscribe(samUser => {
                this.setFormValues(samUser);
                this.updateDisabled = false;
                this.deleteDisabled = false;
                this.closeDisabled = false;
                this._toast.success('user_update_success');
                this.refreshFormMessageService.sendMessage('sam-user.table');
                this.isLoading = false;
                this.isUpdate = true;
            }, (err) => {
                this._toast.error('user_update_error');
                this.isLoading = false;
                this.closeDisabled = false;
            });
    }

    public deleteSamUser() {
        this.isLoading = true;
        this.deleteDisabled = true;
        return this._samUserService.deleteSamUser(this.id)
            .subscribe(() => {
                this._toast.success('user_delete_success');
                this.refreshFormMessageService.sendMessage('sam-user.table');
                this.ngOnDestroy();
                this.destroyForm();
                this.isLoading = false;
            }, (err) => {
                this._toast.error('user_delete_error');
                this.isLoading = false;
            });
    }

    public resetPwdSamUser() {
        this._formHandler.showFormDialog(AdminForm.ResetUserPassword, this.createPayload(), s => ({
            ...s,
            diagModal: true,
            diagTitle: 'title_resetsamUser_form',
            width: 480,
            height: 900
        }));
    }

    public matchSelectedEntries(values: TT6Ges[], v: TT6Ges): boolean {
        return values.some(e => e.nogestion?.toLowerCase() === v.nogestion?.toLowerCase());
    }


    public onValueUpdated($event: (NewElem | TT6Ges)[]) {
        this.gestions = $event as TT6Ges[];
    }

    public onSecondListChange(roles: ListItem<SamUserRole>[]) {
        this.setFormValue('roles', roles.map(e => e.value));
    }

    public debug() {
        console.log('this.formGroup', this.formGroup);
        Object.keys(this.formGroup.controls).forEach(key => {
            const control = this.formGroup.controls[key];
            if (control.invalid) {
                console.log(`Invalid control: ${key}`);
            }
        });
        console.log("payload", this.createPayload())
    }

    protected getFormControlNames(): FormKeys<SamUser> {
        return [
            'id',
            [
                'username',
                new UntypedFormControl(undefined, Validators.required)],
            'userId',
            'tenant',
            [
                'lastName',
                new UntypedFormControl(undefined, Validators.required)],
            [
                'firstName',
                new UntypedFormControl(undefined, Validators.required)],
            [
                'email',
                new UntypedFormControl(undefined, [
                    Validators.email,
                    Validators.required])],
            'gestions',
            [
                'locale',
                new UntypedFormControl(undefined, Validators.required)],
            'roles',
            'password',
            'confirmPassword'];
    }

    protected getValidationType() {
        return SamUser;
    }

    private setEntityId(v: string) {
        if (v !== this.id) {
            this.setFormValue('id', v);
        }
    }

    private setFormValues(userDt: SamUser) {
        const userData: SamUser = this.createUserFromData(userDt);
        this.setFormValue('id', userData.id);
        this.setFormValue('username', userData.username);
        this.setFormValue('userId', userData.userId);
        this.setFormValue('tenant', userData.tenant);
        this.setFormValue('email', userData.email);
        this.setFormValue('firstName', userData.firstName);
        this.setFormValue('lastName', userData.lastName);
        this.setFormValue('locale', userData.locale);
        this.setFormValue('roles', userData.roles);
        this.locale = userData.locale;
        this.gestions = this.availableGestions.filter(g => userData.gestions?.includes(g.nogestion!));
    }

    private createUserFromData(userData: SamUser) {
        const user: SamUser = new SamUser();

        user.id = userData.id;
        user.username = userData.username;
        user.userId = userData.userId;
        user.tenant = userData.tenant;
        user.email = userData.email;
        user.firstName = userData.firstName;
        user.lastName = userData.lastName;
        user.locale = userData.locale;
        user.gestions = userData.gestions;
        user.roles = userData.roles;
        return user;
    }

    private createPayload() {
        const user: SamUser = new SamUser();
        user.id = this.getFormValue('id');
        user.username = this.getFormValue('username');
        user.userId = this.getFormValue('userId');
        user.tenant = this.getTenantValue();
        user.email = this.getFormValue('email');
        user.firstName = this.getFormValue('firstName');
        user.lastName = this.getFormValue('lastName');
        user.locale = this.getFormValue('locale');
        user.gestions = this.gestions?.map(g => g.nogestion!);
        user.password = this.getFormValue('password');
        user.confirmPassword = this.getFormValue('confirmPassword');
        user.roles = this.getFormValue('roles');
        return user;
    }

    private getTenantValue() {
        const ten = this.getFormValue('tenant');
        let outputTenant: string;
        if (Array.isArray(ten)) {
            outputTenant = ten[0];
        } else {
            outputTenant = ten;
        }
        return outputTenant;
    }

    private getGestions(): Observable<TT6Ges[]> {
        return this._http.get<TT6Ges[]>(environment.backendURL + 'tenant/gestions');
    }

    private syncFormValueValidation() {
        this.getFormControl('email')?.setValidators([Validators.pattern("(([a-zA-Z0-9]+(?:[._+-][a-zA-Z0-9]+)*)@([a-zA-Z0-9]+(?:[.-][a-zA-Z0-9]+)*[.][a-zA-Z]{2,}))|(^\\s*$)")]);
        if (this.isNew) {
            this.getFormControl('password')?.setValidators([Validators.required]);
            this.getFormControl('confirmPassword')?.setValidators([Validators.required]);
        } else {
            this.getFormControl('password')?.clearValidators();
            this.getFormControl('confirmPassword')?.clearValidators();
        }
    }
}
