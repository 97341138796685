<form [formGroup]="formGroup"
      class="row spaceForm">
    <div class="col-xs-12">
        <h2>{{ 'institutions' | translate }}</h2>
        <h3>{{ subtitle }}</h3>
        <h4 [class]="institutionState">{{ institutionState | translate }}</h4>

        <div class="row">
            <p-scrollPanel *ngIf="!!resultJson"
                           [style]="{width: '100%', height: '30vh'}"
                           class="col-xs-12">
                <lpp-institution-result-viewer *ngIf="domain === 'LPP'"
                                               [json]="resultJson"></lpp-institution-result-viewer>
                <tax-institution-result-viewer *ngIf="domain === 'TAX'"
                                               [json]="resultJson"></tax-institution-result-viewer>
                <tax-at-source-institution-result-viewer *ngIf="domain === 'IMP'"
                                                         [json]="resultJson"></tax-at-source-institution-result-viewer>
                <avs-institution-result-viewer *ngIf="domain === 'AVS'"
                                               [json]="resultJson"></avs-institution-result-viewer>
                <caf-institution-result-viewer *ngIf="domain === 'CAF'"
                                               [json]="resultJson"></caf-institution-result-viewer>
                <tax-crossborder-institution-result-viewer *ngIf="domain === 'TXB'"
                                                           [json]="resultJson"></tax-crossborder-institution-result-viewer>
                <statistics-institution-result-viewer *ngIf="domain === 'STATISTICS'"
                                                      [json]="resultJson"></statistics-institution-result-viewer>
                <base-institution-result-viewer *ngIf="domain === 'LAC' || domain === 'IJM' || domain === 'LAA'"
                                                [json]="resultJson"></base-institution-result-viewer>
                <!--                <pre>{{ resultJsonParsed | json }}</pre>-->
            </p-scrollPanel>

        </div>
        <p-scrollPanel *ngIf="hasNotificationToShow && !resultJson"
                       [style]="{width: '100%', height: '30vh'}">
            <ng-container *ngIf="notifications?.errors?.length > 0">
                <div class="row">
                    <h4 class="col-xs-12 notification error">{{ 'swissdec.notification.error_title' | translate }}</h4>
                    <ul *ngFor="let notification of notifications?.errors">
                        <li class="col-xs-12">
                            {{ notification.qualityLevel | translate }} - {{ notification.description }}
                            ({{ notification.descriptionCode }})
                        </li>
                    </ul>
                </div>
            </ng-container>
            <ng-container *ngIf="notifications?.warnings?.length > 0">
                <div class="row">
                    <h4 class="col-xs-12 notification warn">{{ 'swissdec.notification.warn_title' | translate }}</h4>
                    <ul *ngFor="let notification of notifications?.warnings">
                        <li class="col-xs-12">
                            {{ notification.qualityLevel | translate }} - {{ notification.description }}
                            ({{ notification.descriptionCode }})
                        </li>
                    </ul>
                </div>
            </ng-container>
            <ng-container *ngIf="notifications?.infos?.length > 0">
                <div class="row">
                    <h4 class="col-xs-12 notification info">{{ 'swissdec.notification.info_title' | translate }}</h4>
                    <ul *ngFor="let notification of notifications?.infos">
                        <li class="col-xs-12">
                            {{ notification.qualityLevel | translate }} - {{ notification.description }}
                            ({{ notification.descriptionCode }})
                        </li>
                    </ul>
                </div>
            </ng-container>
        </p-scrollPanel>

        <div *ngIf="institutionState === 'PROCESSING'"
             class="row">
            <iw-date-picker #txtDateFrom
                            [isDisabled]="true"
                            [labelAlign]="'top'"
                            [label]="'expected_to_be_done_for'"
                            [value]="expectedAvailability"
                            class="iw-input col-xs-7"></iw-date-picker>

        </div>

        <!--  Show completion URL  -->
        <div *ngIf="showCompletionUrl"
             class="row">
            <div class="col-xs-12">
                <h4>Completion URL</h4>
            </div>
            <div class="col-xs-12">
                <p class="row">
                    {{ 'completion_url_process_description' | translate }} </p>

                <div class="row">
                    <a [href]="completionUrl"
                       target="_blank">{{ 'completion_url' | translate }}</a>
                </div>
                <div class="row">
                    <iw-textfield [isDisabled]="true"
                                  [label]="'login' | translate"
                                  [tooltipPosition]="'right'"
                                  [tooltip]="'login' | translate"
                                  [value]="login"
                                  class="col-xs-12"
                                  labelAlign="top"
                                  size="small"></iw-textfield>

                    <iw-textfield [isDisabled]="true"
                                  [label]="'password' | translate"
                                  [tooltipPosition]="'right'"
                                  [tooltip]="'password' | translate"
                                  [value]="password"
                                  class="col-xs-12"
                                  labelAlign="top"
                                  size="small"></iw-textfield>
                </div>
            </div>

        </div>

        <div *ngIf="isProcessFlowDialog"
             class="col-xs-12">
            <div class="row">
                <p class="col-xs-12">
                    {{ 'completion_dialog_process_description' | translate }} </p>
            </div>
        </div>
        <div *ngIf="isProcessFlowResult"
             class="col-xs-12">
            <div class="row">
                <p class="col-xs-12">
                    {{ 'institution_result_description' | translate }} </p>
            </div>
        </div>
    </div>

</form>
