import {Component, EventEmitter, OnDestroy} from '@angular/core';
import {AgencyHandlerService, ModalService, RestApiService, RestEntityClient} from '@app/sam-base/core';
import {ToastService} from '@app/sam-base/core/toast';
import {ComboboxItem, HtmlElementStatus, ModalComponent} from '@app/sam-base/models';
import {
    EmployeeDeclarationEditOptions,
    EmployeeDeclarationSplit
} from '@modules/sam-main/admin/components/swissdec/tab-sasx5dec-employees/employee-declaration-edit/employee-declaration-edit.options';
import {TranslateService} from '@ngx-translate/core';
import {EmployeeDeclaration} from '@sam-base/models/admin/employee-declaration.model';
import {GesaexeView} from '@sam-base/models/invoices/gesaexeview';
import {lastValueFrom, of, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

function strToCbp(lst: string[]): ComboboxItem<string>[] {
    return lst.map(e => (<ComboboxItem<string>>{
        value: e,
        name: e
    }));
}

@Component({
    selector: 'iw-employee-declaration-type-edit',
    templateUrl: './employee-declaration-edit.component.html'
})
export class EmployeeDeclarationEditComponent implements ModalComponent<EmployeeDeclaration, EmployeeDeclarationEditOptions>, OnDestroy {
    public anneesSuggestions: ComboboxItem<string>[] = [];
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public employeeDeclaration: EmployeeDeclaration = new EmployeeDeclaration();
    public splits: EmployeeDeclarationSplit[] = [];
    public btnOk: HtmlElementStatus = {
        enabled: true,
        value: 'enregistrer',
        visible: true
    };
    private _options!: EmployeeDeclarationEditOptions;
    private _ok?: (result: EmployeeDeclaration) => void;
    private _cancel?: (error?: any) => void;
    private readonly _restGesaexeClient: RestEntityClient<GesaexeView>;
    private subscriptions = new Subject();

    constructor(private _restService: RestApiService, private readonly _agencyHandlerService: AgencyHandlerService,
                private readonly _modalService: ModalService, private _translate: TranslateService,
                private _toastService: ToastService) {
        this._restGesaexeClient = _restService.getEntityClient(GesaexeView);
    }

    public get employeeDeclarationId() {
        return this._options.employeeDeclarationId;
    }

    public get userAvsSplitTotalAmount(): number {
        return this.splits
            .reduce((total, splitObj) => total + (Number(splitObj.amount) || 0), 0);
    }

    public get diffUserAvsSplitTotalAmount(): number {
        return Number(this.employeeDeclaration.avsIncome) - this.userAvsSplitTotalAmount;
    }

    public get isReadOnly(): boolean {
        return this._options.readonly ?? true;
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public onPressOk() {
        this.updateSplitYearAvsIncome();
        const isOkButtonEnabled = this.updateBtnOkValidation();
        if (!isOkButtonEnabled) {
            this._toastService.warning('employee_declaration_edit_disabled');
            return;
        }
        if (this._ok && this.employeeDeclaration) {
            this._ok(this.employeeDeclaration);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public async setData(data: EmployeeDeclarationEditOptions): Promise<void> {
        this._options = data;
        this.employeeDeclaration = await lastValueFrom(this._restService
            .getEntityClient(EmployeeDeclaration)
            .getById(data.employeeDeclarationId));
        this.updateSplitsFromEmployeeDeclaration(this.employeeDeclaration);
        this.getAnnees()
            .subscribe(annees => this.anneesSuggestions = annees);
    }

    public registerOk(action: (result: EmployeeDeclaration) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public getAnnees = () => this._restGesaexeClient.getFilterList({
        page: '0',
        size: '1000',
        sort: 'cannee'
    })
        .pipe(map(result => result.map(e => e.cannee || '')), map(annees => {
            const anneesCbp = strToCbp(annees);
            const currentYear = this.anneesSuggestions
                .find(annee => annee.value === this._options.year);
            if (currentYear) {
                currentYear.disabled = true;
            }
            return anneesCbp;
        }));

    public annees$ = () => {
        // Get an array of used years from splits
        const usedYears = this.splits.map(split => split.year);

        // Filter out the used years from anneesSuggestions
        return of(this.anneesSuggestions.filter(anneeCombo => !usedYears.includes(anneeCombo.value)));
    };

    public addSplit() {
        let minYear = Number(this.splits[0].year) || 0;

        // Find the lowest year
        this.splits.forEach(split => {
            const currentYear = Number(split.year);
            if (currentYear < minYear) {
                minYear = currentYear;
            }
        });

        // Create a new split with the lowest year - 1
        const newSplit = new EmployeeDeclarationSplit();
        newSplit.year = `${minYear - 1}`;

        // Add the new split to the splits
        this.splits.push(newSplit);
        this.updateBtnOkValidation();
    }

    public onIncomeSplitChange() {
        setTimeout(() => {
            this.updateBtnOkValidation();
        }, 0);
    }

    public onSplitDateChange() {
        setTimeout(() => {
            this.updateBtnOkValidation();
        }, 0);
    }

    public removeSplit(split: EmployeeDeclarationSplit) {
        const splitIndex = this.splits.findIndex(splitIt => splitIt.year === split.year);
        if (splitIndex !== -1) {
            this.splits.splice(splitIndex, 1);
            this.updateBtnOkValidation();
        }
    }

    private updateSplitsFromEmployeeDeclaration(employeeDeclaration: EmployeeDeclaration) {

        if (employeeDeclaration.splitYearAvsIncome) {

            this.splits = employeeDeclaration.splitYearAvsIncome.split(';')
                .map(split => {
                    const [year, amount] = split.split('|');
                    const employeeSplit: EmployeeDeclarationSplit = new EmployeeDeclarationSplit();
                    employeeSplit.year = year;
                    employeeSplit.amount = parseFloat(amount);
                    return employeeSplit;
                });
            this.splits.sort((a, b) => Number(b.year) - Number(a.year));
            console.log('this.splits', this.splits);
        }
    }

    private updateBtnOkValidation(): boolean {
        this.btnOk.enabled = this.diffUserAvsSplitTotalAmount === 0 || !!this.employeeDeclaration.additionalDeliveryDate;
        return this.btnOk.enabled;
    }

    private updateSplitYearAvsIncome() {
        const splitsArrayString: string[] = [];
        this.splits.forEach(split => {
            splitsArrayString.push(`${split.year}|${split.amount}`);
        });
        this.employeeDeclaration.splitYearAvsIncome = splitsArrayString.join(';');
    }
}
