<div *ngIf="json">
    <h1>{{ 'swissdec.institution.result.title' | translate }}</h1>

    <h2>{{ 'swissdec.institution.result.addresseeContext.title' | translate }}</h2>
    <div>
        <h3>{{ 'swissdec.institution.result.addresseeContext.userAgent.title' | translate }}</h3>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.userAgent.producer' | translate }}
            :</strong> {{ json.addresseeContext.userAgent.producer }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.userAgent.name' | translate }}
            :</strong> {{ json.addresseeContext.userAgent.name }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.userAgent.version' | translate }}
            :</strong> {{ json.addresseeContext.userAgent.version }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.userAgent.standardVersion' | translate }}
            :</strong> {{ json.addresseeContext.userAgent.standardVersion }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.userAgent.certificate' | translate }}
            :</strong> {{ json.addresseeContext.userAgent.certificate }}</p>

        <p><strong>{{ 'swissdec.institution.result.addresseeContext.institutionName' | translate }}
            :</strong> {{ json.addresseeContext.institutionName }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.transmissionDate' | translate }}
            :</strong> {{ json.addresseeContext.transmissionDate | date:'medium' }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.responseID' | translate }}
            :</strong> {{ json.addresseeContext.responseID }}</p>
        <p><strong>{{ 'swissdec.institution.result.addresseeContext.requestID' | translate }}
            :</strong> {{ json.addresseeContext.requestID }}</p>
    </div>

    <p><strong>{{ 'swissdec.institution.result.process' | translate }}:</strong> {{ json.process }}</p>

    <h2>{{ 'swissdec.institution.result.company.title' | translate }}</h2>
    <div>
        <h3>{{ 'swissdec.institution.result.company.name.title' | translate }}</h3>
        <p><strong>{{ 'swissdec.institution.result.company.name.hrrcname' | translate }}
            :</strong> {{ json.companyDescription.name.hrrcname }}</p>

        <h3>{{ 'swissdec.institution.result.company.address.title' | translate }}</h3>
        <p><strong>{{ 'swissdec.institution.result.company.address.city' | translate }}
            :</strong> {{ json.companyDescription.address.city }}</p>
        <p><strong>{{ 'swissdec.institution.result.company.address.zipcode' | translate }}
            :</strong> {{ json.companyDescription.address.zipcode }}</p>
    </div>

    <h2>{{ 'swissdec.institution.result.workplaces.title' | translate }}</h2>
    <div *ngFor="let workplace of json.companyDescription.workplace; let i = index">
        <h3>{{ 'swissdec.institution.result.workplaces.workplace' | translate }} {{ i + 1 }}</h3>
        <p><strong>{{ 'swissdec.institution.result.workplaces.id' | translate }}:</strong> {{ workplace.workplaceID }}
        </p>

        <h4>{{ 'swissdec.institution.result.workplaces.address.title' | translate }}</h4>
        <p><strong>{{ 'swissdec.institution.result.workplaces.address.city' | translate }}
            :</strong> {{ workplace.addressExtended.city }}</p>
        <p><strong>{{ 'swissdec.institution.result.workplaces.address.zipcode' | translate }}
            :</strong> {{ workplace.addressExtended.zipcode }}</p>

        <div *ngFor="let workingTime of workplace.companyWorkingTime">
            <h4>{{ 'swissdec.institution.result.workplaces.workingTime.title' | translate }}</h4>
            <p><strong>{{ 'swissdec.institution.result.workplaces.workingTime.weeklyHours' | translate }}
                :</strong> {{ workingTime.weeklyHours.value }}</p>
            <p><strong>{{ 'swissdec.institution.result.workplaces.workingTime.id' | translate }}
                :</strong> {{ workingTime.weeklyHours.companyWeeklyHoursID }}</p>
        </div>
    </div>
</div>