import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';
import {BusinessExceptionErrors} from '@sam-base/core/auth/interceptors/error.model';

@IwRestEntity('employeeDeclaration', '', 'common')
export class EmployeeDeclaration {
    @IwRestEntityId() public id?: string;
    public declarationId?: string;
    @IwColumn({
        index: 0,
        width: 5
    }) public gestion?: string;
    public empId?: string;
    @IwColumn({
        index: 1,
        width: 20
    }) public nom?: string;
    @IwColumn({
        index: 2,
        width: 20
    }) public prenom?: string;
    public isSelected?: boolean;
    public originalDate?: string;
    public originalDocId?: string;
    public newDocId?: string;
    public additionalDeliveryDate?: string;
    public remarks?: string;
    public avsIncome?: number;
    public splitYearAvsIncome?: string;
    public error?: BusinessExceptionErrors;

}
