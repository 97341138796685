import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {IwDecompteName} from '@app/modules/sam-decompte/models/iw-decompte-names.model';
import {PlacementForm} from '@app/modules/sam-main/placement/placement.forms';
import {AppState, ModalService} from '@app/sam-base/core';
import {SubMenuStatisticsNames} from '@app/sam-base/core/enum/sub-menu-statistics.enum';
import {IwEventHandler, IwEventHubService} from '@app/sam-base/core/events';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {SearchNavigationService} from '@app/sam-base/core/search-navigation';
import {DashboardNavigationService} from '@app/sam-base/core/services/dashboard-navigation.service';
import {
    LayoutEvents,
    LayoutPlacementNavigationEvent,
    LayoutSubNavigationEvent
} from '@app/sam-base/layout/events/events.models';
import {
    Ppacattestview,
    Ppaco,
    Ppagiview,
    Ppcct,
    Ppcli,
    Ppclicdes,
    Ppclimis,
    Ppemp,
    Ppind,
    Pppar,
    Ppplf,
    Pprapview,
    Ppsuivi
} from '@app/sam-base/models/placement';
import {KibanaService} from '@core/services/kibana.service';
import {BulkManagerComponent} from '@modules/sam-main/admin/components/bulk/bulk-manager.component';
import {OperationType} from '@modules/sam-main/admin/models/operation.model';
import {Ppclicdfs} from '@sam-base/models/placement/ppclicdfs';
import {lastValueFrom} from 'rxjs';

import {IwDecompteNavService} from '../../core/services/iw-decompte-nav.service';

@Injectable()
export class LayoutEventsHandlers {
    public _pparEnt: RestEntityClient<Pppar>;
    public _ppcctEnt: RestEntityClient<Ppcct>;

    constructor(private readonly searchNavService: SearchNavigationService,
                private readonly kibanaService: KibanaService,
                private readonly eventsHub: IwEventHubService<string>,
                private readonly _formHandler: FormHandlerService<PlacementForm>,
                private readonly _modalService: ModalService,
                private readonly _decompteNav: IwDecompteNavService,
                private readonly _dashboardNavService: DashboardNavigationService, _restService: RestApiService) {
        this._pparEnt = _restService.getEntityClient(Pppar);
        this._ppcctEnt = _restService.getEntityClient(Ppcct);
    }

    @IwEventHandler(LayoutPlacementNavigationEvent.DASHBOARD)
    public dashboard() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_ON);

        this._dashboardNavService.navigateToDashboard();

    }

    @IwEventHandler(LayoutPlacementNavigationEvent.CLIENT)
    public clients() {
        this.searchNavService.navigate(Ppcli);
    }

    @IwEventHandler(LayoutPlacementNavigationEvent.EMPLOY)
    public employ() {
        this.searchNavService.navigate(Ppemp);
    }

    @IwEventHandler(LayoutPlacementNavigationEvent.ORDERS)
    public orders() {
        this.searchNavService.navigate(Ppclicdes);
    }

    @IwEventHandler(LayoutPlacementNavigationEvent.ORDERS_FIXE)
    public orders_fixe() {
        this.searchNavService.navigate(Ppclicdfs);
    }

    @IwEventHandler(LayoutSubNavigationEvent.ECHEANCE_PERMIS)
    public echeancesPermis(_: AppState) {
        this.navigateToDecompte(IwDecompteName.EcheancePermis);
    }


    @IwEventHandler(LayoutPlacementNavigationEvent.ASSIGNMENTS)
    public assignments() {
        this.searchNavService.navigate(Ppclimis);
    }

    @IwEventHandler(LayoutPlacementNavigationEvent.PLACEMENT)
    public placements() {
        this.searchNavService.navigate(Ppplf);
    }

    @IwEventHandler(LayoutSubNavigationEvent.RAPPORTS)
    public rapports() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.Rapports);

    }

    @IwEventHandler(LayoutSubNavigationEvent.TEXTES_PARAMETRABLES)
    public async textesParametrables() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.TextesParametrables, undefined, s => ({
            ...s,
            entityId: '0',
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.LOOKUPS)
    public async lookups() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.Lookups, undefined, s => ({
            ...s,
            entityId: '0',
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.PROF_CONFIG)
    public async professionsConfig() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.ProfessionsConfig, undefined, s => ({
            ...s,
            entityId: '0',
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.KEYWORD_CONFIG)
    public async keywordsConfig() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.KeywordConfig, undefined, s => ({
            ...s,
            entityId: '0',
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.PPGRH_CONFIG)
    public async ppgrhConfig() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.PpgrhConfig, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.AGENCE_CONFIG)
    public async ppagenceConfig() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.AgenceConfig, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.PPSECTEUR_CONFIG)
    public ppsecteurConfig() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.PpsecteurConfig, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.PPCCT_CONFIG)
    public async ppcctConfig() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        const ppcct: Ppcct | undefined = await lastValueFrom(this._ppcctEnt.getFirst());
        this._formHandler.showFormDialog(PlacementForm.PpcctConfig, undefined, s => ({
            ...s,
            entityId: ppcct?.cctId ?? '',
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.PARAMETRES_GENERAUX)
    public parametresGeneraux() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.ParametresGeneraux, undefined, s => ({
            ...s,
            entityId: '0',
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.CALENDAR_JOURS_FERIES)
    public async calendarJoursFeriesConfig() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.CalendarJoursFeries, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.USER_ROLES)
    public userRolesConfig() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.UserRoles, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.USER_TEAMS)
    public userTeamsConfig() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.UserTeams, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }


    @IwEventHandler(LayoutSubNavigationEvent.IJ_CONFIG)
    public interijobConfig() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.Interijob, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.CONSEILLERS)
    public conseillers() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.Conseillers, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.KIBANA)
    public kibana(_: AppState, cliId?: string) {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_ON);
        this.kibanaService.navigateToKibana(SubMenuStatisticsNames.heuresProduites, cliId);
    }

    @IwEventHandler(LayoutSubNavigationEvent.KIBANA_FOLLOWUP_MANAGER)
    public kibanaClientActivity() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_ON);
        // eslint-disable-next-line max-len
        this.kibanaService.navigateToKibana(SubMenuStatisticsNames.followupManager);
    }

    @IwEventHandler(LayoutSubNavigationEvent.KIBANA_MARGIN_PROFIT)
    public kibanaMarginProfit() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_ON);
        // eslint-disable-next-line max-len
        this.kibanaService.navigateToKibana(SubMenuStatisticsNames.marginProfit);
    }

    @IwEventHandler(LayoutSubNavigationEvent.KIBANA_RESUME)
    public kibanaResume() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_ON);
        // eslint-disable-next-line max-len
        this.kibanaService.navigateToKibana(SubMenuStatisticsNames.resume);
    }

    @IwEventHandler(LayoutSubNavigationEvent.KIBANA_RECOUVREMENT)
    public kibanaRecouvrement() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_ON);
        // eslint-disable-next-line max-len
        this.kibanaService.navigateToKibana(SubMenuStatisticsNames.invoiceManagement);
    }

    @IwEventHandler(LayoutSubNavigationEvent.PERIODE_ACTIVE)
    public periodeActive() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.PeriodManagement, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.MODELES_MISSION)
    public modelesMission() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.Ppmismdl);
    }

    @IwEventHandler(LayoutSubNavigationEvent.PPDOC_TYPE)
    public ppdocType() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.Ppdoc_Type, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.GESTION_MODELES_FACTURATION)
    public gestionModelesFacturation() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.GestionModeles, {module: 'FA'}, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.GESTION_MODELES_PLACEMENT)
    public gestionModelesPlacement() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.GestionModeles, {module: 'PP'}, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.GESTION_MODELES_SALAIRE)
    public gestionModelesSalaire() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.GestionModeles, {module: 'SA'}, s => ({
            ...s,
            diagModal: true
        }));
    }


    @IwEventHandler(LayoutSubNavigationEvent.GENRE_TRAVAIL)
    public genreTravail() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.GenreTravail, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.ACOMPTES)
    public acomptesList() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.searchNavService.navigate(Ppaco);
    }

    @IwEventHandler(LayoutSubNavigationEvent.INDEMNITES)
    public indemnitesList() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.searchNavService.navigate(Ppind);
    }

    @IwEventHandler(LayoutSubNavigationEvent.AGINTER)
    public aginterList() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.searchNavService.navigate({
            entity: Ppagiview
        });

    }

    @IwEventHandler(LayoutSubNavigationEvent.ATTESTATION_EMPLOYER)
    public attEmployeList() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.searchNavService.navigate({
            entity: Ppacattestview
        });
    }

    @IwEventHandler(LayoutSubNavigationEvent.GENRES_INDEMNITES)
    public genresIndemnites() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.GenresIndemnites, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.RAPPORTS_SAISIS)
    public rapportsSaisis() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.searchNavService.navigate(Pprapview);
    }

    @IwEventHandler(LayoutSubNavigationEvent.PPSUIVI)
    public ppsuivis() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.searchNavService.navigate(Ppsuivi);
    }

    @IwEventHandler(LayoutSubNavigationEvent.RAPPORTS_MANQUANTS)
    public rapportsManquants() {
        this.navigateToDecompte(IwDecompteName.RapManq);
    }

    @IwEventHandler(LayoutSubNavigationEvent.EMP_DOCS)
    public employeDocs() {
        this.navigateToDecompte(IwDecompteName.EmpDoc);
    }

    @IwEventHandler(LayoutSubNavigationEvent.SWISSSTAFFING)
    public monthlySwissstaffing() {
        this.navigateToDecompte(IwDecompteName.Swissstaffing);
    }

    @IwEventHandler(LayoutSubNavigationEvent.SITDEBITEUR)
    public sitdebiteur() {
        this.navigateToDecompte(IwDecompteName.sitdebiteur);
    }

    @IwEventHandler(LayoutSubNavigationEvent.BALDEBITEUR)
    public baldebiteur() {
        this.navigateToDecompte(IwDecompteName.baldebiteur);
    }

    @IwEventHandler(LayoutSubNavigationEvent.CACLIENT)
    public caclient() {
        this.navigateToDecompte(IwDecompteName.caclient);
    }

    @IwEventHandler(LayoutSubNavigationEvent.MARGIN)
    public margin() {
        this.navigateToDecompte(IwDecompteName.Margin);
    }

    @IwEventHandler(LayoutSubNavigationEvent.ACOLIST)
    public acolist() {
        this.navigateToDecompte(IwDecompteName.Acolist);
    }

    @IwEventHandler(LayoutSubNavigationEvent.SUVA)
    public SUVA() {
        this.navigateToDecompte(IwDecompteName.Suva);
    }

    @IwEventHandler(LayoutSubNavigationEvent.ACTIVE_MISSIONS)
    public activeMissions() {
        this.navigateToDecompte(IwDecompteName.ActiveMissions);
    }

    @IwEventHandler(LayoutSubNavigationEvent.NEW_CLIENT_DECOMPTE)
    public newClientDecompte() {
        this.navigateToDecompte(IwDecompteName.NewClient);
    }

    @IwEventHandler(LayoutSubNavigationEvent.RELEVE_COMPTES_DEBITEURS)
    public releveComptesDebiteurs() {
        this.navigateToDecompte(IwDecompteName.ReleveComptesDebiteurs);
    }

    @IwEventHandler(LayoutSubNavigationEvent.WEEKLY_TIMESHEETS_DECOMPTE)
    public hourlyTimesheetsDecompte() {
        this.navigateToDecompte(IwDecompteName.WeeklyTimsheets);
    }

    @IwEventHandler(LayoutSubNavigationEvent.RAPPORT_LOCATION_SERVICE)
    public rapportLocationServiceDecompte() {
        this.navigateToDecompte(IwDecompteName.Rloc);
    }

    @IwEventHandler(LayoutSubNavigationEvent.RAPPORT_ANNUEL)
    public rapportAnnuelDecompte() {
        this.navigateToDecompte(IwDecompteName.RapportAnnuel);
    }

    @IwEventHandler(LayoutSubNavigationEvent.HEURES_PRODUITES)
    public heuresProduitesDecompte() {
        this.navigateToDecompte(IwDecompteName.HeuresProduites);
    }


    @IwEventHandler(LayoutSubNavigationEvent.SITDEBITEURCLI)
    public sitdebiteurWithCli(_: AppState, cliId: string) {
        this.navigateToDecompte(IwDecompteName.sitdebiteur, {cliId});
    }

    @IwEventHandler(LayoutSubNavigationEvent.MISSION_HISTORY)
    public missionHistory(_: AppState) {
        this.navigateToDecompte(IwDecompteName.MissionHistory);
    }

    @IwEventHandler(LayoutSubNavigationEvent.CERT_TRAVAIL)
    public certificatTravail(_: AppState) {
        this.navigateToDecompte(IwDecompteName.CertificatTravail);
    }

    @IwEventHandler(LayoutSubNavigationEvent.RECAP_MIS)
    public recapMission(_: AppState) {
        this.navigateToDecompte(IwDecompteName.RecapMis);
    }

    @IwEventHandler(LayoutSubNavigationEvent.CALENDAR_EXP)
    public calendarExp(_: AppState) {
        this.navigateToDecompte(IwDecompteName.Calendar);
    }

    @IwEventHandler(LayoutSubNavigationEvent.ABS)
    public abscences(_: AppState) {
        this.navigateToDecompte(IwDecompteName.Abs);
    }

    @IwEventHandler(LayoutSubNavigationEvent.INVOICES_PREPARATION)
    public invoicesPreparation() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.InvoicesPreparation, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.INVOICES_PUBLICATION)
    public invoicesPublication() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._modalService.showModal(BulkManagerComponent, {type: OperationType.PUBLISH_INVOICE});
    }

    @IwEventHandler(LayoutSubNavigationEvent.SEND_INVOICE)
    public invoicesEmail() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._modalService.showModal(BulkManagerComponent, {type: OperationType.SEND_INVOICE});
    }


    @IwEventHandler(LayoutSubNavigationEvent.TIMESHEET)
    public timesheet(_: AppState) {
        this.navigateToDecompte(IwDecompteName.Timesheet);
    }

    @IwEventHandler(LayoutSubNavigationEvent.TIMESHEET_EMP)
    public timesheetwithemp(_: AppState, empId: string) {
        this.navigateToDecompte(IwDecompteName.Timesheet, {empId});
    }

    @IwEventHandler(LayoutSubNavigationEvent.SALARY_PREPARATION)
    public salaryPreparation() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(PlacementForm.SalaryPreparation, undefined, s => ({
            ...s
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.HEURES_ALLOCATIONS_FAMILIALES)
    public navDecompteHeuresAllocationFamiliales() {
        this.navigateToDecompte(IwDecompteName.HeuresTravaillees);
    }

    @IwEventHandler(LayoutSubNavigationEvent.CONTRACTS)
    public navDecompteContracts() {
        this.navigateToDecompte(IwDecompteName.Contracts);
    }

    @IwEventHandler(LayoutSubNavigationEvent.DECLPP)
    public navDecompteLpp() {
        this.navigateToDecompte(IwDecompteName.DecLPP);
    }

    @IwEventHandler(LayoutSubNavigationEvent.DEBUTS)
    public navDecompteDebuts() {
        this.navigateToDecompte(IwDecompteName.Debuts);
    }

    private navigateToDecompte(name: string, param?: Params) {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_ON);
        this._decompteNav.openPreview(name, param);
    }
}
