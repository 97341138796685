<form [formGroup]="formGroup"
      class="row">

    <div class="row space-form col-md-12">
        <p-message *ngIf="isImpotDedCode"
                   [text]="'dedcode_imp_edit_from_impot_tab' | translate"
                   severity="info"></p-message>
    </div>

    <div class="row space-form col-md-12">
        <iw-textfield [isDisabled]=true
                      [labelAlign]="'top'"
                      [value]="annee"
                      class="iw-input col-xs-12 padding-top-15"
                      style="text-align-last: center;"></iw-textfield>

        <div class="row col-xs-12"
             style="align-items: end;">
            <iw-textfield [isDisabled]=true
                          [labelAlign]="'top'"
                          [label]="'Déd:' | translate"
                          [value]="dedCode"
                          class="iw-input col-xs-2 padding-top-10 no-padding-right"
                          formControlName="deductionCode"></iw-textfield>
            <iw-textfield [isDisabled]=true
                          [labelAlign]="'top'"
                          [value]="dedCodetitre"
                          class="iw-input col-xs-6 no-padding-left padding-top-10"></iw-textfield>

        </div>

        <div class="row col-xs-12"
             style="align-items: end;">
            <iw-dropdown (valueSelected)="setSoumisCodeValue($event)"
                         [isDisabled]="!soumisCodeEnabled || isReadOnlyOrDisabledEditDedCode"
                         [labelAlign]="'top'"
                         [label]="'soumis-code' | translate"
                         [options]="soumisCodeOptions"
                         class="iw-input col-xs-6 padding-top-10"
                         formControlName="soumisCode"></iw-dropdown>
            <iw-checkbox (valueChange)="enableSoumisCode($event)"
                         [falseValue]="0"
                         [isDisabled]="isTxManuelDisabled || isReadOnlyOrDisabledEditDedCode"
                         [labelAlign]="'right'"
                         [label]="'Manuel' | translate"
                         [trueValue]="1"
                         class="iw-input col-xs-2 flex-end"
                         formControlName="txmanuel"></iw-checkbox>
            <iw-textfield [isDisabled]=true
                          [labelAlign]="'right'"
                          [value]="tauxAuto"
                          class="iw-input col-xs-2 margin-left-20"></iw-textfield>
        </div>

        key ==> {{ getFormValue('key') }}
        <div *ngIf="isCodeTauxVisible"
             class="row col-xs-12"
             style="align-items: end;">
            <iw-dropdown [isDisabled]="!isManualCheckedAndOtherThanNonSoumis || isReadOnlyOrDisabledEditDedCode"
                         [labelAlign]="'top'"
                         [label]="'code-taux' | translate"
                         [options]="txCodeOptions"
                         class="iw-input col-xs-6 padding-top-10"
                         formControlName="key"></iw-dropdown>
        </div>
        <div *ngIf="isCCT"
             class="row col-xs-12"
             style="align-items: end;">
            <iw-cct-dropdown [activeOnly]="true"
                             [label]="'cct' | translate"
                             class="iw-input col-xs-6 padding-top-10"
                             formControlName="txcode"
                             labelAlign="top"></iw-cct-dropdown>
        </div>

        <div class="row col-xs-10 padding-top-20"
             style="align-items: end;">
            <iw-checkbox (valueChange)="setMode($event)"
                         *ngIf="this.getFormValue('deductionCode') === 'LPP'"
                         [(value)]="lppHorValue"
                         [hidden]="isLPPHidden"
                         [isDisabled]="!isManualCheckedAndOtherThanNonSoumis || isReadOnlyOrDisabledEditDedCode"
                         [label]="'lpphoraire' | translate"
                         class="iw-input col-xs-4"></iw-checkbox>
        </div>

        <div *ngIf="isModeVisible"
             class="row col-xs-10 padding-top-40"
             style="align-items: end;">
            <iw-dropdown
                    [isDisabled]="!isManualCheckedAndOtherThanNonSoumis || lppHorValue === true || isReadOnlyOrDisabledEditDedCode"
                    [labelAlign]="'top'"
                    [label]="'Mode' | translate"
                    [options]="cusCode1Options"
                    class="iw-input col-xs-6 padding-top-10"
                    formControlName="cuscode1"></iw-dropdown>
        </div>


        <div *ngIf="isRemarqueVisible"
             class="row col-xs-12"
             style="align-items: end;">
            <iw-textfield [(value)]="remAVS"
                          [isDisabled]="isReadOnlyOrDisabledEditDedCode"
                          [labelAlign]="'top'"
                          [label]="'remarque' | translate"
                          class="iw-input col-xs-6 padding-top-10"></iw-textfield>

        </div>
        <div *ngIf="isAVSDedCodeAndRentier"
             class="row col-xs-12"
             style="align-items: end;">
            <iw-checkbox #ckbAVSNoFranchise
                         [(value)]="avsNoFranchise"
                         [labelAlign]="'right'"
                         [label]="'avsNoFranchise' | translate"
                         class="iw-input col-xs-6 padding-top-10"
                         formControlName="avsNoFranchise"></iw-checkbox>
        </div>


        <div *ngIf="isCantonVisible"
             class="row col-xs-12"
             style="align-items: end;">

            <iw-canton-dropdown #drpCodeImp
                                [isDisabled]="!isManualCheckedAndOtherThanNonSoumis || isReadOnlyOrDisabledEditDedCode"
                                [labelAlign]="'top'"
                                [label]="'canton' | translate"
                                class="iw-input col-xs-6 padding-top-10"
                                formControlName="txcode"></iw-canton-dropdown>
        </div>

        <div *ngIf="isBaremVisible"
             class="row col-xs-12"
             style="align-items: end;">
            <iw-dropdown [isDisabled]="!isManualCheckedAndOtherThanNonSoumis || isReadOnlyOrDisabledEditDedCode"
                         [labelAlign]="'top'"
                         [label]="'bareme'"
                         [options]="txCode2Options"
                         class="iw-input col-xs-4 padding-top-10 no-padding-right"
                         formControlName="txcode2"></iw-dropdown>
            <iw-textfield [isDisabled]=true
                          [labelAlign]="'top'"
                          [value]="txtBarc3"
                          class="iw-input col-xs-4 no-padding-left padding-top-15"></iw-textfield>
            <iw-checkbox #ckbImpathee
                         [falseValue]="0"
                         [isDisabled]="!isManualCheckedAndOtherThanNonSoumis || isReadOnlyOrDisabledEditDedCode"
                         [labelAlign]="'right'"
                         [label]="'athee' | translate"
                         [trueValue]="1"
                         class="iw-input col-xs-2 flex-end"
                         formControlName="cuscode2"></iw-checkbox>
        </div>

        <div class="row col-xs-8"
             style="align-items: end;">
            <iw-button #btnClose
                       (press)="saveEmployeeDeductionInfo()"
                       *ngIf="!isReadOnlyOrDisabledEditDedCode"
                       [text]="'enregistrer' | translate"
                       class="iw-button padding-top-15 margin-right-5"></iw-button>
            <iw-button #btnClose
                       (press)="closeDialog()"
                       [text]="'Annuler' | translate"
                       class="iw-button"></iw-button>
        </div>

    </div>
</form>
