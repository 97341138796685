import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppemp} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {ComboboxItem} from '@sam-base/models';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {Langue} from '@sam-base/models/common/langue.enum';

import {ProfileService} from '@shared/profile/profile.service';

@Component({
    selector: 'iw-tab-ppemp-paiement',
    templateUrl: './tab-ppemp-paiement.component.html'
})
export class TabPpempPaiementComponent extends BasePartialFormComponent<Ppemp> implements OnInit {

    public langue = Langue;

    public modeType: ComboboxItem<number>[] = [
        {
            name: this._translate.instant('combo_mensuel'),
            value: 1
        },
        {
            name: this._translate.instant('combo_manuel'),
            value: 2
        },
        {
            name: this._translate.instant('combo_horaire'),
            value: 3
        },
        {
            name: this._translate.instant('combo_horaiteTempo'),
            value: 4
        },
        {
            name: this._translate.instant('combo_journalier'),
            value: 5
        }];

    public isPPAdmin = false;
    public lppwcountOptions: ComboboxItem<number>[] = [
        {
            name: '----',
            value: 0
        },
        {
            name: '1',
            value: 1
        },
        {
            name: '2',
            value: 2
        },
        {
            name: '3',
            value: 3
        },
        {
            name: '4',
            value: 4
        },
        {
            name: '5',
            value: 5
        },
        {
            name: '6',
            value: 6
        },
        {
            name: '7',
            value: 7
        },
        {
            name: '8',
            value: 8
        },
        {
            name: '9',
            value: 9
        },
        {
            name: '10',
            value: 10
        },
        {
            name: '11',
            value: 11
        },
        {
            name: '12',
            value: 12
        },
        {
            name: '13',
            value: 13
        }];

    constructor(private readonly _profileService: ProfileService, private readonly _translate: TranslateService) {
        super();
    }

    public get ppempId() {
        return this.getFormValue<string>('empId');
    }

    public get showLppwcount() {
        return (this.isPPAdmin || this.getFormValue<number>('lppwcount')) && !this.lppforce1;
    }
    
    public get lppforce1() {
        return this.getFormValue<boolean>('lppforce1');
    }

    public get lppwcount() {
        return this.getFormValue<number>('lppwcount');
    }

    public get salgenfreq() {
        return this.getFormValue<string>('salgenfreq');
    }

    public get email() {
        return this.getFormValue<string>('email');
    }

    public ngOnInit(): void {
        this.isPPAdmin = this._profileService.checkUserPermission([SamUserRole.SAM_PLACEMENT_PARAM]);
    }

    public checkValidation(event: number) {
        if (event === 0) {
            this.setFormValue('lppwcount', undefined);
        }
    }

}
