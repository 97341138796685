import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EmployeeDeductionInfo} from '@app/sam-base/models/salary/employeeDeductionInfo';
import {Observable, Subject} from 'rxjs';
import {RestClient} from '../rest-api/core';

export interface SaempdedSubscrition {
    uuid: string;
    saved: boolean;
}

@Injectable()
export class EmployeeDeductionService extends RestClient<any> {

    public sampededSaveSub: Subject<SaempdedSubscrition> = new Subject();

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }


    public getTxManuelInfoByDedCodeAndEmpId(empId: string, dedCode: String): Observable<string> {
        return this._http.get(this._baseUrl + `employeeDeductionInfo/${empId}/${dedCode}`, {responseType: 'text'});
    }

    public updateEmployeeDeductionInfo(empId: string,
                                       employeeDeductionInfo: EmployeeDeductionInfo): Observable<EmployeeDeductionInfo> {
        return this._http.put(this._baseUrl + `employeeDeductionInfo/${empId}`, employeeDeductionInfo);
    }

    public deleteDeductionById(edId: string): Observable<void> {
        return this._http.delete<void>(this._baseUrl + `saempded/${edId}`);
    }

    public getAvsNoFranchise(empId: string): Observable<string> {
        return this._http.get(this._baseUrl + `employeeDeductionInfo/avsNoFranchise/${empId}`, {responseType: 'text'});
    }
}
