import {HttpClient} from '@angular/common/http';
import {Component, Input, OnDestroy, OnInit, Type} from '@angular/core';
import {
    BulkEntitySelectComponent
} from '@modules/sam-main/admin/components/bulk/bulk-entity-select/bulk-entity-select.component';
import {OperationType} from '@modules/sam-main/admin/models/operation.model';
import {EsQueryStatement, Term} from '@sam-base/core';
import {SaepView} from '@sam-base/models/salary/saepview';

@Component({
    selector: 'iw-bulk-entity-select-saep',
    templateUrl: './bulk-entity-select.component.html',
})
export class BulkEntitySelectSaepComponent extends BulkEntitySelectComponent<SaepView> implements OnInit, OnDestroy {
    public entity: Type<SaepView> = <any>SaepView;

    @Input()
    public operationType?: OperationType;

    @Input()
    public salId?: string;

    @Input()
    public year?: string;

    constructor(private readonly http: HttpClient) {
        super();
    }


    public ngOnInit() {
        this.buildQueryStatements();
        this.setCustomColumns();
    }

    private buildQueryStatements() {

        const list: EsQueryStatement<SaepView>[] = [];
        // list.push(EsQueryStatement.fromTerm(<Term<SaepView>>{type: 'saepview'}, 'filter'));
        list.push(EsQueryStatement.fromTerm(<Term<SaepView>>{salId: this.salId}, 'must'));
        if (this.operationType === OperationType.VALIDATE_WAITING_PAYMENT) {
            list.push(EsQueryStatement.fromTerm(<Term<SaepView>>{paid: 'false'}, 'must'));
            list.push(EsQueryStatement.fromTerm(<Term<SaepView>>{payType: 3}, 'must_not'));
            list.push(EsQueryStatement.fromTerm(<Term<SaepView>>{payType: 5}, 'must_not'));
            list.push(EsQueryStatement.fromTerm(<Term<SaepView>>{payType: 6}, 'must_not'));
        }
        this.statements = list;
        console.log('this.statements', this.statements);
    }

    private setCustomColumns() {
        this.columns = [
            {
                prop: 'epId',
                type: 'string'
            },
            {
                prop: 'nom',
                type: 'string'
            },
            {
                prop: 'prenom',
                type: 'string',
            },
            {
                name: 'montant',
                prop: 'montant',
                type: 'mnt',
                align: 'right'
            },
            {
                name: 'mode',
                prop: 'mpaLabel',
                type: 'string'
            },
        ];
    }
}
