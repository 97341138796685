import {Component, OnInit} from '@angular/core';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {EntityNavigationService, RestApiService, RestQueryOperation} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {IwStoreService} from '@app/sam-base/core/store';
import {ComboboxItem, FormKeys} from '@app/sam-base/models';
import {Ppaco, Ppcli, Ppclimis, Ppemp, Ppmis, Ppper, Pprap} from '@app/sam-base/models/placement';
import {PeriodeService} from '@modules/sam-main/placement/services/periode.service';
import {ReportsService} from '@modules/sam-main/placement/services/reports.service';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {lastValueFrom} from 'rxjs';

import {rapportTabsFormControls} from './rapport-tabs-formcontrols';

@Component({
    templateUrl: './rapport.component.html'
})
export class RapportComponent extends BaseTabFormComponent<Pprap> implements OnInit {

    public report?: Pprap;
    public entityPpemp = Ppemp;
    public entityClient = Ppcli;
    public mission: Ppmis = new Ppmis();
    public acompte: Ppaco = new Ppaco();
    public salIds: ComboboxItem<any>[] = [];
    public PLACEMENT_ADMIN_ROLE = [
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_ADMIN_PERIOD,
        SamUserRole.SAM_ADMIN_GOD_MODE];

    constructor(store: IwStoreService, private _restService: RestApiService,
                private readonly _entityNavService: EntityNavigationService,
                private readonly _periodService: PeriodeService,
                private readonly _reportsService: ReportsService) {
        super(store);
        this.subscribeMissionById();
        this.subscribeAcompteByRapId();
    }


    public get tabData(): Pprap | undefined {
        return this.getFormData();
    }

    public set tabData(v: Pprap | undefined) {
        this.mergeEntityChanges(v);
    }

    public get createdModeClass() {
        const createdMode = this.getFormValue('modecreat');
        if (!createdMode && createdMode === 'N') {
            return;
        }
        return 'created-mode-j';
    }

    public get canChangeSalId() {
        return !this.getFormValue('sent2Sal') && !this.getFormValue('sent2Sal');
    }

    public get currentWeekDay(): string {
        const dateLundi = this.getFormValue('datelundi');
        if (!dateLundi) {
            return '';
        }
        return IwDateHelper.getWeekNumber(dateLundi);
    }

    public ngOnInit() {
        this.subscribeValueChange('rapId', () => {
            this._periodService.getOpenPeriodes()
                .subscribe({
                    next: (data: Ppper[]) => {
                        // Initialize or clear salIds
                        this.salIds = [];

                        // Loop through each Ppper and insert only if salId is defined
                        data.forEach((period: Ppper) => {
                            if (period.salId !== undefined && period.salId !== null) {
                                this.salIds.push({
                                    name: period.salId,
                                    value: period.salId
                                });
                            }
                        });
                    },
                    error: (err) => {
                        console.error('Error fetching periods', err);
                    }
                });
        });
        this.subscribeValueChange('salId', () => {
                this.savePprap()
            }
        );
    }

    public getTabsValues(): TabFormControl<Pprap>[] {
        return rapportTabsFormControls;
    }

    public openEmp() {
        this._entityNavService
            .navigateToEntityForm(Ppemp, this.getFormValue('empId'));
    }

    public openCli() {
        this._entityNavService
            .navigateToEntityForm(Ppcli, this.getFormValue('cliId'));
    }

    public openMis() {
        this._entityNavService
            .navigateToEntityForm(Ppclimis, this.getFormValue('misId'));
    }

    protected getFormControlNames(): FormKeys<Pprap> {
        return this.getTabsFormControls(rapportTabsFormControls);
    }

    private async savePprap() {
        const rapId = this.getFormValue('rapId');
        if (rapId && this.canChangeSalId)
            await lastValueFrom(this._reportsService.setReportSalId(this.getFormValue('rapId') ?? '', this.getFormValue('salId')));
    }

    private subscribeMissionById(): void {
        this.subscribeValueChange('misId', () => {
            const misId = this.getFormValue('misId');
            if (!misId) {
                return;
            }
            this._restService.getEntityClient(Ppmis)
                .getById(misId)
                .subscribe((mission: Ppmis) => this.mission = mission);
        });
    }

    private subscribeAcompteByRapId(): void {
        this.subscribeValueChange('rapId', () => {
            this.report = this.getFormData();
            const rapId = this.getFormValue('rapId');
            if (!rapId) {
                return;
            }

            this._restService.getEntityQuery(Ppaco, {
                operation: RestQueryOperation.Equals,
                prop: 'rapId',
                value: rapId
            })
                .scroll()
                .subscribe((acomptes: Ppaco[]) => {
                    if (!acomptes.length) {
                        return;
                    }
                    this.acompte = acomptes[0];
                });
        });
    }
}
