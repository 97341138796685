import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {RappelFactureComponent} from '@modules/sam-main/salary/components/rappel-facture/rappel-facture.component';
import {Gerplhdr} from '@sam-base/models/invoices/gerplhdr';

export const GERPL_FORM: FormDescription<Gerplhdr> = {
    form: RappelFactureComponent,
    state: {
        ...buildDefaultState(Gerplhdr),
        diagTitle: 'title_rappel_facture',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: false,
            showClose: true,
            showEdit: false,
            showList: true,
            showPrint: true,
            showDelete: false
        },
        editMode: 'read',
        useNavigation: true,
        width: 900,
        patch: false
    }
};
